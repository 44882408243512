import React, { Component } from 'react';
import FbrService from '../service/FbrService';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import { TabView, TabPanel } from 'primereact/tabview';
import { SplitButton } from 'primereact/splitbutton';

export class FormTypeService extends Component {
    constructor() {
        super();
        this.state = {
            listeprest: [],
            listetypeserv: [],
            listeformule: [],
            liste_param_eval: [],
            liste_param_type: [],
            idPrest: null,
            liste_param_eval_adding: [],
            selectedParamEval: null,
            selectedParamType: null,
            selectedType: null,

            liste_selectedType: [],
            liste_selectedPrest: [],
            liste_quantitatif: [],
            liste_qualitatif: [],
            liste_bailleur: [],
            idBailleurType: null,

            idType: 0,
            activeIndex: 0,
            rangType: 0,
            cibleType: null,
            libType: '',
            descType: '',
            categorieType: 'Quantitative',
            liste_categorie: [
                { label: 'Qualitative', value: 'Qualitative' },
                { label: 'Quantitative', value: 'Quantitative' },
                { label: 'Enquete', value: 'Enquete' },
            ],
            noteMaxType: 0,
            srceVerifType: '',
            puRefType: 0,
            idFeval: null,
            msg: '',
            modif: false,
            listeserv: [],
            selectedServ: null,
            modifSrv: false,
            visible: false,
            showindicator: false,
            visible_dlg_form_typeserv: false,
            visible_dlg_form_item: false,
            visible_dlg_affectation_param: false,
            visible_dlg_form_param: false,
            idSrv: 0,
            libSrv: '',
            descSrv: '',
            puPeval: 0,
            maxPeval: 0,
            modif_param_eval: false,
            visible_param_eval: false,
            visible_param_type: false,
            visible_param_eval_prest: false,
            liste_cible: [],
            liste_famille: [],
            idFamille: null,
            critereAppType: '',
            critereNoteType: '',

            idItem: 0,
            keyItem: '',
            valueItem: 0,
            refId: '',
            libRef: '',
            abvType: '',
            modif_kv: false,
            visible_kv: false,
            selectedKv: null,
            liste_kv: []

        };
        this.edit_typeserv = this.edit_typeserv.bind(this);
        this.conf_delete_typeserv = this.conf_delete_typeserv.bind(this);
        this.conf_delete_param_type = this.conf_delete_param_type.bind(this);
        this.FbrService = new FbrService();
        this.toogleDlg_typeserv = this.toogleDlg_typeserv.bind(this);
        this.toogleDlg_item = this.toogleDlg_item.bind(this);
        this.edit_service = this.edit_service.bind(this);
        this.conf_delete_service = this.conf_delete_service.bind(this);
        this.delete_ligne_param = this.delete_ligne_param.bind(this);
        this.conf_delete_param_eval = this.conf_delete_param_eval.bind(this);
        this.inputNumberEditor = this.inputNumberEditor.bind(this);
        this.edit_param_eval = this.edit_param_eval.bind(this);

        this.puPevalEditor = this.puPevalEditor.bind(this);
        this.maxPevalEditor = this.maxPevalEditor.bind(this);

        this.onIndexTemplate = this.onIndexTemplate.bind(this);

        this.edit_kv = this.edit_kv.bind(this);
        this.conf_delete_kv = this.conf_delete_kv.bind(this);
        this.toogleDlg_kv = this.toogleDlg_kv.bind(this);
        this.show_dlg_param = this.show_dlg_param.bind(this);
    }

    show_dlg_param(rowData) {
        this.get_liste_param_eval('Tous', rowData.idType);
        this.setState({ visible_param_eval_prest: true });
    }

    onIndexTemplate(data, props) {
        return props.rowIndex + 1;
    }

    inputNumberEditor(props, field) {
        return <InputNumber value={props.rowData[field]} onChange={(e) => this.onEditorValueChange(props, e.value)} />;
    }

    onEditorValueChange(props, value) {
        let updateds = [...props.value];
        updateds[props.rowIndex][props.field] = value;
        this.setState({ liste_param_eval: updateds });
    }

    puPevalEditor(props) {
        return this.inputNumberEditor(props, 'puPeval');
    }

    maxPevalEditor(props) {
        return this.inputNumberEditor(props, 'maxPeval');
    }

    toogleDlg_typeserv() {
        this.setState({ visible_dlg_form_typeserv: !this.state.visible_dlg_form_typeserv });
        // this.get_liste_param_eval();
    }

    toogleDlg_kv() {
        this.setState({ visible_kv: !this.state.visible_kv, liste_kv: [] }, () => {
            this.get_liste_kv();
        });
    }

    get_liste_kv() {
        this.setState({ showindicator: true });
        this.FbrService.get_liste_key_value(this.state.selectedType.idType).then(data => this.setState({ liste_kv: data, showindicator: false }));
    }

    toogleDlg_item() {
        this.setState({ showindicator: true });
        this.setState({ visible_param_type: true }, () => {
            this.get_liste_param_type();
        });
    }

    componentDidMount() {
        this.get_liste_formuleEva();
        this.get_liste_codif();
        this.get_liste_typeserv();
    }

    get_liste_codif() {
        this.setState({ showindicator: true });
        this.FbrService.get_liste_codif('TYPE_PRESTATAIRE').then(data => {
            this.setState({ showindicator: false });
            if (data.length > 0) {
                let liste = data.map(x => {
                    return { value: x.idCodif, label: x.libCodif }
                });
                this.setState({ liste_cible: liste });
            }
        });

        this.setState({ showindicator: true });
        this.FbrService.get_liste_codif('FAMILLE_INDICATEUR').then(data => {
            this.setState({ showindicator: false });
            if (data.length > 0) {
                let liste = data.map(x => {
                    return { value: x.idCodif, label: x.libCodif + ' - ' + x.nomPrest }
                });
                this.setState({ liste_famille: liste });
            }
        });

        this.setState({ showindicator: true });
        this.FbrService.get_liste_codif('BAILLEUR').then(data => {
            this.setState({ showindicator: false });
            if (data.length > 0) {
                let liste = data.map(x => {
                    return { value: x.idCodif, label: x.libCodif }
                });
                this.setState({ liste_bailleur: liste });
            }
        });

    }



    get_liste_typeserv() {
        this.setState({ showindicator: true });
        this.FbrService.get_liste_typeserv().then(data => this.setState({ listetypeserv: data, showindicator: false }));
    }

    get_liste_formuleEva() {
        this.setState({ showindicator: true });
        this.FbrService.get_liste_formule().then(data => this.setState({ listeformule: data, showindicator: false }));
    }


    resultat(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_typeserv();
            this.componentDidMount();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    resultat_kv(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_kv();
            this.get_liste_kv();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    resultat_update_param_eval(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_param_eval();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }


    resultat_param_eval(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_param_eval();
            this.get_liste_param_eval_forAding();
            this.get_liste_param_eval(this.state.idPrest, 'Tous');
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    resultat_param_type(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.get_liste_param_type();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    edit_typeserv(data) {
        if (data != null && data != undefined) {
            this.setState({ idType: data.idType });
            this.setState({ libType: data.libType });
            this.setState({ descType: data.descType });
            this.setState({ categorieType: data.categorieType });
            this.setState({ critereNoteType: data.critereNoteType });
            this.setState({ critereAppType: data.critereAppType });
            this.setState({ idFamille: data.idFamille.idCodif });
            this.setState({ cibleType: data.cibleType.idCodif });
            this.setState({ noteMaxType: data.noteMaxType });
            this.setState({ srceVerifType: data.srceVerifType });
            this.setState({ abvType: data.abvType });
            this.setState({ puRefType: data.puRefType });
            this.setState({ idBailleurType: data.idBailleurType.idCodif, rangType: data.rangType });

            this.setState({ modif: true });
            this.setState({ visible_dlg_form_typeserv: true });
            this.setState({ selectedType: data });
        }
    }

    edit_param_eval(data) {
        if (data != null && data != undefined) {
            this.setState({ puPeval: data.puPeval });
            this.setState({ maxPeval: data.maxPeval });
            this.setState({ selectedParamEval: data });
            this.setState({ modif_param_eval: true });
        }
    }

    annule_param_eval() {
        this.setState({ puPeval: 0 });
        this.setState({ maxPeval: 0 });
        this.setState({ modif_param_eval: false, visible_param_eval_prest: false });
        this.forceUpdate();
    }

    edit_kv(data) {
        if (data != null && data != undefined) {
            this.setState({ idItem: data.idItem });
            this.setState({ keyItem: data.keyItem });
            this.setState({ valueItem: data.valueItem });
            this.setState({ libRef: data.libRef });
            this.setState({ refId: data.refId });
            this.setState({ selectedKv: data });
            this.setState({ modif_kv: true, visible_kv: true });
        }
    }

    annule_kv() {
        this.setState({ idItem: 0 });
        this.setState({ keyItem: '' });
        this.setState({ valueItem: 0 });
        this.setState({ libRef: '' });
        this.setState({ refId: '' });
        this.setState({ modif_kv: false });
        this.forceUpdate();
    }

    annule_typeserv() {
        this.setState({ libType: '' });
        this.setState({ descType: '' });
        this.setState({ critereAppType: '' });
        this.setState({ critereNoteType: '' });
        this.setState({ noteMaxType: 0 });
        this.setState({ srceVerifType: '' });
        this.setState({ abvType: '' });
        this.setState({ puRefType: 0 });
        this.setState({ idFeval: 0 });
        this.setState({ idType: 0, rangType: 0 });
        if (this.state.modif == true) {
            this.setState({ visible_dlg_form_typeserv: false });
        }
        this.setState({ modif: false });
        this.forceUpdate();
    }

    enreg_param_eval() {
        let param = this.state.selectedParamEval;
        param.maxPeval = this.state.maxPeval;
        param.puPeval = this.state.puPeval;
        this.FbrService.enreg_param_eval(param).then(data => this.resultat_param_eval(data.code, data.contenu));
    }

    enreg_param_type() {
        let param = { idPtype: 0, typePrest: { idCodif: this.state.cibleType }, idType: this.state.selectedType };
        this.FbrService.enreg_param_type(param).then(data => this.resultat_param_type(data.code, data.contenu));
    }

    enreg_param_eval_batch() {
        if (this.state.liste_param_eval_adding == null || this.state.liste_param_eval_adding == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Aucun indicateur à affecter' });
        } else {
            this.setState({ showindicator: true });
            let userconnected = this.FbrService.get_userconnected();
            for (var i = this.state.liste_param_eval_adding.length; i--;) {
                this.state.liste_param_eval_adding[i].iduser = userconnected.idUser;
            }
            this.FbrService.enreg_param_eval_batch(this.state.liste_param_eval_adding).then(data => this.resultat_param_eval(data.code, data.contenu));
            this.forceUpdate();
        }
    }

    update_param_eval_batch() {
        if (this.state.liste_param_eval == null || this.state.liste_param_eval == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Aucun acteur à modifier' });
        } else {
            this.setState({ showindicator: true });
            let userconnected = this.FbrService.get_userconnected();
            for (var i = this.state.liste_param_eval.length; i--;) {
                this.state.liste_param_eval[i].iduser = userconnected.idUser;
            }
            this.FbrService.update_param_eval_batch(this.state.liste_param_eval).then(data => this.resultat_update_param_eval(data.code, data.contenu));
            this.forceUpdate();
        }
    }


    enreg_typeserv() {
        if (this.state.libType == "" || this.state.categorieType == "" || this.state.idFamille == "") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            this.setState({ showindicator: true });
            if (this.state.modif == false) {
                let typeservice = {
                    idType: 0,
                    libType: this.state.libType,
                    abvType: this.state.abvType,
                    descType: this.state.descType,
                    categorieType: this.state.categorieType,
                    noteMaxType: this.state.noteMaxType,
                    srceVerifType: this.state.srceVerifType,
                    critereNoteType: this.state.critereNoteType,
                    critereAppType: this.state.critereAppType,
                    idFamille: { idCodif: this.state.idFamille },
                    cibleType: { idCodif: this.state.cibleType },
                    idBailleurType: { idCodif: this.state.idBailleurType },
                    rangType: this.state.rangType,
                    puRefType: this.state.puRefType
                };
                this.FbrService.enreg_typeserv(typeservice).then(data => {
                    this.resultat(data.code, data.contenu);
                    if (data !== null && data !== undefined) {
                        for (var i = this.state.liste_param_eval_adding.length; i--;) {
                            this.state.liste_param_eval_adding[i].idType = { idType: data.id_ref };
                        }
                        this.enreg_param_eval_batch();
                    }
                });
            } else {
                let typeservice = this.state.selectedType;
                typeservice.idType = this.state.idType;
                typeservice.abvType = this.state.abvType;
                typeservice.libType = this.state.libType;
                typeservice.descType = this.state.descType;
                typeservice.critereAppType = this.state.critereAppType;
                typeservice.critereNoteType = this.state.critereNoteType;
                typeservice.categorieType = this.state.categorieType;
                typeservice.idFamille = { idCodif: this.state.idFamille };
                typeservice.cibleType = { idCodif: this.state.cibleType };
                typeservice.idBailleurType = { idCodif: this.state.idBailleurType };
                typeservice.noteMaxType = this.state.noteMaxType;
                typeservice.srceVerifType = this.state.srceVerifType;
                typeservice.puRefType = this.state.puRefType;
                typeservice.rangType = this.state.rangType;
                this.FbrService.enreg_typeserv(typeservice).then(data => this.resultat(data.code, data.contenu));
            }
        }
    }

    actionTemplate(rowData) {
        /*
                return <div className="p-grid" style={{ width: 122 }}>
                    <div className="p-col"><Button title='Modifier' onClick={() => this.edit_typeserv(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
                    <div className="p-col"><Button title='Supprimer' onClick={() => this.conf_delete_typeserv(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -22 }} icon="pi pi-times" /></div>
                    <div className="p-col"><Button onClick={() => this.toogleDlg_kv(rowData)} className="p-button-secondary" style={{ width: 25, height: 25, marginLeft: -22 }} icon="pi pi-sitemap" /></div>
                </div>
                */
        let items = [
            {
                label: 'Supprimer',
                icon: 'pi pi-times',
                command: (e) => {
                    this.conf_delete_typeserv(rowData);
                }
            },
            {
                label: 'Modifier',
                icon: 'pi pi-pencil',
                command: (e) => {
                    this.edit_typeserv(rowData);
                }
            },
            {
                label: 'Cotation',
                icon: 'pi pi-check-square',
                command: (e) => {
                    this.toogleDlg_kv(rowData);
                }
            },
            {
                label: 'Acteurs',
                icon: 'pi pi-sitemap',
                command: (e) => {
                    this.show_dlg_param(rowData);
                }
            }
        ]
        return <SplitButton className='p-button-info' onClick={(e) => this.toogleDlg_kv(rowData)} icon="pi pi-sitemap" style={{ width: 80 }} model={items}></SplitButton>

        // return <SplitButton className='p-button-info' onClick={(e) => this.edit_typeserv(rowData)} icon="pi pi-pencil" style={{ width: 80 }} model={items}></SplitButton>

    }



    actionTemplatePeval(rowData) {
        return <div className="p-grid" style={{ width: 90 }}>
            <div className="p-col"><Button title='Modifier' onClick={() => this.edit_param_eval(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button title='Supprimer' onClick={() => this.conf_delete_param_eval(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -20 }} icon="pi pi-times" /></div>
        </div>
    }

    actionTemplate_paramType(rowData) {
        return <div className="p-grid" style={{ width: 50 }}>
            <div className="p-col"><Button title='Supprimer' onClick={() => this.conf_delete_param_type(rowData)} className="p-button-danger" style={{ width: 25, height: 25 }} icon="pi pi-times" /></div>
        </div>
    }

    delete_typeserv() {
        if (this.state.selectedType == null || this.state.selectedType == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.FbrService.delete_typeserv(this.state.selectedType).then(data => this.resultat(data.code, data.contenu));
        }
    }

    delete_param_eval() {
        if (this.state.selectedParamEval == null || this.state.selectedParamEval == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.FbrService.delete_param_eval(this.state.selectedParamEval).then(data => this.resultat_param_eval(data.code, data.contenu));
        }
    }

    conf_delete_service(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedServ: data });
            this.setState({ msg: data.libSrv });
            this.setState({ visible: true });
        }
    }

    conf_delete_typeserv(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedType: data });
            this.setState({ msg: data.libType });
            this.setState({ visible: true });
        }
    }

    conf_delete_param_type(data) {
        this.setState({ showindicator: true });
        this.FbrService.delete_param_type(data).then(data => this.resultat_param_type(data.code, data.contenu));
    }

    conf_delete_kv(data) {
        this.setState({ showindicator: true });
        this.FbrService.delete_key_value(data).then(data => this.resultat_kv(data.code, data.contenu));
    }

    itemTemplate(option) {
        if (!option.value) {
            return option.label;
        }
        else {
            return (
                <div className="p-clearfix">
                    <div className="p-grid">
                        <div className="p-col-12 p-md-8">
                            <label>{option.label}</label>
                        </div>
                        <div className="p-col-12 p-md-4">
                            <label>{option.libTypePrest}</label>
                        </div>
                    </div>
                </div>
            );
        }
    }

    get_liste_prestataire() {
        this.setState({ showindicator: true });
        this.FbrService.get_liste_prestataire().then(data => this.setState({ showindicator: false }, () => {
            if (data !== undefined && data !== null) {
                let liste = data.map(x => {
                    return { value: x.idPrest, label: x.nomPrest, typePrest: x.typePrest.idCodif, libTypePrest: x.typePrest.libCodif, idBailleur: x.idBailleur.idCodif }
                });
                this.setState({ listeprest: liste });
            }
        }));
    }

    exportCSV = () => {
        this.dt.exportCSV();
    }

    leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Nouveau" icon="pi pi-plus" className="p-button-success p-mr-2" style={{ width: 120 }} onClick={this.toogleDlg_typeserv} />
                <Button label="Affectation" icon="pi pi-sitemap" style={{ width: 120 }} className="p-button-info" onClick={(e) => {
                    this.get_liste_prestataire();
                    this.setState({ visible_dlg_affectation_param: true });
                }} />
            </React.Fragment>
        )
    }

    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={this.exportCSV} />}
            </React.Fragment>
        )
    }

    edit_service(data) {
        if (data != null && data != undefined) {
            this.setState({ idSrv: data.idSrv });
            this.setState({ libSrv: data.libSrv });
            this.setState({ descSrv: data.descSrv });
            this.setState({ modif: true });
            this.setState({ selectedArt: data });
        }
    }

    annule_service() {
        this.setState({ libSrv: '' });
        this.setState({ descSrv: '' });
        this.setState({ idSrv: 0 });
        this.setState({ modif: false });
        this.forceUpdate();
    }

    enreg_service() {
        if (this.state.libSrv == "" || this.state.descSrv == "") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            this.setState({ showindicator: true });
            if (this.state.modif == false) {
                let service = {
                    idSrv: 0,
                    libSrv: this.state.libSrv,
                    descSrv: this.state.descSrv
                }
                this.FbrService.AddService(service)
                    .then(data => this.resultat(data.code, data.contenu));
            } else {
                let service = {
                    idSrv: this.state.idSrv,
                    libSrv: this.state.libSrv,
                    descSrv: this.state.descSrv
                }
                this.FbrService.UpdateService(service, service.idSrv)
                    .then(data => this.resultat(data.code, data.contenu));
            }
        }
    }

    actionTemplate_Service(rowData) {
        return <div className="p-grid" style={{ width: 90 }}>
            <div className="p-col"><Button title='Modifier' onClick={() => this.edit_service(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button title='Supprimer' onClick={() => this.conf_delete_service(rowData)} className="p-button-danger" style={{ width: 25, height: 25 }} icon="pi pi-times" /></div>
        </div>
    }

    enreg_kv() {
        if (this.state.keyItem == "" || this.state.keyItem == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            this.setState({ showindicator: true });
            if (this.state.modif_kv == false) {
                let kv = {
                    idItem: 0,
                    keyItem: this.state.keyItem,
                    valueItem: this.state.valueItem,
                    refId: this.state.selectedType.idType,
                    libRef: 'TYPE_SERVICE'
                };
                this.FbrService.enreg_key_value(kv).then(data => this.resultat_kv(data.code, data.contenu));
            } else {
                let kv = this.state.selectedKv;
                kv.keyItem = this.state.keyItem;
                kv.valueItem = this.state.valueItem;
                this.FbrService.enreg_key_value(kv).then(data => this.resultat_kv(data.code, data.contenu));
            }
        }
    }

    get_liste_param_eval_forAdingPrest(typePrest, idBailleur) {
        this.setState({ showindicator: true });
        this.FbrService.get_liste_param_eval_forAdingPrest(typePrest, idBailleur).then(data => {
            this.setState({ showindicator: false });
            this.setState({ liste_param_eval_adding: data });
        });
    }

    get_liste_param_eval_forAding() {
        if (this.state.idPrest !== null && this.state.idPrest !== undefined && this.state.idPrest !== '') {
            this.setState({ showindicator: true });
            this.FbrService.get_liste_param_eval_forAding(this.state.idPrest, 'Tous', this.state.idBailleurType).then(data => {
                if (data !== undefined && data !== null) {
                    this.setState({ liste_param_eval_adding: data, showindicator: false })
                }
            });
        }
    }

    get_liste_param_eval(ridPrest, ridType) {
        this.FbrService.get_liste_param_eval(ridPrest, ridType).then(data => {
            let liste = data;
            if (liste.length > 0 && this.state.visible_param_eval_prest == true) {
                for (var i = liste.length; i--;) {
                    if (liste[i].idType.categorieType == 'Quantitative') {
                        liste[i].oldValue = liste[i].puPeval;
                        let new_montant = 0.01 * liste[i].idPrest.categoriePrest.valCodif * liste[i].idType.puRefType + liste[i].idType.puRefType;
                        liste[i].puPeval = new_montant;
                    } else {
                        liste[i].oldValue = liste[i].maxPeval;
                    }
                }
            }
            this.setState({ liste_param_eval: liste, showindicator: false });

        });
    }

    get_liste_param_type() {
        this.setState({ showindicator: true });
        this.FbrService.get_liste_param_type(this.state.selectedType.idType, 'Tous').then(data => this.setState({ liste_param_type: data, showindicator: false }));
    }

    actionTemplate_ligne(rowData) {
        return <Button title='Supprimer' onClick={() => this.delete_ligne_param(rowData)} className="p-button-danger" style={{ width: 25, height: 25 }} icon="pi pi-times" />
    }

    delete_ligne_param(data) {
        for (var i = this.state.liste_param_eval_adding.length; i--;) {
            if (data.idType.libType == this.state.liste_param_eval_adding[i].idType.libType) {
                this.state.liste_param_eval_adding.splice(i, 1);
                break;
            }
        }
        this.forceUpdate();
    }

    conf_delete_param_eval(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedParamEval: data });
            this.setState({ msg: data.idType.libType });
            this.setState({ visible_param_eval: true });
        }
    }

    actionTemplate_kv(rowData) {
        return <div className="p-grid" style={{ width: 90 }}>
            <div className="p-col"><Button title='Modifier' onClick={() => this.edit_kv(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button title='Supprimer' onClick={() => this.conf_delete_kv(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -20 }} icon="pi pi-times" /></div>
        </div>
    }

    render() {
        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_typeserv();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        const dialogFooterPeval = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible_param_eval: false });
                    this.delete_param_eval();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible_param_eval: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        const dp = <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Dropdown value={this.state.idPrest} options={this.state.listeprest} optionLabel='label' filter={true} filterBy='label,libTypePrest' onChange={(e) => this.setState({ idPrest: e.target.value }, () => {
                if (this.state.activeIndex == 0) {
                    let val = this.FbrService.getPrest(this.state.listeprest, this.state.idPrest);
                    if (val !== null && val !== undefined) {
                        this.setState({ idBailleurType: val.idBailleur }, () => {
                            this.get_liste_param_eval_forAding();
                        })
                    }
                } else {
                    this.get_liste_param_eval(this.state.idPrest, 'Tous');
                }
            })} autoWidth={false} style={{ width: 300 }} placeholder='Sélectionner un acteur' itemTemplate={this.itemTemplate} panelStyle={{ width: '500px' }} />
            {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
        </div>


        return (
            <div className="p-fluid">
                <Toast ref={(el) => this.toast = el} />
                <Toolbar className="p-mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>

                <Dialog maximizable visible={this.state.visible_param_eval_prest} style={{ width: '75%' }} header="Affectation" modal className="p-fluid" onHide={(e) => this.setState({ visible_param_eval_prest: false })}>
                    {this.state.selectedType !== undefined && this.state.selectedType !== null ?
                        <>
                            {this.state.selectedType.categorieType == 'Quantitative' ?
                                <DataTable value={this.state.liste_param_eval}
                                    responsive={true} scrollable scrollHeight="250px" className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                                    onSelectionChange={e => this.setState({ selectedParamEval: e.value })} responsive={true} selection={this.state.selectedParamEval} selectionMode="single">
                                    <Column field="Index" header="N°" body={this.onIndexTemplate} style={{ width: '5%' }} />
                                    <Column field="idPrest.nomPrest" filter={true} filterMatchMode='contains' header="Acteur" sortable={true} />
                                    <Column field="idPrest.categoriePrest.libCodif" body={(rowData, column) => {
                                        return <div>
                                            <span>{rowData.idPrest.categoriePrest.libCodif} - {rowData.idPrest.categoriePrest.valCodif} %</span>
                                        </div>
                                    }} filter={true} filterMatchMode='contains' header="Catégorie" sortable={true} style={{ width: '15%' }} />
                                    <Column field="idPrest.idSite.regionSite" filter={true} filterMatchMode='contains' header="Region" sortable={true} style={{ width: '10%' }} />
                                    <Column field="idPrest.idSite.prefectureSite" filter={true} filterMatchMode='contains' header="Prefecture" sortable={true} style={{ width: '11%' }} />
                                    <Column body={(rowData, column) => {
                                        return <div>
                                            <span>{this.FbrService.formaterValueSep(parseFloat(this.state.selectedType.puRefType))}</span>
                                        </div>
                                    }} filter={true} filterMatchMode='contains' header="Prix de base" style={{ width: '10%' }} />
                                    <Column body={(rowData, column) => {
                                        return <div>
                                            <span>{this.FbrService.formaterValueSep(parseFloat(rowData.oldValue))}</span>
                                        </div>
                                    }} filter={true} filterMatchMode='contains' header="Ancien prix" style={{ width: '10%' }} />
                                    <Column field="puPeval" body={(rowData, column) => {
                                        return <div>
                                            <span style={{ fontWeight: 'bold', color: rowData.oldValue !== rowData.puPeval ? 'red' : 'black' }}>{this.FbrService.formaterValueSep(parseFloat(rowData.puPeval))}</span>
                                        </div>
                                    }} filter={true} filterMatchMode='contains' header="Prix acteur" sortable={true} style={{ width: '11%' }} editor={this.puPevalEditor} />
                                </DataTable> :
                                <DataTable value={this.state.liste_param_eval}
                                    responsive={true} scrollable scrollHeight="250px" className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                                    onSelectionChange={e => this.setState({ selectedParamEval: e.value })} responsive={true} selection={this.state.selectedParamEval} selectionMode="single">
                                    <Column field="Index" header="N°" body={this.onIndexTemplate} style={{ width: '5%' }} />
                                    <Column field="idPrest.nomPrest" filter={true} filterMatchMode='contains' header="Acteur" sortable={true} />
                                    <Column field="idPrest.categoriePrest.libCodif" body={(rowData, column) => {
                                        return <div>
                                            <span>{rowData.idPrest.categoriePrest.libCodif} - {rowData.idPrest.categoriePrest.valCodif} %</span>
                                        </div>
                                    }} filter={true} filterMatchMode='contains' header="Catégorie" sortable={true} style={{ width: '15%' }} />
                                    <Column field="idPrest.idSite.regionSite" filter={true} filterMatchMode='contains' header="Region" sortable={true} style={{ width: '12%' }} />
                                    <Column field="idPrest.idSite.prefectureSite" filter={true} filterMatchMode='contains' header="Prefecture" sortable={true} style={{ width: '12%' }} />
                                    <Column body={(rowData, column) => {
                                        return <div>
                                            <span>{this.FbrService.formaterValueSep(parseFloat(rowData.oldValue))}</span>
                                        </div>
                                    }} filter={true} filterMatchMode='contains' header="Ancienne note" style={{ width: '12%' }} />
                                    <Column field="maxPeval" body={(rowData, column) => {
                                        return <div>
                                            <span style={{ fontWeight: 'bold', color: rowData.oldValue !== rowData.maxPeval ? 'red' : 'black' }}>{rowData.maxPeval}</span>
                                        </div>
                                    }} filter={true} filterMatchMode='contains' header="Note" sortable={true} style={{ width: '15%' }} editor={this.maxPevalEditor} />
                                </DataTable>}
                        </>
                        : null
                    }
                    <center>
                        <hr />
                        <>
                            <Button label="Annuler" icon="pi pi-times" className="p-button-text" style={{ width: 120 }} onClick={(e) => this.setState({ visible_param_eval_prest: false })} />
                            <Button label="Valider" icon="pi pi-check" onClick={(e) => this.update_param_eval_batch()} style={{ width: 120 }} />
                            {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                        </>
                    </center>
                </Dialog>



                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={() => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>

                <Dialog header="Cotation" visible={this.state.visible_kv} style={{ width: '630px' }} modal={true} onHide={(e) => this.setState({ visible_kv: false })}>
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div style={{ height: 400 }}>
                                <div className="card card-w-title"  style={{ backgroundColor: '#E8FBFE' }}>
                                    <label style={{ fontWeight: 'bold', fontSize: 15 }}>{this.state.selectedType !== undefined && this.state.selectedType !== null ? this.state.selectedType.libType : ""}</label>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-2">
                                        <label htmlFor="profile">Appréciation *</label>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <InputText value={this.state.keyItem} onChange={(e) => this.setState({ keyItem: e.target.value })} />
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <label htmlFor="profile">Cotation *</label>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <InputNumber min={0} max={100} value={this.state.valueItem} onChange={(e) => this.setState({ valueItem: e.value })} />
                                    </div>
                                </div>
                                <DataTable value={this.state.liste_kv} selectionMode="single" scrollable={true} scrollHeight="180px"
                                    onRowClick={e => this.setState({ selectedKv: e.data })} onRowSelect={e => this.setState({ selectedKv: e.data })}
                                    responsive={true} selection={this.state.selectedKv} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers" >
                                    <Column header="Action" body={this.actionTemplate_kv.bind(this)} style={{ width: '20%' }} />
                                    <Column field="valueItem" header="Cotation" style={{ width: '30%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                    <Column field="keyItem" header="Appréciation" style={{ width: '50%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                </DataTable>
                            </div>
                            <center>
                                <div className="p-grid" style={{ width: 300, marginBottom: -10 }}>
                                    <div className="p-col"><Button label="Fermer" icon="pi pi-ban" onClick={() => {
                                        this.annule_kv();
                                        this.setState({ visible_kv: false });
                                    }} /></div>
                                    <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={() => this.enreg_kv()} /></div>
                                </div>
                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                            </center>
                        </div>
                    </div>
                </Dialog>

                <Dialog header="Modification" visible={this.state.visible_param_type} style={{ width: '380px' }} modal={true} onHide={() => this.setState({ visible_param_type: false })}>
                    <div className="card" style={{}}>
                        <div className="p-grid">
                            <div className="p-col-12">
                                <label >Type acteur *</label>
                                <Dropdown value={this.state.cibleType} options={this.state.liste_cible} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ cibleType: e.target.value })} autoWidth={false} appendTo={document.body} />
                            </div>
                        </div>
                        <center>
                            <div className="p-grid" style={{ width: 280 }}>
                                <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={() => {
                                    this.setState({ visible_param_type: false });
                                }} style={{ width: 110 }} /></div>
                                <div className="p-col"><Button label="Ajouter" icon="pi pi-check" onClick={() => this.enreg_param_type()} style={{ width: 110, marginLeft: -20 }} /></div>
                            </div>
                            <br />
                            {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                        </center>
                        <DataTable value={this.state.liste_param_type}
                            responsive={true} scrollable scrollHeight="300px" className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                            onSelectionChange={e => this.setState({ selectedParamType: e.value })} responsive={true} selection={this.state.selectedParamType} selectionMode="single">
                            <Column header="#" body={this.actionTemplate_paramType.bind(this)} style={{ width: '15%' }} />
                            <Column field="typePrest.libCodif" filter={true} filterMatchMode='contains' header="Type acteur" sortable={true} />
                        </DataTable>
                    </div>
                </Dialog>

                <Dialog header="Modification" visible={this.state.modif_param_eval} style={{ width: '350px' }} modal={true} onHide={() => this.setState({ modif_param_eval: false })}>
                    {this.state.selectedParamEval !== null && this.state.selectedParamEval !== undefined ?
                        <div className="card">
                            <div className="p-grid">
                                <div className="p-col-12">
                                    {this.state.selectedParamEval.idType.categorieType == 'Quantitative' ?
                                        <label >Prix unit. *</label>
                                        :
                                        <label >Note max. *</label>
                                    }
                                    {this.state.selectedParamEval.idType.categorieType == 'Quantitative' ?
                                        <InputNumber value={this.state.puPeval} onChange={(e) => this.setState({ puPeval: e.value })} />
                                        :
                                        <InputNumber value={this.state.maxPeval} onChange={(e) => this.setState({ maxPeval: e.value })} />
                                    }
                                </div>
                            </div>
                            <center>
                                <div className="p-grid" style={{ width: 280 }}>
                                    <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={() => {
                                        this.setState({ modif_param_eval: false });
                                    }} style={{ width: 110 }} /></div>
                                    <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={() => this.enreg_param_eval()} style={{ width: 110, marginLeft: -20 }} /></div>
                                </div>
                                <br />
                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                            </center>
                        </div>
                        : null}

                </Dialog>

                <Dialog header="Confirmation" visible={this.state.visible_param_eval} style={{ width: '250px' }} modal={true} footer={dialogFooterPeval} onHide={() => this.setState({ visible_param_eval: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>

                <Dialog maximizable visible={this.state.visible_dlg_affectation_param} style={{ width: '80%' }} header="Affectation" modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_affectation_param: false })}>

                    <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({ activeIndex: e.index }, () => {
                        if (this.state.activeIndex == 0) {
                            this.get_liste_param_eval_forAding();
                        } else {
                            this.get_liste_param_eval(this.state.idPrest, 'Tous');
                        }
                    })}>
                        <TabPanel header="Nouvelle affectation">
                            <div className="card card-w-title" style={{ height: 460, marginTop: -20 }}>
                                <DataTable value={this.state.liste_param_eval_adding} header={dp}
                                    responsive={true} scrollable scrollHeight="300px" className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                                    onSelectionChange={e => this.setState({ selectedParamEval: e.value })} responsive={true} selection={this.state.selectedParamEval} selectionMode="single">
                                    <Column header="Action" body={this.actionTemplate_ligne.bind(this)} style={{ width: '5%' }} />
                                    <Column field="Index" header="N°" body={this.onIndexTemplate} style={{ width: '5%' }} />
                                    <Column field="idType.libType" filter={true} filterMatchMode='contains' header="Désignation" sortable={true} />
                                    <Column field="idType.categorieType" filter={true} filterMatchMode='contains' header="Catégorie" sortable={true} style={{ width: '15%' }} />
                                    <Column field="puPeval" body={(rowData, column) => {
                                        return <div>
                                            <span>{this.FbrService.formaterValueSep(parseFloat(rowData.puPeval))}</span>
                                        </div>
                                    }} filter={true} filterMatchMode='contains' header="Prix unit." sortable={true} style={{ width: '12%' }} editor={this.puPevalEditor} />
                                    <Column field="maxPeval" filter={true} filterMatchMode='contains' header="Note max." sortable={true} style={{ width: '12%' }} editor={this.maxPevalEditor} />
                                </DataTable>
                            </div>
                            <center>
                                <>
                                    <Button label="Annuler" icon="pi pi-times" className="p-button-text" style={{ width: 120 }} onClick={(e) => this.setState({ visible_dlg_affectation_param: false })} />
                                    <Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_param_eval_batch()} style={{ width: 120 }} />
                                </>
                                <br />
                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                            </center>

                        </TabPanel>
                        <TabPanel header="Modification affectation">
                            <div className="card card-w-title" style={{ height: 460, marginTop: -20 }}>
                                <DataTable value={this.state.liste_param_eval} header={dp}
                                    responsive={true} scrollable scrollHeight="300px" className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                                    onSelectionChange={e => this.setState({ selectedParamEval: e.value })} selection={this.state.selectedParamEval} selectionMode="single">
                                    <Column header="Action" body={this.actionTemplatePeval.bind(this)} style={{ width: '8%' }} />
                                    <Column field="Index" header="N°" body={this.onIndexTemplate} style={{ width: '4%' }} />
                                    <Column field="idType.libType" filter={true} filterMatchMode='contains' header="Désignation" sortable={true} />
                                    <Column field="idType.categorieType" filter={true} filterMatchMode='contains' header="Catégorie" sortable={true} style={{ width: '12%' }} />
                                    <Column field="puPeval" body={(rowData, column) => {
                                        return <div>
                                            <span>{this.FbrService.formaterValueSep(parseFloat(rowData.puPeval))}</span>
                                        </div>
                                    }} filter={true} filterMatchMode='contains' header="Prix unit." sortable={true} style={{ width: '10%' }} />
                                    <Column field="maxPeval" filter={true} filterMatchMode='contains' header="Note max." sortable={true} style={{ width: '12%' }} />
                                </DataTable>
                            </div>

                        </TabPanel>

                    </TabView>

                </Dialog>


                <Dialog visible={this.state.visible_dlg_form_typeserv} style={{ width: '95%' }} header={this.state.modif == true ? "Modification" : "Nouveau indicateur"} modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_form_typeserv: false })} maximizable>
                    <div className="card p-fluid" style={{ backgroundColor: '#f7f7f7' }}>
                        <div className="p-grid">
                            <div className="p-col-12 p-md-1">
                                <label htmlFor="libType">Désignation *</label>
                            </div>
                            <div className="p-col-12 p-md-3">
                                <InputTextarea rows="2" value={this.state.libType} onChange={(e) => this.setState({ libType: e.target.value })} />
                            </div>
                            <div className="p-col-12 p-md-1">
                                <label htmlFor="srceVerifType">Abréviation</label>
                            </div>
                            <div className="p-col-12 p-md-3">
                                <InputText value={this.state.abvType} onChange={(e) => this.setState({ abvType: e.target.value })} />
                            </div>

                            <div className="p-col-12 p-md-1">
                                <label htmlFor="typeActeur">Type acteur *</label>
                            </div>
                            <div className="p-col-12 p-md-3">
                                <Dropdown optionLabel="label" value={this.state.cibleType} options={this.state.liste_cible} onChange={(e) => this.setState({ cibleType: e.target.value }, () => {
                                    this.get_liste_param_eval_forAdingPrest(this.state.cibleType, this.state.idBailleurType);
                                })} filter={true} filterBy='label' filterMatchMode='contains' />
                            </div>

                            <div className="p-col-12 p-md-1">
                                <label htmlFor="categorieType">Catégorie *</label>
                            </div>
                            <div className="p-col-12 p-md-3">
                                <Dropdown value={this.state.categorieType} options={this.state.liste_categorie} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ categorieType: e.target.value }, () => {
                                    if (this.state.categorieType == 'Enquete') {
                                        this.setState({ critereNoteType: 'Choix unique' })
                                    } else {
                                        this.setState({ critereNoteType: '' })
                                    }
                                })} autoWidth={false} />
                            </div>

                            <div className="p-col-12 p-md-1">
                                {this.state.categorieType == 'Quantitative' ?
                                    <label >Prix unitaire *</label>
                                    :
                                    <label >Note maximale *</label>
                                }
                            </div>
                            <div className="p-col-12 p-md-3">
                                {this.state.categorieType == 'Quantitative' ?
                                    <InputNumber value={this.state.puRefType} onChange={(e) => this.setState({ puRefType: e.value })} />
                                    :
                                    <InputNumber value={this.state.noteMaxType} onChange={(e) => this.setState({ noteMaxType: e.value })}
                                        onBlur={(e) => {
                                            if (this.state.categorieType == 'Enquete') {
                                                if (this.state.liste_param_eval_adding !== null && this.state.liste_param_eval_adding !== undefined) {
                                                    for (var i = this.state.liste_param_eval_adding.length; i--;) {
                                                        this.state.liste_param_eval_adding[i].maxPeval = this.state.noteMaxType;
                                                    }
                                                }
                                                this.forceUpdate();
                                            }
                                        }}
                                    />
                                }
                            </div>

                            <div className="p-col-12 p-md-1">
                                <label htmlFor="idPrest">Bailleur * </label>
                            </div>
                            <div className="p-col-12 p-md-3">
                                <Dropdown placeholder="Sélectionner" autoWidth={false} value={this.state.idBailleurType} filter={true} filterBy='label' filterMatchMode='contains' options={this.state.liste_bailleur} onChange={(e) => {
                                    this.setState({ idBailleurType: e.value }, () => {
                                        this.get_liste_param_eval_forAdingPrest(this.state.cibleType, this.state.idBailleurType);
                                    });
                                }} appendTo={document.body} />
                            </div>

                            <div className="p-col-12 p-md-1">
                                <label htmlFor="libType">Critères d'appréciation </label>
                            </div>
                            <div className="p-col-12 p-md-3">
                                <InputTextarea rows="2" value={this.state.critereAppType} onChange={(e) => this.setState({ critereAppType: e.target.value })} />
                            </div>

                            <div className="p-col-12 p-md-1">
                                <label htmlFor="libType">Critères de notation </label>
                            </div>
                            <div className="p-col-12 p-md-3">
                                {this.state.categorieType == 'Enquete' ?
                                    <Dropdown value={this.state.critereNoteType} options={[{ label: 'Choix unique', value: 'Choix unique' }, { label: 'Saisie', value: 'Saisie' }]} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ critereNoteType: e.target.value })} autoWidth={false} appendTo={document.body} />
                                    : <InputTextarea rows="2" value={this.state.critereNoteType} onChange={(e) => this.setState({ critereNoteType: e.target.value })} />}
                            </div>

                            <div className="p-col-12 p-md-1">
                                <label htmlFor="noteMaxType">Famille indicateur *</label>
                            </div>
                            <div className="p-col-12 p-md-3">
                                <Dropdown value={this.state.idFamille} options={this.state.liste_famille} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ idFamille: e.target.value })} autoWidth={false} panelStyle={{ width: '700px' }} appendTo={document.body} />
                            </div>

                            <div className="p-col-12 p-md-1">
                                <label htmlFor="srceVerifType">Source vérification</label>
                            </div>
                            <div className="p-col-12 p-md-3">
                                <InputText id="srceVerifType" value={this.state.srceVerifType} onChange={(e) => this.setState({ srceVerifType: e.target.value })} />
                            </div>
                            <div className="p-col-12 p-md-1">
                                <label htmlFor="idPrest">Rang * </label>
                            </div>
                            <div className="p-col-12 p-md-3">
                                <InputNumber value={this.state.rangType} onChange={(e) => this.setState({ rangType: e.value })} />
                            </div>
                        </div>
                        {this.state.modif == false ?
                            <>
                                {this.state.categorieType == 'Quantitative' ?
                                    <DataTable value={this.state.liste_param_eval_adding}
                                        responsive={true} scrollable scrollHeight="175px" className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                                        onSelectionChange={e => this.setState({ selectedParamEval: e.value })} selection={this.state.selectedParamEval} selectionMode="single">
                                        <Column field="Index" header="N°" body={this.onIndexTemplate} style={{ width: '4%' }} />
                                        <Column field="idPrest.nomPrest" filter={true} filterMatchMode='contains' header="Acteur" sortable={true} />
                                        <Column field="puPeval" body={(rowData, column) => {
                                            return <div>
                                                <span>{this.FbrService.formaterValueSep(parseFloat(rowData.puPeval))}</span>
                                            </div>
                                        }} filter={true} filterMatchMode='contains' header="Prix unitaire" sortable={true} style={{ width: '15%' }} editor={this.puPevalEditor} />
                                    </DataTable>
                                    :
                                    <DataTable value={this.state.liste_param_eval_adding}
                                        responsive={true} scrollable scrollHeight="175px" className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                                        onSelectionChange={e => this.setState({ selectedParamEval: e.value })} responsive={true} selection={this.state.selectedParamEval} selectionMode="single">
                                        <Column field="Index" header="N°" body={this.onIndexTemplate} style={{ width: '4%' }} />
                                        <Column field="idPrest.nomPrest" filter={true} filterMatchMode='contains' header="Acteur" sortable={true} />
                                        <Column field="maxPeval" filter={true} filterMatchMode='contains' header="Note maximal" sortable={true} style={{ width: '15%' }} editor={this.maxPevalEditor} />
                                    </DataTable>
                                }
                            </>
                            : null
                        }

                        <center>
                            <div className="p-grid" style={{ width: 300 }}>
                                <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={() => this.annule_typeserv()} /></div>
                                <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={() => this.enreg_typeserv()} /></div>
                                <div className="p-col">{this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}</div>
                            </div>
                        </center>
                    </div>
                </Dialog>

                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title">
                            <DataTable value={this.state.listetypeserv} selectionMode="single" scrollable scrollHeight="600px"
                                onRowClick={e => this.setState({ selectedType: e.data })} onRowSelect={e => this.setState({ selectedType: e.data })} ref={(el) => { this.dt = el; }}
                                responsive={true} selection={this.state.selectedType} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers">
                                <Column header="Action" body={this.actionTemplate.bind(this)} style={{ width: '10%' }} />
                                {/* <Column field="idType" filter={true} filterMatchMode='contains' header="ID" sortable={true} style={{ width: '10%' }} /> */}
                                {/* <Column field="noteMaxType" filter={true} filterMatchMode='contains' header="Max" sortable={true} style={{ width: '6%' }} /> */}
                                <Column field="rangType" filter={true} filterMatchMode='contains' header="No" sortable={true} style={{ width: '7%' }} />
                                <Column field="libType" filter={true} filterMatchMode='contains' header="Désignation" sortable={true} />
                                <Column field="cibleType.libCodif" filter={true} filterMatchMode='contains' header="Acteur" sortable={true} style={{ width: '9%' }} />
                                <Column field="categorieType" filter={true} filterMatchMode='contains' header="Catégorie" sortable={true} style={{ width: '11%' }} />
                                <Column field="idFamille.libCodif" filter={true} filterMatchMode='contains' header="Famille" sortable={true} style={{ width: '22%' }} />
                                {/* <Column field="puRefType" body={(rowData, column) => {
                                    return <div>
                                        <span>
                                            {rowData.categorieType == 'Quantitative' ? this.FbrService.formaterValueSep(parseFloat(rowData.puRefType)) : rowData.noteMaxType}
                                        </span>
                                    </div>
                                }} filter={true} filterMatchMode='contains' header="Prix/Note" style={{ width: '7%' }} /> */}
                                <Column field="idBailleurType.libCodif" filter={true} filterMatchMode='contains' header="Bailleur" sortable={true} style={{ width: '10%' }} />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}