import React, { Component } from 'react';
import FbrService from '../service/FbrService';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { SplitButton } from 'primereact/splitbutton';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { RadioButton } from "primereact/radiobutton";

export class FormResultatEnquete extends Component {
    constructor() {
        super();
        this.state = {
            pd1: new Date(moment('01/01/' + moment(new Date()).format('YYYY'), 'YYYY-MM-DD').format('YYYY-MM-DD')),
            pd2: new Date(),
            liste_enquete: [],
            listeprest: [],
            selectedEnquete: null,
            idResEnq: null,
            dateResEnq: new Date(),
            debResEnq: new Date(),
            finResEnq: new Date(),

            idPrest: null,

            idRlenq: null,
            valPrevExiste: 1,
            valConfExiste: 0,
            valPrevPrest: 1,
            valConfPrest: 0,
            valPrevSatisf: 20,
            valConfSatisf: 0,
            numFicheRlenq: '',
            numCasRlenq: '',
            cmtRlenq: '',
            liste_ligne_enquete: [],
            liste_ligne: [],
            selectedLenquete: null,

            ridPrest: null,
            msg: '',
            modif: false,
            modif_ligne: false,
            visible: false,
            visible_form_new_enq: false,
            visible_dlg_ligne: false,
            visible_del_ligne: false,
            visible_dlg_enq: false,
            showindicator: false,
        };
        this.edit_enquete = this.edit_enquete.bind(this);
        this.conf_delete_enquete = this.conf_delete_enquete.bind(this);
        this.conf_delete_ligne_enquete = this.conf_delete_ligne_enquete.bind(this);

        this.edit_ligne_enquete = this.edit_ligne_enquete.bind(this);
        this.footerTemplate = this.footerTemplate.bind(this);
        this.footerTemplate = this.footerTemplate.bind(this);
        //  this.footerTemplateLenquete = this.footerTemplateLenquete.bind(this);
        this.inputNumberEditor = this.inputNumberEditor.bind(this);

        this.valConfSatisfEditor = this.valConfSatisfEditor.bind(this);

        this.valConfExisteEditor = this.valConfExisteEditor.bind(this);
        this.valConfPrestEditor = this.valConfPrestEditor.bind(this);

        this.radioEditor = this.radioEditor.bind(this);

        this.numCasRlenqEditor = this.numCasRlenqEditor.bind(this);
        this.inputTextEditor = this.inputTextEditor.bind(this);
        this.conf_delete_new_ligne = this.conf_delete_new_ligne.bind(this);


        this.FbrService = new FbrService();

    }

    conf_delete_new_ligne(data) {
        for (var i = this.state.liste_ligne.length; i--;) {
            if (data.idRlenq == this.state.liste_ligne[i].idRlenq) {
                this.state.liste_ligne.splice(i, 1);
                break;
            }
        }
        this.forceUpdate();
    }


    componentDidMount() {
        this.get_liste_enquete();
        this.get_liste_prestataire();
    }

    numCasRlenqEditor(props) {
        return this.inputTextEditor(props, 'numCasRlenq');
    }

    inputTextEditor(props, field) {
        return <InputText value={props.rowData[field]} onChange={(e) => this.onEditorValueChange(props, e.target.value)} />;
    }

    onEditorValueChange(props, value) {
        //console.log(value);
        let updateds = [...props.value];
        updateds[props.rowIndex][props.field] = value;
        this.setState({ liste_ligne: updateds });
        this.forceUpdate();
    }




    valConfSatisfEditor(props) {
        return this.inputNumberEditor(props, 'valConfSatisf');
    }


    inputNumberEditor(props, field) {
        if (field == 'valConfSatisf') {
            return <InputNumber style={{ width: '100px' }} showButtons buttonLayout="horizontal" decrementButtonClassName="p-button-danger" incrementButtonClassName="p-button-success" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" max={20} min={0} step={5} value={props.rowData[field]} onChange={(e) => this.onEditorValueChange(props, e.value)} />;
        } else {
            return <InputNumber style={{ width: '100px' }} showButtons buttonLayout="horizontal" decrementButtonClassName="p-button-danger" incrementButtonClassName="p-button-success" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" max={1} min={0} value={props.rowData[field]} onChange={(e) => this.onEditorValueChange(props, e.value)} />;
        }
    }



    valConfExisteEditor(props) {
        //return this.radioEditor(props, 'valConfExiste');
        return this.inputNumberEditor(props, 'valConfExiste');
    }

    valConfPrestEditor(props) {
        return this.inputNumberEditor(props, 'valConfPrest');
        // return this.radioEditor(props, 'valConfPrest');
    }


    radioEditor(props, field) {
        let list = [{ key: 1, name: 'Oui' }, { key: 0, name: 'Non' }];
        return <div className="p-grid" style={{ width: '98%', paddingTop: 20, paddingLeft: 5 }}>
            {list.map((item) => {
                return (
                    <div key={item.key} className="p-col p-field-radiobutton" style={{ marginLeft: -8 }}>
                        <RadioButton inputId={item.key} name="item" value={item} onChange={(e) => {
                            this.onEditorValueChange(props, e.value);
                        }} checked={props.rowData[field] === item.key} />
                        <label htmlFor={item.key}> {item.name} </label>
                    </div>
                );
            })}
        </div>
    }


    get_liste_prestataire() {
        this.setState({ showindicator: true });
        this.FbrService.get_liste_prestataire_eval().then(data => {
            this.setState({ showindicator: false });
            if (data !== null && data !== undefined && data !== '') {
                if (data.length > 0) {
                    let liste = data.map(x => {
                        return { value: x.idPrest, label: x.nomPrest, typePrest: x.typePrest.libCodif }
                    });
                    this.setState({ listeprest: liste });
                }
            }
        });
    }

    actionTemplate_new_ligne(rowData) {
        return <Button onClick={() => this.conf_delete_new_ligne(rowData)} className="p-button-danger" style={{ width: 25, height: 25 }} icon="pi pi-times" />
    }

    onIndexTemplate(data, props) {
        return props.rowIndex + 1;
    }

    footerTemplate() {
        let valPrevExiste = 0;
        let valConfExiste = 0;
        let valPrevPrest = 0;
        let valConfPrest = 0;
        let valPrevSatisf = 0;
        let valConfSatisf = 0;

        if (this.state.liste_ligne) {
            for (let row of this.state.liste_ligne) {
                valPrevExiste = valPrevExiste + row.valPrevExiste;
                valConfExiste = valConfExiste + row.valConfExiste;
                valPrevPrest = valPrevPrest + row.valPrevPrest;
                valConfPrest = valConfPrest + row.valConfPrest;
                valPrevSatisf = valPrevSatisf + row.valPrevSatisf;
                valConfSatisf = valConfSatisf + row.valConfSatisf;
            }
        }

        return (
            <React.Fragment>
                <td colSpan="2" style={{ textAlign: 'right', fontWeight: 'bold' }}>TOTAL </td>
                <td style={{ fontWeight: 'bold' }}>{valPrevExiste}</td>
                <td style={{ fontWeight: 'bold' }}>{valConfExiste}</td>
                <td style={{ fontWeight: 'bold' }}>{valPrevExiste} </td>
                <td style={{ fontWeight: 'bold' }}>{valConfPrest} </td>
                <td style={{ fontWeight: 'bold' }}>{20 * valPrevExiste} </td>
                <td style={{ fontWeight: 'bold' }}>{valConfSatisf} </td>
            </React.Fragment>
        );
    }


    get_liste_ligne_enquete(idResEnq) {
        this.setState({ showindicator: true });
        this.FbrService.get_liste_ligne_resultat_enquete(idResEnq).then(data => this.setState({ liste_ligne_enquete: data, showindicator: false }));
    }

    get_liste_enquete() {
        this.setState({ showindicator: true });
        let spd1 = moment(this.state.pd1).format('DD/MM/YYYY');
        let spd2 = moment(this.state.pd2).format('DD/MM/YYYY');
        let ridpres = 'Tous';
        if (this.state.ridPrest !== undefined && this.state.ridPrest !== null && this.state.ridPrest !== '') {
            ridpres = this.state.ridPrest;
        }
        this.FbrService.get_liste_resultat_enquete(spd1, spd2, ridpres).then(data => this.setState({ liste_enquete: data, showindicator: false }));
    }


    resultat(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_enquete();
            this.componentDidMount();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }


    edit_ligne_enquete(data) {
        if (data != null && data != undefined) {
            this.setState({ idRlenq: data.idRlenq });
            this.setState({ valConfExiste: data.valConfExiste });
            this.setState({ valPrevExiste: data.valPrevExiste });
            this.setState({ numCasRlenq: data.numCasRlenq });
            this.setState({ valPrevPrest: data.valPrevPrest });
            this.setState({ valConfPrest: data.valConfPrest });

            this.setState({ valPrevSatisf: data.valPrevSatisf });
            this.setState({ valConfSatisf: data.valConfSatisf });

            this.setState({ modif_ligne: true, visible_dlg_ligne: true });
            this.setState({ selectedLenquete: data });
        }
    }

    edit_enquete(data) {
        if (data != null && data != undefined) {
            this.setState({ idResEnq: data.idResEnq });
            this.setState({ idPrest: data.idPrest });
            this.setState({ debResEnq: data.debResEnq });
            this.setState({ finResEnq: data.finResEnq });
            this.setState({ modif: true, visible_dlg_enq: true });
            this.setState({ selectedEnquete: data });
        }
    }

    annule_enquete() {
        this.setState({ idResEnq: 0 });
        //this.setState({ finResEnq: '' });
        //this.setState({ debResEnq: '' });
        this.setState({ modif: false, visible_dlg_enq: false });
        this.setState({ idPrest: null });
        this.setState({ liste_ligne: [] });
        this.forceUpdate();
    }

    resultat_ligne(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.get_liste_ligne_enquete(this.state.selectedEnquete.idResEnq);
            this.setState({ visible_dlg_ligne: false });
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    enreg_ligne() {
        if (this.state.selectedEnquete !== null && this.state.selectedEnquete != undefined) {
            if (this.state.numCasRlenq == "") {
                this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
                return;
            }
            if (this.state.modif_ligne == true) {
                this.setState({ showindicator: true });
                var ligne = this.state.selectedLenquete;
                ligne.numCasRlenq = this.state.numCasRlenq;
                ligne.valConfExiste = this.state.valConfExiste;
                ligne.valConfPrest = this.state.valConfPrest;
                ligne.valConfSatisf = this.state.valConfSatisf;
                this.FbrService.enreg_ligne_resultat_enquete(ligne).then(data => this.resultat_ligne(data.code, data.contenu));
            } else {
                let ligne = {
                    idRlenq: '0',
                    numCasRlenq: this.state.numCasRlenq,
                    valPrevExiste: 1,
                    valConfExiste: this.state.valConfExiste,
                    valPrevPrest: 1,
                    valConfPrest: this.state.valConfPrest,
                    valPrevSatisf: 20,
                    valConfSatisf: this.state.valConfSatisf,
                    idResEnq: this.state.selectedEnquete
                };
                this.FbrService.enreg_ligne_resultat_enquete(ligne).then(data => this.resultat_ligne(data.code, data.contenu));
            }
        } else {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez selectionner une ligne' });
        }
    }

    enreg_enq() {
        if (this.state.dateResEnq == "" || this.state.dateResEnq == null || this.state.idPrest == null) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            this.setState({ showindicator: true });
            let userconnected = this.FbrService.get_userconnected();
            if (this.state.modif == false) {
                let val = {
                    idResEnq: 0,
                    debResEnq: moment(this.state.debResEnq).format('DD/MM/YYYY'),
                    finResEnq: moment(this.state.finResEnq).format('DD/MM/YYYY'),
                    idPrest: { idPrest: this.state.idPrest },
                    iduser: userconnected.idUser,
                    idUser: { idUser: userconnected.idUser },
                    dateResEnq: moment(this.state.dateResEnq).format('DD/MM/YYYY')
                };
                for (var i = this.state.liste_ligne.length; i--;) {
                    this.state.liste_ligne[i].idResEnq = val;
                    this.state.liste_ligne[i].iduser = userconnected.idUser;
                }
                this.FbrService.enreg_resultat_enquete_batch(this.state.liste_ligne).then(data => this.resultat(data.code, data.contenu));
            } else {
                let val = this.state.selectedEnquete;
                val.debResEnq = moment(this.state.debResEnq).format('DD/MM/YYYY');
                val.finResEnq = moment(this.state.finResEnq).format('DD/MM/YYYY');
                val.iduser = userconnected.idUser;
                val.idPrest = { idPrest: this.state.idPrest };
                val.dateResEnq = moment(this.state.dateResEnq).format('DD/MM/YYYY');
                this.FbrService.enreg_resultat_enquete(val).then(data => this.resultat(data.code, data.contenu));
            }
        }
    }

    actionTemplate(rowData) {
        return <div className="p-grid" style={{ width: 90 }}>
            <div className="p-col"><Button title='Modifier' onClick={() => this.edit_enquete(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button title='Supprimer' onClick={() => this.conf_delete_enquete(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -20 }} icon="pi pi-times" /></div>
        </div>
    }


    delete_enq() {
        if (this.state.selectedEnquete == null || this.state.selectedEnquete == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.FbrService.delete_resultat_enquete(this.state.selectedEnquete).then(data => this.resultat(data.code, data.contenu));
        }
    }

    delete_ligne_enq() {
        if (this.state.selectedLenquete == null || this.state.selectedLenquete == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.FbrService.delete_ligne_resultat_enquete(this.state.selectedLenquete).then(data => this.resultat_ligne(data.code, data.contenu));
        }
    }

    get_liste_ligne_enquete_saisie(idPrest, nb) {
        Array.prototype.insert = function (index) {
            this.splice.apply(this, [index, 0].concat(this.slice.call(arguments, 1)));
        };
        if (this.state.liste_ligne.length > 0) {
            let rang = this.state.liste_ligne.length + 1;
            this.state.liste_ligne.insert(rang,
                {
                    idRlenq: rang,
                    numCasRlenq: 'T'+this.state.idPrest+rang,
                    valPrevExiste: 1,
                    valConfExiste: 0,
                    valPrevPrest: 1,
                    valConfPrest: 0,
                    valPrevSatisf: 20,
                    valConfSatisf: 0,
                }
            );
        } else {
            let liste = [];
            for (let i = 0; i < nb; i++) {
                liste.insert(liste + 1,
                    {
                        idRlenq: i + 1,
                        numCasRlenq: 'T'+this.state.idPrest+(i + 1),
                        valPrevExiste: 1,
                        valConfExiste: 0,
                        valPrevPrest: 1,
                        valConfPrest: 0,
                        valPrevSatisf: 20,
                        valConfSatisf: 0,
                    }
                );
            }
            this.setState({ liste_ligne: liste });
        }

        this.forceUpdate();
    }


    conf_delete_ligne_enquete(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedLenquete: data });
            this.setState({ msg: data.numCasRlenq });
            this.setState({ visible_del_ligne: true });
        }
    }

    conf_delete_enquete(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedEnquete: data });
            this.setState({ msg: '' });
            this.setState({ visible: true });
        }
    }

    actionTemplate_enq(rowData) {
        let items = [
            {
                label: 'Supprimer',
                icon: 'pi pi-times',
                command: (e) => {
                    this.conf_delete_enquete(rowData);
                }
            },
            {
                label: 'Modifier',
                icon: 'pi pi-pencil',
                command: (e) => {
                    this.edit_enquete(rowData);
                }
            }
        ]
        let label = rowData.idResEnq + '';
        return <SplitButton title='Modifier' label={label} className='p-button-info' onClick={(e) => this.edit_enquete(rowData)} icon="pi pi-pencil" style={{ width: 100 }} model={items}></SplitButton>
    }

    actionTemplate_Lenquete(rowData) {
        return <div className="p-grid" style={{ width: 90 }}>
            <div className="p-col"><Button title='Modifier' onClick={() => this.edit_ligne_enquete(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button title='Supprimer' onClick={() => this.conf_delete_ligne_enquete(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -20 }} icon="pi pi-times" /></div>
        </div>
    }

    itemTemplateRech(option) {
        if (!option.value) {
            return option.label;
        }
        else {
            return (
                <div className="p-grid" >
                    <div className="p-col-12 p-md-8">
                        <label>{option.label}</label>
                    </div>
                    <div className="p-col-12 p-md-4">
                        <label>{option.typePrest}</label>
                    </div>
                </div>
            );
        }
    }

    itemTemplate(option) {
        if (!option.value) {
            return option.label;
        }
        else {
            return (
                <div className="p-grid" style={{ padding: 10 }}>
                    <div className="p-col-12 p-md-8">
                        <label>{option.label}</label>
                    </div>
                    <div className="p-col-12 p-md-4">
                        <label>{option.typePrest}</label>
                    </div>
                </div>
            );
        }
    }

    render() {
        const header = (
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
            }}>
                Fiches d'enquête
                <Button icon="pi pi-plus" onClick={(e) => {
                    this.get_liste_ligne_enquete_saisie(this.state.idPrest, 1);
                }} />
            </div>
        );
        let headerGroup = <ColumnGroup>
            <Row>
                <Column header="#" rowSpan={2} />
                <Column header="No" rowSpan={2} />
                <Column header="No du cas" rowSpan={2} />
                <Column header="Existance patient" colSpan={2} />
                <Column header="Prestation" colSpan={2} />
                <Column header="Satisfaction" colSpan={2} />
            </Row>
            <Row>
                <Column header="Max" field="valPrevExiste" />
                <Column header="Existe" field="valConfExiste" />

                <Column header="Max" field="valPrevPrest" />
                <Column header="Confirmé" field="valConfPrest" />

                <Column header="Max" field="valPrevSatisf" />
                <Column header="Obtenu" field="valConfSatisf" />
            </Row>
        </ColumnGroup>;
        let headerGroup2 = <ColumnGroup>
            <Row>
                <Column header="Action" rowSpan={2} />
                <Column header="No" rowSpan={2} />
                <Column header="No cas" rowSpan={2} />
                <Column header="Existance" colSpan={2} />
                <Column header="Prestation" colSpan={2} />
                <Column header="Satisfaction" colSpan={2} />
            </Row>
            <Row>
                <Column header="Max" field="valPrevExiste" />
                <Column header="Conf" field="valConfExiste" />

                <Column header="Max" field="valPrevPrest" />
                <Column header="Conf" field="valConfPrest" />

                <Column header="Max" field="valPrevSatisf" />
                <Column header="Note" field="valConfSatisf" />
            </Row>
        </ColumnGroup>;
        let header_new = <Button title='Nouvelle fiche' label='Nouveau' className="p-button-help" onClick={(e) => {
            this.setState({ visible_form_new_enq: true });
        }} icon="pi pi-plus" style={{ width: 150 }} ></Button>
        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_enq();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        const dialogFooterLigne = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible_del_ligne: false });
                    this.delete_ligne_enq();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible_del_ligne: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        return (
            <div className="p-fluid">
                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={() => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                <Dialog header="Confirmation" visible={this.state.visible_del_ligne} style={{ width: '250px' }} modal={true} footer={dialogFooterLigne} onHide={() => this.setState({ visible_del_ligne: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                <Dialog header="Modification " visible={this.state.visible_dlg_ligne} style={{ width: '400px' }} modal={true} onHide={(e) => this.setState({ visible_dlg_ligne: false })}>
                    <div className="p-grid">
                        <div className="p-col-12 p-md-6">
                            <label >N° du cas *</label>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <InputText value={this.state.numCasRlenq} onChange={(e) => this.setState({ numCasRlenq: e.target.value })} />
                        </div>
                        <div className="p-col-12 p-md-6">
                            <label >Existance patient *</label>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <InputNumber max={1} min={0} value={this.state.valConfExiste} onChange={(e) => this.setState({ valConfExiste: e.value })} style={{ width: '150px' }} step={5} showButtons buttonLayout="horizontal" decrementButtonClassName="p-button-secondary" incrementButtonClassName="p-button-success" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" />
                        </div>
                        <div className="p-col-12 p-md-6">
                            <label >Confirmation prestation *</label>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <InputNumber max={1} min={0} value={this.state.valConfPrest} onChange={(e) => this.setState({ valConfPrest: e.value })} style={{ width: '150px' }} step={5} showButtons buttonLayout="horizontal" decrementButtonClassName="p-button-secondary" incrementButtonClassName="p-button-success" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" />
                        </div>
                        <div className="p-col-12 p-md-6">
                            <label >Satisfaction *</label>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <InputNumber max={20} min={0} value={this.state.valConfSatisf} onChange={(e) => this.setState({ valConfSatisf: e.value })} style={{ width: '150px' }} step={5} showButtons buttonLayout="horizontal" decrementButtonClassName="p-button-secondary" incrementButtonClassName="p-button-success" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" />
                        </div>
                    </div>
                    <center>
                        <div className="p-grid" style={{ width: 300 }}>
                            <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={() => {
                                this.setState({ visible_dlg_ligne: false });
                            }} /></div>
                            <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={() => this.enreg_ligne()} /></div>

                        </div>
                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                    </center>
                </Dialog>
 
                <Dialog header="Modification " visible={this.state.visible_dlg_enq} style={{ width: '400px' }} modal={true} onHide={(e) => this.setState({ visible_dlg_enq: false })}>
                    <div className="p-grid">
                        <div className="p-col-12 p-md-3">
                            <label >Acteur *</label>
                        </div>
                        <div className="p-col-12 p-md-9">
                            <Dropdown id="idPrest" value={this.state.idPrest} options={this.state.listeprest} optionLabel='label' filter={true} filterBy='label,typePrest' filterMatchMode='contains' onChange={(e) => this.setState({ idPrest: e.target.value }, () => {
                                this.setState({ liste_ligne: [] });
                                this.get_liste_ligne_enquete_saisie(this.state.idPrest);
                            })} autoWidth={false}  panelStyle={{ width: '500px' }} appendTo={document.body}/>
                        </div>
                        <div className="p-col-12 p-md-3">
                            <label htmlFor="firstname2">Date *</label>
                        </div>
                        <div className="p-col-12 p-md-9">
                            <Calendar dateFormat="dd/mm/yy" value={this.state.dateResEnq} onChange={(e) => this.setState({ dateResEnq: e.target.value })} appendTo={document.body} />
                        </div>
                    </div>

                    <center>
                        <div className="p-grid" style={{ width: 300, marginBottom: -20 }}>
                            <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={() => {
                                this.setState({ visible_dlg_enq: false });
                            }} /></div>
                            <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={() => this.enreg_enq()} /></div>

                        </div>
                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                    </center>
                </Dialog>

                {
                    this.state.visible_form_new_enq == true ?
                        <div className="p-card" style={{ padding: 5 }}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <label style={{ fontSize: 18, fontWeight: 'bold' }}>
                                    <i className="pi pi-pencil"></i>  NOUVEAUX ECHANTILLONAGE
                                </label>
                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                <label>
                                    <Button label="Liste échantillonage" className="p-button-help" icon="pi pi-list" onClick={() => {
                                        this.setState({ visible_form_new_enq: false, liste_ligne: [] });
                                    }} />
                                </label>
                            </div>
                            <hr />
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-12 p-md-6">
                                    <label htmlFor="firstname2">Acteur *</label>
                                    <Dropdown id="idPrest" value={this.state.idPrest} options={this.state.listeprest} optionLabel='label' filter={true} filterBy='label,typePrest' filterMatchMode='contains' onChange={(e) => this.setState({ idPrest: e.target.value }, () => {
                                        this.setState({ liste_ligne: [] });
                                        this.get_liste_ligne_enquete_saisie(this.state.idPrest, 12);
                                    })} autoWidth={false}
                                        panelStyle={{ width: '500px' }} appendTo={document.body} filterInputAutoFocus={false} />
                                </div>
                                <div className="p-field p-col-12 p-md-4">
                                    <label htmlFor="firstname2">Date *</label>
                                    <Calendar dateFormat="dd/mm/yy" value={this.state.dateResEnq} onChange={(e) => this.setState({ dateResEnq: e.target.value })} appendTo={document.body} />
                                </div>
                            </div>

                            <div className="card" style={{ height: 580, marginTop: -20 }}>
                                <DataTable value={this.state.liste_ligne}
                                    responsive={true} scrollable={true} scrollHeight="400px" selectionMode="single" className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                                    onRowClick={e => this.setState({ selectedLenquete: e.data })} onRowSelect={e => this.setState({ selectedLenquete: e.data })}
                                    selection={this.state.selectedLenquete}
                                    header={header} headerColumnGroup={headerGroup} >
                                    <Column header="#" body={this.actionTemplate_new_ligne.bind(this)} style={{ width: '5%' }} />
                                    <Column field="Index" header="N°" body={this.onIndexTemplate} style={{ width: '5%' }} />
                                    <Column field="numCasRlenq" body={(rowData, props, column) => {
                                        return <InputText value={rowData.numCasRlenq} onChange={(e) => {
                                            //console.log('ok');
                                        }} />
                                    }} header="N° du cas" editor={this.numCasRlenqEditor} />
                                    <Column field="valPrevExiste" body={(rowData, column) => {
                                        return <InputText disabled value={rowData.valPrevExiste} />
                                    }} header="Max" style={{ width: '8%' }} />
                                    <Column field="valConfExiste" body={(rowData, props) => {
                                        return <InputNumber style={{ width: '100px' }} onChange={(e) => {
                                            this.state.liste_ligne[props.rowIndex].valConfExiste = e.value;
                                        }} showButtons buttonLayout="horizontal" decrementButtonClassName="p-button-secondary" incrementButtonClassName="p-button-success" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" max={1} min={0} value={rowData.valConfExiste} />
                                    }} header="Patient Trouvé" style={{ width: '13%' }} editor={this.valConfExisteEditor} />
                                    <Column field="valPrevPrest" body={(rowData, column) => {
                                        return <InputText disabled value={rowData.valPrevPrest} />
                                    }} header="Max" style={{ width: '8%' }} />
                                    <Column field="valConfPrest" body={(rowData, props) => {
                                        return <InputNumber onChange={(e) => {
                                            this.state.liste_ligne[props.rowIndex].valConfPrest = e.value;
                                        }} style={{ width: '100px' }} showButtons buttonLayout="horizontal" decrementButtonClassName="p-button-secondary" incrementButtonClassName="p-button-success" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" max={1} min={0} value={rowData.valConfPrest} />
                                    }} header="Prestation Confirmé" style={{ width: '13%' }} editor={this.valConfExisteEditor} />
                                    <Column field="valPrevSatisf" body={(rowData, column) => {
                                        return <InputText disabled value={rowData.valPrevSatisf} />
                                    }} header="Max" style={{ width: '8%' }} />
                                    <Column field="valConfSatisf" body={(rowData, props) => {
                                        return <InputNumber onChange={(e) => {
                                            this.state.liste_ligne[props.rowIndex].valConfSatisf = e.value;
                                        }} style={{ width: '100px' }} step={5} showButtons buttonLayout="horizontal" decrementButtonClassName="p-button-secondary" incrementButtonClassName="p-button-success" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" max={20} min={0} value={rowData.valConfSatisf} />
                                    }} header="Satisfaction" style={{ width: '13%' }} editor={this.valConfSatisfEditor} />
                                </DataTable>
                            </div>

                            <br />
                            <center style={{}}>
                                <div className="p-grid" style={{ width: 300 }}>
                                    <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={() => this.annule_enquete()} /></div>
                                    <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={() => this.enreg_enq()} /></div>
                                    <br />
                                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                </div>
                            </center>
                        </div>
                        :
                        <>
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <div className="card-w-title">
                                        <i className="pi pi-search"></i> Option de recherche
                                        <hr />
                                        <div className="p-grid">
                                            <div className="p-col-12 p-md-3">
                                                <Calendar value={this.state.pd1} onChange={(e) => this.setState({ pd1: e.value }, () => {
                                                    this.get_liste_enquete();
                                                })} dateFormat="dd/mm/yy" yearNavigator yearRange="2021:2070" />
                                            </div>
                                            <div className="p-col-12 p-md-3">
                                                <Calendar value={this.state.pd2} onChange={(e) => this.setState({ pd2: e.value }, () => {
                                                    this.get_liste_enquete();
                                                })} dateFormat="dd/mm/yy" yearNavigator yearRange="2021:2070" />
                                            </div>
                                            <div className="p-col-12 p-md-3">
                                                <Dropdown autoWidth={false} options={[{ label: 'Acteur', value: 'Tous' }].concat(this.state.listeprest)} optionLabel='label' placeholder='Acteur' filter={true} filterBy='label,typePrest' filterMatchMode='contains' value={this.state.ridPrest} onChange={e => this.setState({ ridPrest: e.value }, () => {
                                                    this.get_liste_enquete();
                                                })}
                                                    panelStyle={{ width: '500px' }} appendTo={document.body} filterInputAutoFocus={false} />
                                            </div>

                                            <div className="p-col-12 p-md-2">
                                                <Button icon='pi pi-search' style={{ width: 150 }} label='Rechercher' onClick={() => this.get_liste_enquete()} />
                                            </div>
                                            <div className="p-col-12 p-md-1">
                                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="p-grid">
                                <div className="p-col-12 p-lg-6">
                                    <div className="p-grid">
                                        <div className="p-col-12">
                                            <div className="card" style={{ height: 590 }}>
                                                <DataTable value={this.state.liste_enquete} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                                                    responsive={true} paginator={true} rows={7} alwaysShowPaginator={true} selectionMode="single" paginatorPosition="top"
                                                    onRowClick={e => {
                                                        this.setState({ selectedEnquete: e.data }, () => {
                                                            this.get_liste_ligne_enquete(this.state.selectedEnquete.idResEnq);
                                                        });
                                                    }} paginatorLeft={header_new} onRowSelect={e => this.setState({ selectedEnquete: e.data })}
                                                     selection={this.state.selectedEnquete} >
                                                    <Column field="idResEnq" header="Action" body={this.actionTemplate_enq.bind(this)} style={{ width: '24%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                    <Column field="dateResEnq" header="Date" body={(rowData, column) => {
                                                        return <div>
                                                            <span style={{}}>{rowData.dateResEnq}</span>
                                                        </div>
                                                    }} filter={true} filterMatchMode='contains' sortable={true} style={{ width: '20%' }} />
                                                    <Column field="score_percept" header="Score" body={(rowData, column) => {
                                                        return <div>
                                                            <span style={{ fontWeight: 'bold' }}>{rowData.score_percept} %</span>
                                                        </div>
                                                    }} filter={true} filterMatchMode='contains' sortable={true} style={{ width: '17%' }} />
                                                    <Column field="nomPrest" header="Acteur" body={(rowData, column) => {
                                                        return <div>
                                                            <span style={{ color: '#000000', fontWeight: 'bold' }}>{rowData.nomPrest}</span><br />
                                                            <span style={{ color: '#000000' }}>{rowData.typePrest}</span>
                                                        </div>
                                                    }} filter={true} filterMatchMode='contains' sortable={true} />
                                                </DataTable>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12 p-lg-6">
                                    <div className="p-grid">
                                        <div className="p-col-12">
                                            <div className="card" style={{ height: 590 }}>
                                                <DataTable value={this.state.liste_ligne_enquete}
                                                    responsive={true} scrollable={true} scrollHeight="450px" selectionMode="single" className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                                                    onRowClick={e => this.setState({ selectedLenquete: e.data })} onRowSelect={e => this.setState({ selectedLenquete: e.data })}
                                                     selection={this.state.selectedLenquete} headerColumnGroup={headerGroup2}
                                                    footer={<Button icon="pi pi-plus" onClick={() => {
                                                        this.setState({ visible_dlg_ligne: true, modif_ligne: false });
                                                    }} label="Ajouter" style={{ width: 150 }} disabled={this.state.selectedEnquete == null || this.state.selectedEnquete == undefined} />}
                                                >
                                                    <Column header="Action" body={this.actionTemplate_Lenquete.bind(this)} style={{ width: '16%' }} />
                                                    <Column field="Index" header="N°" body={this.onIndexTemplate} style={{ width: '7%' }} />
                                                    <Column field="numCasRlenq" header="N° cas" />
                                                    <Column field="valPrevExiste" header="Max" style={{ width: '10%' }} />
                                                    <Column field="valConfExiste" header="Conf" style={{ width: '11%' }} />
                                                    <Column field="valPrevPrest" header="Max" style={{ width: '10%' }} />
                                                    <Column field="valConfPrest" header="Conf" style={{ width: '11%' }} />
                                                    <Column field="valPrevSatisf" header="Max" style={{ width: '10%' }} />
                                                    <Column field="valConfSatisf" header="Note" style={{ width: '11%' }} />
                                                </DataTable>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </>
                }

            </div>
        );
    }
}