import React, { Component } from 'react';
import FbrService from '../service/FbrService';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toolbar } from 'primereact/toolbar';

export class FormSite extends Component {
    constructor() {
        super();
        this.state = {
            listesite: [],
            selectedSite: null,
            idSite: 0,
            nomSite: '',
            codeSite: '',
            crdSite: '',
            prefectureSite: '',
            regionSite: '',
            nomStructSite: '',
            codeStructSite: '',
            longitudeSite: '',
            latitudeSite: '',
            photoSite: 'photo.png',
            emailSite: '',
            adrSite: '',
            telSite: '',
            typeSite: null,
            liste_type: [
                { label: 'TYPE 1', value: 'TYPE 1' },
                { label: 'TYPE 2', value: 'TYPE 2' },
                { label: 'TYPE 3', value: 'TYPE 3' }
            ],
            liste_region: [
                { label: 'Boke', value: 'Boké' },
                { label: 'Conakry', value: 'Conakry' },
                { label: 'Faranah', value: 'Faranah' },
                { label: 'Kankan', value: 'Kankan' },
                { label: 'Kindia', value: 'Kindia' },
                { label: 'Labe', value: 'Labé' },
                { label: 'Mamou', value: 'Mamou' },
                { label: 'Nzérkoré', value: 'Nzérkoré' }
            ],
            liste_all_pref: [],
            liste_all_crd: [],
            liste_prefecture: [],
            liste_crd: [],
            region: { label: 'Boke', value: 'Boké' },
            pref: null,
            crd: null,
            file: null,
            msg: '',
            modif: false,
            visible: false,
            visible_dlg_site: false,
            showindicator: false,
            liste_ou_vih: [],
            liste_ou_global: [],
            liste_level_2_region: [], //Région
            liste_level_3_pref: [], //Préfecture
            liste_level_4_crd: [], //Sous prefecture

        };
        this.edit_site = this.edit_site.bind(this);
        this.conf_delete_site = this.conf_delete_site.bind(this);
        this.FbrService = new FbrService();
        this.toogleDlg = this.toogleDlg.bind(this);
        this.refresh_site = this.refresh_site.bind(this);
    }

    fileUpload(file, namefile) {
        this.FbrService.upload_photo_site(file, namefile, 'photos').then(data => {
            if (data.code == '200') {
                this.toast.show({ severity: 'success', summary: 'Success', detail: 'Chargement effectué avec succès' });
            } else {
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Echec chargement' });
            }
        });
    }

    componentDidMount() {
        this.get_liste_site();
        this.FbrService.get_liste_prefecture().then(data => {
			if (data != null && data != undefined) {
                this.setState({ liste_all_pref: data});
			} 
		});

		this.FbrService.get_liste_crd().then(data => {
			if (data != null && data != undefined) {
                this.setState({ liste_all_crd: data });
			} 
		});
    }

    get_liste_site() {
        this.setState({ showindicator: true });
        this.FbrService.get_liste_site().then(data => this.setState({ listesite: data ,showindicator: false}));
    }

    select_pref(region,valPref) {
        let liste = [];
        if (this.state.liste_all_pref != null && this.state.liste_all_pref != undefined) {
            if (this.state.liste_all_pref.length > 0) {
                let liste_filtre = this.state.liste_all_pref.filter(function (el) {
                    return el.region == region;
                });
                if (liste_filtre != null && liste_filtre != undefined) {
                    if (liste_filtre.length > 0) {
                        liste = liste_filtre.map(x => {
                            return { value: x.nom_pref, label: x.nom_pref }
                        });
                    }
                }
            }
        }
        if(valPref!==undefined){
            this.setState({ liste_prefecture: liste },()=>{
                this.setState({prefectureSite: valPref});
            });
        }else{
            this.setState({ liste_prefecture: liste });
        }
        
    }

    select_crd(pref,valCrd) {
        let liste = [];
        if (this.state.liste_all_crd != null && this.state.liste_all_crd != undefined) {
            if (this.state.liste_all_crd.length > 0) {
                let liste_filtre = this.state.liste_all_crd.filter(function (el) {
                    return el.nom_pref == pref;
                });
                if (liste_filtre != null && liste_filtre != undefined) {
                    if (liste_filtre.length > 0) {
                        liste = liste_filtre.map(x => {
                            return { value: x.nom_crd, label: x.nom_crd }
                        });
                    }
                }
            }
        }
        if(valCrd!==undefined){
            this.setState({ liste_crd: liste },()=>{
                this.setState({crdSite: valCrd});
            });
        }else{
            this.setState({ liste_crd: liste });
        }
        
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_site();
            this.componentDidMount();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    edit_site(data) {
        if (data != null && data != undefined) {
            this.setState({ idSite: data.idSite });
            this.setState({ nomSite: data.nomSite });
            this.setState({ codeSite: data.codeSite });
            this.setState({ crdSite: data.crdSite });
            
            this.setState({ regionSite: data.regionSite});
            this.select_pref(data.regionSite,data.prefectureSite);
            this.select_crd(data.prefectureSite,data.crdSite);

            this.setState({ nomStructSite: data.nomStructSite });
            this.setState({ codeStructSite: data.codeStructSite });
            this.setState({ longitudeSite: data.longitudeSite });
            this.setState({ latitudeSite: data.latitudeSite });
            this.setState({ photoSite: data.photoSite });
            this.setState({ telSite: data.telSite });
            this.setState({ emailSite: data.emailSite });
            this.setState({ adrSite: data.adrSite });
            this.setState({ typeSite: { value: data.typeSite, label: data.typeSite } });
            this.setState({ modif: true });
            this.setState({ selectedSite: data });
            this.setState({ visible_dlg_site: true });
        }
    }

    annule_site() {
        this.setState({ nomSite: '' });
        this.setState({ codeSite: '' });
        this.setState({ crdSite: '' });
        this.setState({ prefectureSite: '' });
        this.setState({ regionSite: '' });
        this.setState({ nomStructSite: '' });
        this.setState({ codeStructSite: '' });
        this.setState({ longitudeSite: '' });
        this.setState({ latitudeSite: '' });
        this.setState({ photoSite: '' });
        this.setState({ telSite: '' });
        this.setState({ emailSite: '' });
        this.setState({ adrSite: '' });
        this.setState({ typeSite: '' });
        this.setState({ idSite: 0 });
        this.setState({ modif: false });
        this.setState({ visible_dlg_site: false });
        this.forceUpdate();
    }

    enreg_site() {
        if (this.state.nomSite == "" || this.state.crdSite == "" || this.state.regionSite == ""|| this.state.prefectureSite == "") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            let upload = false;
            let nomfic = this.state.photoSite;
            if (this.state.file != null && this.state.file != undefined && this.state.file != '') {
                let name = this.state.file.name;
                upload = true;
                nomfic = this.state.nomSite + '.' + name.slice((name.lastIndexOf(".") - 1 >>> 0) + 2);
            }
            this.setState({ showindicator: true });
            if (this.state.modif == false) {
                let site = {
                    idSite: 0,
                    nomSite: this.state.nomSite,
                    codeSite: this.state.codeSite,
                    crdSite: this.state.crdSite,
                    prefectureSite: this.state.prefectureSite,
                    regionSite: this.state.regionSite,
                    nomStructSite: this.state.nomStructSite,
                    codeStructSite: this.state.codeStructSite,
                    longitudeSite: this.state.longitudeSite,
                    latitudeSite: this.state.latitudeSite,
                    photoSite: this.state.photoSite,
                    telSite: this.state.telSite,
                    emailSite: this.state.emailSite,
                    adrSite: this.state.adrSite
                }
                this.FbrService.enreg_site(site)
                    .then(data => {
                        if (data != undefined && data != null) {
                            if (upload === true) {
                                this.fileUpload(this.state.file, nomfic);
                            }
                            this.resultat(data.code, data.contenu);
                        }
                    });
            } else {
                let site = {
                    idSite: this.state.idSite,
                    nomSite: this.state.nomSite,
                    codeSite: this.state.codeSite,
                    crdSite: this.state.crdSite,
                    prefectureSite: this.state.prefectureSite,
                    regionSite: this.state.regionSite,
                    nomStructSite: this.state.nomStructSite,
                    codeStructSite: this.state.codeStructSite,
                    longitudeSite: this.state.longitudeSite,
                    latitudeSite: this.state.latitudeSite,
                    photoSite: this.state.photoSite,
                    telSite: this.state.telSite,
                    emailSite: this.state.emailSite,
                    adrSite: this.state.adrSite
                }
                this.FbrService.enreg_site(site)
                    .then(data => {
                        if (data != undefined && data != null) {
                            if (upload === true) {
                                this.fileUpload(this.state.file, nomfic);
                            }
                            this.resultat(data.code, data.contenu);
                        }
                    });
            }
        }
    }

    actionTemplate(rowData) {
        return <div className="p-grid" style={{ width: 90 }}>
            <div className="p-col"><Button title='Modifier' onClick={() => this.edit_site(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button title='Supprimer' onClick={() => this.conf_delete_site(rowData)} className="p-button-danger" style={{ width: 25, height: 25,marginLeft: -20 }} icon="pi pi-times" /></div>
        </div>
    }


    delete_site() {
        if (this.state.selectedSite == null || this.state.selectedSite == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.FbrService.delete_site(this.state.selectedSite).then(data => this.resultat(data.code, data.contenu));
        }
    }


    refresh_site() {
        this.setState({ showindicator: true });
        let userconnected = this.FbrService.get_userconnected();
        this.FbrService.get_FBR_organisationUnit().then(data => {
            if (data !== undefined && data !== null && data.length > 0) {
                this.setState({ liste_ou_vih: data.organisationUnits }, () => {
                    this.FbrService.get_Global_organisationUnit().then(data2 => {
                        this.setState({ liste_ou_global: data2.organisationUnits }, () => {
                            let lregion = this.state.liste_ou_global.filter(function (el2) {
                                return el2.level == 2;
                            })
                            this.setState({ liste_level_2_region: lregion }, () => {
                                let lpref = this.state.liste_ou_global.filter(function (el) {
                                    return el.level == 3;
                                })
                                this.setState({ liste_level_3_pref: lpref }, () => {
                                    let lcrd = this.state.liste_ou_global.filter(function (el) {
                                        return el.level == 4;
                                    })
                                    this.setState({ liste_level_4_crd: lcrd }, () => {
                                        for (var i = this.state.liste_ou_vih.length; i--;) {
                                            this.state.liste_ou_vih[i].idSite = this.state.liste_ou_vih[i].id;
                                            let id = this.state.liste_ou_vih[i].idSite + '';
                                            let ou = this.state.liste_ou_global.filter(function (el3) {
                                                return el3.id + '' === id;
                                            })[0];
                                            if (ou != undefined && ou != null) {
                                                if (ou.level == 5) {
                                                    let region = this.state.liste_level_2_region.filter(function (el) {
                                                        return el.id == ou.ancestors[1].id;
                                                    })[0];
                                                    this.state.liste_ou_vih[i].regionSite = region.name;

                                                    let pref = this.state.liste_level_3_pref.filter(function (el) {
                                                        return el.id == ou.ancestors[2].id;
                                                    })[0];
                                                    this.state.liste_ou_vih[i].prefectureSite = pref.name;

                                                    let crd = this.state.liste_level_4_crd.filter(function (el) {
                                                        return el.id == ou.ancestors[3].id;
                                                    })[0];

                                                    if (ou.coordinates != null && ou.coordinates != undefined) {
                                                        let coordinates = ou.coordinates.replace("[", "");
                                                        coordinates = coordinates.replace("]", "");
                                                        let lcord = coordinates.split(',');
                                                        if (lcord != null && lcord != undefined) {
                                                            if (lcord.length > 0) {
                                                                this.state.liste_ou_vih[i].longitudeSite = lcord[0];
                                                                this.state.liste_ou_vih[i].latitudeSite = lcord[1];
                                                            }
                                                        }
                                                    }
                                                    this.state.liste_ou_vih[i].crdSite = crd.name;
                                                    this.state.liste_ou_vih[i].nomSite = ou.name;
                                                    this.state.liste_ou_vih[i].codeSite = ou.code;
                                                    this.state.liste_ou_vih[i].iduser = userconnected.idUser;
                                                } else {
                                                    this.state.liste_ou_vih.splice(i, 1);
                                                }
                                            }
                                        }
                                        if (this.state.liste_ou_vih != undefined && this.state.liste_ou_vih != null) {
                                            if (this.state.liste_ou_vih.length > 0) {
                                                this.FbrService.enreg_site_batch(this.state.liste_ou_vih).then(data => {
                                                    if (data != null && data != undefined) {
                                                        this.resultat(data.code, data.contenu);
                                                    }
                                                });
                                            }
                                        }
                                    });
                                });
                            });

                            this.setState({ showindicator: false });
                        });

                    });
                })
            }
        });
    }

    conf_delete_site(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedSite: data });
            this.setState({ msg: data.nomSite });
            this.setState({ visible: true });
        }
    }



    get_Global_organisationUnit() {
        this.FbrService.get_Global_organisationUnit().then(data => this.setState({ liste_ou_global: data }));
    }


    exportCSV = () => {
        this.dt.exportCSV();
    }

    toogleDlg() {
        this.setState({ visible_dlg_site: !this.state.visible_dlg_site });
    }

    leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Nouveau" icon="pi pi-plus" className="p-button-success p-mr-2" style={{ width: 120 }} onClick={this.toogleDlg} />
                <Button label="Import DHIS2" icon="pi pi-refresh" style={{ width: 150 }} className="p-button-info" onClick={this.refresh_site} />
            </React.Fragment>
        )
    }

    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={this.exportCSV} />}
            </React.Fragment>
        )
    }

    render() {
        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_site();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        return (
            <div className="p-fluid">
                <Toast ref={(el) => this.toast = el} />
                <Toolbar className="p-mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>

                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={() => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>

                <Dialog visible={this.state.visible_dlg_site} style={{ width: '90%' }} header={this.state.modif == true ? "Modification" : "Nouveau site"} modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_site: false })}>
                    <div className="card">
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="firstname2">Nom site *</label>
                                <InputText id="nomSite" value={this.state.nomSite} onChange={(e) => this.setState({ nomSite: e.target.value })} />
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="lastname2">Code site *</label>
                                <InputText id="codeSite" value={this.state.codeSite} onChange={(e) => this.setState({ codeSite: e.target.value })} />
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="firstname2">Longitude *</label>
                                <InputText id="longitudeSite" value={this.state.longitudeSite} onChange={(e) => this.setState({ longitudeSite: e.target.value })} />
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="lastname2">latitude </label>
                                <InputText id="codeSite" value={this.state.latitudeSite} onChange={(e) => this.setState({ latitudeSite: e.target.value })} />
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="firstname2">Region *</label>
                                <Dropdown placeholder="Region" autoWidth={false} value={this.state.regionSite} filter={true} filterBy='label' filterMatchMode='contains' options={this.state.liste_region} onChange={(e) => {
                                    this.setState({ regionSite: e.value }, () => {
                                        this.select_pref(e.value);
                                        this.setState({ liste_crd: [] });
                                    });
                                }} appendTo={document.body}/>
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="lastname2">Préfecture *</label>
                                <Dropdown placeholder="Préfecture"  editable={true} autoWidth={false} value={this.state.prefectureSite} filter={true} filterBy='label' filterMatchMode='contains' options={this.state.liste_prefecture} onChange={(e) => {
                                    this.setState({prefectureSite: e.value }, () => {
                                        this.select_crd(e.value);
                                    });
                                }}  appendTo={document.body}/>
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="firstname2">CR *</label>
                                <Dropdown placeholder="CR"  editable={true} autoWidth={false} value={this.state.crdSite} filter={true} filterBy='label' filterMatchMode='contains' options={this.state.liste_crd} onChange={(e) => {
                                    this.setState({ crdSite: e.value });
                                }}  appendTo={document.body}/>
                            </div>

                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="lastname2">Téléphone </label>
                                <InputText id="codeSite" value={this.state.telSite} onChange={(e) => this.setState({ telSite: e.target.value })} />
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="firstname2">Email </label>
                                <InputText id="longitudeSite" value={this.state.emailSite} onChange={(e) => this.setState({ emailSite: e.target.value })} />
                            </div>

                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="lastname2">Adresse</label>
                                <InputText id="codeSite" value={this.state.adrSite} onChange={(e) => this.setState({ adrSite: e.target.value })} />
                            </div>

                        </div>
                    </div>
                    <center>
                        <>
                            <Button label="Annuler" icon="pi pi-times" className="p-button-text" style={{ width: 120 }} onClick={(e) => this.setState({ visible_dlg_site: false })} />
                            <Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_site()} style={{ width: 120 }} />
                        </>
                        <br />
                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                    </center>
                </Dialog>

                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title">
                            <DataTable value={this.state.listesite} paginatorPosition="top" selectionMode="single" paginator={true} rows={20}
                                alwaysShowPaginator={false} onRowClick={e => this.setState({ selectedSite: e.data })} onRowSelect={e => this.setState({ selectedSite: e.data })}
                                responsive={true} selection={this.state.selectedSite} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                                ref={(el) => { this.dt = el; }}>
                                <Column header="Action" body={this.actionTemplate.bind(this)} style={{ width: '7%' }} />
                                <Column field="nomSite" filter={true} filterMatchMode='contains' header="Nom" sortable={true}  />
                                <Column field="regionSite" filter={true} filterMatchMode='contains' header="Région" sortable={true} />
                                <Column field="prefectureSite" filter={true} filterMatchMode='contains' header="Préfecture" sortable={true}  />
                                <Column field="crdSite" filter={true} filterMatchMode='contains' header="CRD" sortable={true}  />
                                <Column field="adrSite" filter={true} filterMatchMode='contains' header="Adresse" sortable={true} />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}