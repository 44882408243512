import React, { Component } from 'react';
import FbrService  from '../service/FbrService';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
export class FormFormuleEvaLigneFormule extends Component {
    constructor() {
        super();
        this.state = {
            listefeva: [],
            selectedFeva: null,
            idFeval: 0,
            libFeval: '',
            msg: '',
            modif: false,
            visible_feva: false,
            listeligne: [],
            selectedLigne: null,
            idLfeval: 0,
            infLfeval: 0,
            supLfeval: 0,
            valLfeval: 0,
            visible_ligne: false,
            showindicator: false,
        };

        this.edit_feva = this.edit_feva.bind(this);
        this.conf_delete_feva = this.conf_delete_feva.bind(this);

        this.edit_ligne = this.edit_ligne.bind(this);
        this.conf_delete_ligne = this.conf_delete_ligne.bind(this);

        this.FbrService = new FbrService();
    }

    componentDidMount() {
        this.get_liste_feva();
    }

    get_liste_feva() {
        this.FbrService.get_liste_formule().then(data => this.setState({ listefeva: data }));
    }


    get_liste_Ligne(idFeval) {
        this.FbrService.get_liste_ligne_formule(idFeval).then(data => { this.setState({ listeligne: data }); })
    }


    resultat(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_feva();
            this.get_liste_feva();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }


    resultat_ligne(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_ligne();
           this.get_liste_Ligne(this.state.selectedFeva.idFeval);
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    edit_feva(data) {
        if (data != null && data != undefined) {
            this.setState({ idFeval: data.idFeval });
            this.setState({ libFeval: data.libFeval });
            this.setState({ selectedFeva: data });
            this.setState({ modif: true });
        }
    }

    edit_ligne(data) {
        if (data != null && data != undefined) {
            this.setState({ idLfeval: data.idLfeval });
            this.setState({ infLfeval: data.infLfeval });
            this.setState({ supLfeval: data.supLfeval });
            this.setState({ valLfeval: data.valLfeval });
            this.setState({ idFeval: this.state.selectedFeva.idFeval });
            this.setState({ selectedLigne: data });
            this.setState({ modif: true });
        }
    }

    annule_feva() {
        this.setState({ idFeval: 0 });
        this.setState({ libFeval: '' });
        this.setState({ modif: false });
        this.forceUpdate();
    }

    annule_ligne() {
        this.setState({ idLfeval: 0 });
        this.setState({ infLfeval: 0 });
        this.setState({ supLfeval: 0 });
        this.setState({ valLfeval: 0 });
        this.setState({ modif: false });
        this.forceUpdate();
    }

    enreg_feva() {
        if (this.state.libFeval == "") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            this.setState({ showindicator: true });
            if (this.state.modif == false) {
                let formuleEva = {
                    idFeval: 0,
                    libFeval: this.state.libFeval
                }
                this.FbrService.enreg_formule(formuleEva).then(data => this.resultat(data.code, data.contenu));
            } else {
                let formuleEva = {
                    idFeval: this.state.idFeval,
                    libFeval: this.state.libFeval
                }
                this.FbrService.enreg_formule(formuleEva, formuleEva.idFeval).then(data => this.resultat(data.code, data.contenu));
            }
        }
    }


    enreg_ligne() {
        if (this.state.selectedFeva != null && this.state.selectedFeva != undefined) {
            if (this.state.infLfeval == "" || this.state.supLfeval == "" || this.state.valLfeval == "" || this.state.selectedFeva.idFeval == "") {
                this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
            } else {
                this.setState({ showindicator: true });
                if (this.state.modif == false) {
                    let ligne = {
                        idLfeval: 0,
                        infLfeval: this.state.infLfeval,
                        supLfeval: this.state.supLfeval,
                        valLfeval: this.state.valLfeval,
                        idFeval: this.state.selectedFeva.idFeval
                    }
                    this.FbrService.enreg_ligne_formule(ligne).then(data => this.resultat_ligne(data.code, data.contenu));
                } else {
                    let ligne = {
                        idLfeval: this.state.idLfeval,
                        infLfeval: this.state.infLfeval,
                        supLfeval: this.state.supLfeval,
                        valLfeval: this.state.valLfeval,
                        idFeval: this.state.selectedFeva.idFeval
                    }
                    this.FbrService.enreg_ligne_formule(ligne).then(data => this.resultat_ligne(data.code, data.contenu));
                }
            }
        } else {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez selectionner une formule évaluation' });
        }
    }


    actionTemplate_feva(rowData) {
        return <div className="p-grid" style={{ width: 90 }}>
            <div className="p-col"><Button onClick={() => this.edit_feva(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button onClick={() => this.conf_delete_feva(rowData)} className="p-button-danger" style={{ width: 25, height: 25,marginLeft: -20 }} icon="pi pi-times" /></div>
        </div>
    }

    actionTemplate_ligne(rowData) {
        return <div className="p-grid" style={{ width: 90 }}>
            <div className="p-col"><Button onClick={() => this.edit_ligne(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button onClick={() => this.conf_delete_ligne(rowData)} className="p-button-danger" style={{ width: 25, height: 25,marginLeft: -20 }} icon="pi pi-times" /></div>
        </div>
    }



    delete_feva() {
        if (this.state.selectedFeva == null || this.state.selectedFeva == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.FbrService.delete_formule(this.state.selectedFeva).then(data => this.resultat(data.code, data.contenu));
        }
    }

    delete_ligne() {
        if (this.state.selectedLigne == null || this.state.selectedLigne == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.FbrService.delete_ligne_formule(this.state.selectedLigne).then(data => this.resultat_ligne(data.code, data.contenu));
        }
    }


    conf_delete_feva(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedFeva: data });
            this.setState({ msg: data.libFeval });
            this.setState({ visible_feva: true });
        }
    }

    conf_delete_ligne(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedLigne: data });
            this.setState({ msg: data.idLfeval });
            this.setState({ visible_ligne: true });
        }
    }



    render() {

        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible_feva: false });
                    this.delete_feva();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible_feva: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        const dialogFooter2 = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible_ligne: false });
                    this.delete_ligne();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible_ligne: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        return (
            <div className="p-fluid">
                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Confirmation" visible={this.state.visible_feva} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={() => this.setState({ visible_feva: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                <Dialog header="Confirmation" visible={this.state.visible_ligne} style={{ width: '250px' }} modal={true} footer={dialogFooter2} onHide={() => this.setState({ visible_ligne: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>

                <div className="p-grid" style={{ marginBottom: -20 }}>
                    <div className="p-col-12 p-lg-4">
                        <div className="p-grid">
                            <div className="p-col-12">
                                <div className="card card-w-title" style={{ height: 400 }}>

                                    <i className="pi pi-pencil"></i> Enregistrement formule évaluation
                                    <hr />
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-4">
                                            <label htmlFor="libFeval">Libellé</label>
                                        </div>
                                        <div className="p-col-12 p-md-8">
                                            <InputText id="libFeval" value={this.state.libFeval} onChange={(e) => this.setState({ libFeval: e.target.value })} />
                                        </div>
                                    </div>
                                    <center>
                                        <div className="p-grid" style={{ width: 300 }}>
                                            <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={() => this.annule_feva()} /></div>
                                            <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={() => this.enreg_feva()} /></div>
                                        </div>
                                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                    </center>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="p-col-12 p-lg-8">
                        <div className="p-grid">
                            <div className="p-col-12">
                                <div className="card card-w-title" style={{ height: 400 }}>

                                    <i className="pi pi-list"></i> Liste formule évaluation
                                            <hr />
                                    <DataTable value={this.state.listefeva} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                                        responsive={true} scrollable={true} scrollHeight="300px" selectionMode="single"
                                        onRowClick={e => {
                                            this.setState({ selectedFeva: e.data }, () => {
                                                this.get_liste_Ligne(this.state.selectedFeva.idFeval);
                                            });
                                        }}
                                        onRowSelect={e => this.setState({ selectedFeva: e.data }, () => {
                                            this.get_liste_Ligne(this.state.selectedFeva.idFeval);
                                        })}
                                        responsive={true} selection={this.state.selectedFeva}
                                    >
                                        <Column header="Action" body={this.actionTemplate_feva.bind(this)} style={{ width: '12%' }} />
                                        <Column field="libFeval" header="Libellé" sortable={true}  />
                                    </DataTable>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>




                <div className="p-grid">
                    <div className="p-col-12 p-lg-4">
                        <div className="p-grid">
                            <div className="p-col-12">
                                <div className="card card-w-title" style={{ height: 400 }}>

                                    <i className="pi pi-pencil"></i> Enregistrement ligne formule
                                    <hr />

                                    <div className="p-grid" >
                                        <div className="p-col-12 p-md-4">
                                            <label htmlFor="infLfeval">Inférieur</label>
                                        </div>
                                        <div className="p-col-12 p-md-8">
                                            <InputText id="infLfeval" value={this.state.infLfeval} onChange={(e) => this.setState({ infLfeval: e.target.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-4">
                                            <label htmlFor="supLfeval">Supérieur</label>
                                        </div>
                                        <div className="p-col-12 p-md-8">
                                            <InputText id="supLfeval" value={this.state.supLfeval} onChange={(e) => this.setState({ supLfeval: e.target.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-4">
                                            <label htmlFor="valLfeval">Valeur</label>
                                        </div>
                                        <div className="p-col-12 p-md-8">
                                            <InputText id="valLfeval" value={this.state.valLfeval} onChange={(e) => this.setState({ valLfeval: e.target.value })} />
                                        </div>
                                    </div>

                                    <center>
                                        <div className="p-grid" style={{ width: 300 }}>
                                            <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={() => this.annule_ligne()} /></div>
                                            <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={() => this.enreg_ligne()} /></div>
                                        </div>
                                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                    </center>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="p-col-12 p-lg-8">
                        <div className="p-grid">
                            <div className="p-col-12">
                                <div className="card card-w-title" style={{ height: 400 }}>
                                    <i className="pi pi-list"></i> Liste ligne formule
                                    <hr />
                                    <DataTable value={this.state.listeligne} paginatorPosition="top" paginator={true} rows={10} alwaysShowPaginator={false}
                                       responsive={true} scrollable={true} scrollHeight="300px" selectionMode="single" className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                                        onRowClick={e => this.setState({ selectedLigne: e.data })} onRowSelect={e => this.setState({ selectedLigne: e.data })}
                                        responsive={true} selection={this.state.selectedLigne}
                                    >
                                        <Column header="Action" body={this.actionTemplate_ligne.bind(this)} style={{ width: '12%' }} />
                                        <Column field="infLfeval" header="Inférieur" filter={true} filterMatchMode='contains' sortable={true} />
                                        <Column field="supLfeval" header="Supérieur" filter={true} filterMatchMode='contains' sortable={true} />
                                        <Column field="valLfeval" header="Valeur" filter={true} filterMatchMode='contains' sortable={true}  />
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

 

            </div>


        );
    }
}