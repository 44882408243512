import React, { Component } from 'react';
import FbrService from '../service/FbrService';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import { SplitButton } from 'primereact/splitbutton';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { InputMask } from 'primereact/inputmask';
import { RadioButton } from "primereact/radiobutton";

export class FormEnquete extends Component {
    constructor() {
        super();
        this.state = {
            pd1: new Date(moment('01/01/' + moment(new Date()).format('YYYY'), 'YYYY-MM-DD').format('YYYY-MM-DD')),
            pd2: new Date(),
            liste_enquete: [],
            listeprest: [],
            selectedEnquete: null,
            idEnq: null,
            dateEnq: new Date(),
            heureDebEnq: '',
            heureFinEnq: '',
            suggestionEnq: '',
            telEnq: '',
            nomEnq: '',
            idPrest: null,

            idLenq: null,
            noteEnq: 0,
            maxEnq: 0,
            libLenq: '',
            idType: null,
            liste_ligne_enquete: [],
            liste_ligne: [],
            selectedLenquete: null,
            appLenq: '',
            ridPrest: null,
            rLibEnq: null,
            msg: '',
            modif: false,
            modif_ligne: false,
            visible: false,
            visible_form_new_enq: false,
            visible_dlg_ligne: false,
            visible_dlg_enq: false,
            showindicator: false,
            listeChoix: [],
            ridFamille: 'Tous',
            nomBailleur: '',
            idBailleur: '',
            selectedPrest: null,
            liste_famille: [],
            liste_famille_all: [],
            liste_lib_enquete: [],
            libEnq: 'NA'
        };
        this.edit_enquete = this.edit_enquete.bind(this);
        this.conf_delete_enquete = this.conf_delete_enquete.bind(this);

        this.edit_ligne_enquete = this.edit_ligne_enquete.bind(this);
        this.footerTemplate = this.footerTemplate.bind(this);
        this.footerTemplate = this.footerTemplate.bind(this);
        this.footerTemplateLenquete = this.footerTemplateLenquete.bind(this);
        this.inputNumberEditor = this.inputNumberEditor.bind(this);
        this.noteEnqEditor = this.noteEnqEditor.bind(this);
        this.NotationficheBody = this.NotationficheBody.bind(this);
        this.noteEnqRadioEditor = this.noteEnqRadioEditor.bind(this);
        this.radioEditor = this.radioEditor.bind(this);
        this.radioEditor2 = this.radioEditor2.bind(this);
        this.FbrService = new FbrService();

    }


    componentDidMount() {
        this.get_liste_enquete();
        this.get_liste_prestataire();
        this.setState({ showindicator: true });
    }

    get_liste_famille_enquete() {
        this.FbrService.get_liste_famille_enquete(this.state.idBailleur).then(data => {
            this.setState({ showindicator: false });
            if (data.length > 0) {
                let liste = data.map(x => {
                    return { value: x.idCodif, label: x.libCodif, typePrest: x.ciblePrest }
                });
                let liste_lib = data.map(x => {
                    return { value: x.libCodif, label: x.libCodif, typePrest: x.ciblePrest }
                });
                this.setState({
                    liste_famille: liste,
                    liste_famille_all: liste,
                    liste_lib_enquete: liste_lib
                },()=>{
                    this.selectFamilles();
                });
            }
        });
    } 

    selectFamilles() {
        let liste = this.state.liste_famille_all;
        if (liste.length > 0 && this.state.selectedPrest !== null) {
            let id = this.state.selectedPrest.idTypePrest;
            liste = liste.filter(function (el) {
                return el.typePrest == id;
            });
        }
        this.setState({ liste_famille: liste });
    }

    noteEnqEditor(props) {
        return this.inputNumberEditor(props, 'noteEnq');
    }

    inputNumberEditor(props, field) {
        return <InputNumber value={props.rowData[field]} onChange={(e) => this.onEditorValueChange(props, e.value)} />;
    }

    onEditorValueChange(props, value) {
        let updateds = [...props.value];
        updateds[props.rowIndex][props.field] = value;
        console.log(value);
        this.setState({ liste_ligne: updateds });
        this.forceUpdate();
    }

    onEditorValueChange2(props, value) {
        let updateds = [...props.value];
        updateds[props.rowIndex]['noteEnq'] = value.valueItem;
        updateds[props.rowIndex]['appLenq'] = value.keyItem;
        this.setState({ liste_ligne: updateds });
        this.forceUpdate();
    }


    noteEnqRadioEditor(props) {
        return this.radioEditor(props, 'noteEnq');
    }

    radioEditor2(props, field) {
        let list = props.rowData.idType.listeChoix;
        if (list !== null && list !== undefined && list !== '' && list.length > 0) {
            return <div >
                <label style={{ fontSize: 16, fontWeight: 'bold', marginBottom: 30 }} >{props.rowIndex + 1}) {props.rowData.libLenq} </label>
                <br /><br />
                {list.map((item) => {
                    return (
                        <div key={item.idItem} className="p-field-radiobutton">
                            <RadioButton
                                inputId={item.idItem}
                                name="item"
                                value={item}
                                onChange={(e) => {
                                    this.onEditorValueChange2(props, e.value);
                                }} checked={props.rowData.appLenq === item.keyItem}
                            />
                            <label htmlFor={item.idItem}> {item.keyItem} </label>
                            <br />
                        </div>
                    );
                })}
            </div>
        } else {
            return <div>
                <label style={{ fontSize: 16, fontWeight: 'bold', marginBottom: 30 }} >{props.rowIndex + 1}) {props.rowData.libLenq} </label>
                <br /><br />
                <InputText style={{ width: 200 }} onChange={(e) => {
                    this.onEditorValueChange2(props, { keyItem: e.value, valueItem: 0 });
                }} value={props.rowData['appLenq']} />
            </div>
        }
    }


    radioEditor(props, field) {
        let list = props.rowData.idType.srceVerifType.split(";");
        if (list !== null && list !== undefined) {
            return <div className="p-grid" style={{ width: '98%', paddingTop: 20, paddingLeft: 5 }}>
                {list.map((item) => {
                    return (
                        <div key={item} className="p-col p-field-radiobutton" style={{ marginLeft: -8 }}>
                            <RadioButton inputId={item} name="item" value={item} onChange={(e) => {
                                this.onEditorValueChange(props, e.value);
                            }} checked={props.rowData.noteEnq === item} />
                            <label htmlFor={item}> {item} </label>
                        </div>
                    );
                })}
            </div>
        } else {
            return <InputNumber value={props.rowData[field]} onChange={(e) => this.onEditorValueChange(props, e.value)} />;
        }
    }


    get_liste_prestataire() {
        this.setState({ showindicator: true });
        this.FbrService.get_liste_prestataire_eval().then(data => {
            this.setState({ showindicator: false });
            if (data !== null && data !== undefined && data !== '') {
                if (data.length > 0) {
                    let liste = data.map(x => {
                        return { value: x.idPrest, label: x.nomPrest, typePrest: x.typePrest.libCodif, nomBailleur: x.idBailleur.libCodif, idBailleur: x.idBailleur.idCodif, idTypePrest: x.typePrest.idCodif }
                    }).filter(function (el) {
                        return (el.typePrest == 'RECO-ASC' || el.typePrest == 'PS' || el.typePrest == 'Hopital' || el.typePrest == 'CS' || el.typePrest == 'DPS' || el.typePrest == 'DRS');
                    });
                    this.setState({ listeprest: liste });
                }
            }
        });
    }

    onIndexTemplate(data, props) {
        return props.rowIndex + 1;
    }

    headerTemplate(data) {
        return (
            <React.Fragment>
                <span style={{ fontWeight: 'bold', color: '#106198' }}>{data.idType.idFamille.libCodif}</span>
            </React.Fragment>
        );
    }


    footerTemplate(data) {
        let max = '0';
        let note = '0';
        let td = '0';
        let lab = this.calculateCustomerTotal(data.idType.idFamille.idCodif);
        if (lab !== null && lab !== undefined && lab !== '') {
            max = lab.split('|')[0];
            note = lab.split('|')[1];
            if (parseFloat(max) > 0) {
                td = Math.round(100 * parseFloat(note) / parseFloat(max));
            }
        }
        return (
            <React.Fragment>
                <td colSpan="2" style={{ textAlign: 'right', fontWeight: 'bold' }}>TOTAL </td>
                <td style={{ fontWeight: 'bold' }}>{note}</td>
                <td style={{ fontWeight: 'bold' }}>{max}</td>
                <td style={{ fontWeight: 'bold', color: 'red' }}>{td} %</td>
            </React.Fragment>
        );
    }

    footerTemplateLenquete(data) {
        let max = '0';
        let note = '0';
        let lab = this.calculateCustomerTotalLenquete(data.idType.idFamille.idCodif);
        if (lab !== null && lab !== undefined && lab !== '') {
            max = lab.split('|')[0];
            note = lab.split('|')[1];
        }
        return (
            <React.Fragment>
                <td colSpan="2" style={{ textAlign: 'right', fontWeight: 'bold' }}>TOTAL </td>
                <td style={{ fontWeight: 'bold' }}>{note} / {max}</td>
            </React.Fragment>
        );
    }

    calculateCustomerTotalLenquete(idFamille) {
        let total = 0;
        let note = 0;
        if (this.state.liste_ligne) {
            for (let row of this.state.liste_ligne) {
                if (row.idType.idFamille.idCodif === idFamille) {
                    note = note + parseInt(row.noteEnq);
                    total = total + row.maxEnq;
                }
            }
        }
        return total + '|' + note;
    }

    calculateCustomerTotal(idFamille) {
        let total = 0;
        let note = 0;
        if (this.state.liste_ligne) {
            for (let row of this.state.liste_ligne) {
                if (row.idType.idFamille.idCodif === idFamille) {
                    note = note + row.noteEnq;
                    total = total + row.maxEnq;
                }
            }
        }
        return total + '|' + note;
    }

    get_liste_ligne_enquete(idEnq) {
        this.setState({ showindicator: true });
        this.FbrService.get_liste_ligne_enquete(idEnq).then(data => this.setState({ liste_ligne_enquete: data, showindicator: false }));
    }

    get_liste_enquete() {
        this.setState({ showindicator: true });
        let spd1 = moment(this.state.pd1).format('DD/MM/YYYY');
        let spd2 = moment(this.state.pd2).format('DD/MM/YYYY');
        let ridpres = 'Tous';
        let rLibEnq = 'Tous';
        if (this.state.ridPrest !== undefined && this.state.ridPrest !== null && this.state.ridPrest !== '') {
            ridpres = this.state.ridPrest;
        }
        if (this.state.rLibEnq !== undefined && this.state.rLibEnq !== null && this.state.rLibEnq !== '') {
            rLibEnq = this.state.rLibEnq;
        }

        this.FbrService.get_liste_enquete(spd1, spd2, ridpres, rLibEnq).then(data => this.setState({ liste_enquete: data, showindicator: false }));
    }


    resultat(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_enquete();
            this.componentDidMount();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    edit_ligne_enquete(data) {
        if (data != null && data != undefined) {
            this.setState({ idLenq: data.idLenq });
            this.setState({ maxEnq: data.maxEnq });
            this.setState({ noteEnq: data.noteEnq });
            this.setState({ libLenq: data.libLenq });
            this.setState({ appLenq: data.appLenq });
            this.setState({ cotationEnq: data.cotationEnq });
            this.setState({ modif_ligne: true, visible_dlg_ligne: true });
            this.setState({ listeChoix: data.idType.listeChoix });
            this.setState({ selectedLenquete: data });
        }
    }

    NotationficheBody(rowData, props) {
        let list = rowData.idType.listeChoix;
        if (list !== null && list !== undefined && list !== '' && list.length > 0) {
            return <div >
                <label style={{ fontSize: 16, fontWeight: 'bold', marginBottom: 30 }} >{props.rowIndex + 1}) {rowData.libLenq} </label>
                <br /><br />
                {list.map((item) => {
                    return (
                        <div key={item.idItem} className="p-field-radiobutton">
                            <RadioButton
                                inputId={item.idItem}
                                name="item"
                                value={item}
                                onChange={(e) => {
                                    this.state.liste_ligne[props.rowIndex].noteEnq = e.target.value.valueItem;
                                    this.state.liste_ligne[props.rowIndex].appLenq = e.target.value.keyItem;
                                }} checked={props.rowData.appLenq === item.keyItem}
                            />
                            <label htmlFor={item.idItem}> {item.keyItem} </label>
                            <br />
                        </div>
                    );
                })}
            </div>
        } else {
            return <div>
                <label style={{ fontSize: 16, fontWeight: 'bold', marginBottom: 30 }} >{props.rowIndex + 1}) {rowData.libLenq} </label>
                <br /><br />
                <InputText onChange={(e) => {
                    this.state.liste_ligne[props.rowIndex].appLenq = e.value;
                }} style={{ width: 200 }} value={rowData.appLenq} />
            </div>

        }
    }

    getTab() {
        if (this.state.nomBailleur == 'KFW') {
            return <div className="card" style={{ height: 1500, marginTop: -20 }}>
                <DataTable value={this.state.liste_ligne}
                    responsive={true} scrollable={true} scrollHeight="1450px" selectionMode="single" className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                    onRowClick={e => this.setState({ selectedLenquete: e.data })} onRowSelect={e => this.setState({ selectedLenquete: e.data })}
                    selection={this.state.selectedLenquete}
                    rowGroupFooterTemplate={this.footerTemplateLenquete}>
                    <Column field="noteEnq" body={this.NotationficheBody} editor={this.radioEditor2} />
                </DataTable>
            </div>
        } else {
            return <div className="card" style={{ height: 580, marginTop: -20 }}>
                <DataTable value={this.state.liste_ligne}
                    responsive={true} scrollable={true} scrollHeight="450px" selectionMode="single" className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                    onRowClick={e => this.setState({ selectedLenquete: e.data })} onRowSelect={e => this.setState({ selectedLenquete: e.data })}
                    selection={this.state.selectedLenquete}
                    rowGroupHeaderTemplate={this.headerTemplate} rowGroupMode="subheader"
                    rowGroupFooterTemplate={this.footerTemplateLenquete}
                    groupField="idType.idFamille.libCodif">
                    <Column field="Index" header="N°" body={this.onIndexTemplate} style={{ width: '5%' }} />
                    <Column field="libLenq" header="Indicateur" />
                    <Column field="noteEnq" body={(rowData, column) => {
                        let list = rowData.idType.srceVerifType.split(";");
                        if (list !== null && list !== undefined) {
                            return <div className="p-grid p-flex-row" style={{ width: '98%', paddingTop: 20, paddingLeft: 5 }}>
                                {list.map((item) => {
                                    return (
                                        <div key={item} className="p-col p-field-radiobutton" style={{ marginLeft: -8 }}>
                                            <RadioButton inputId={item} name="item" value={item} onChange={(e) => this.setState({ typePi: e.target.value })} checked={rowData.noteEnq === item} />
                                            <label htmlFor={item}> {item} </label>
                                        </div>
                                    );
                                })}
                            </div>
                        }
                    }} header="Note" style={{ width: '20%' }} editor={this.radioEditor} />
                </DataTable>
            </div>
        }
    }
    edit_enquete(data) {
        if (data != null && data != undefined) {
            this.setState({ idEnq: data.idEnq });
            this.setState({ idPrest: data.idPrest });
            this.setState({ nomEnq: data.nomEnq });
            this.setState({ telEnq: data.telEnq });
            this.setState({ heureDebEnq: data.heureDebEnq });
            this.setState({ heureFinEnq: data.heureFinEnq });
            this.setState({ suggestionEnq: data.suggestionEnq });
            this.setState({ modif: true, visible_dlg_enq: true });
            this.setState({ selectedEnquete: data });
        }
    }

    annule_enquete() {
        this.setState({ nomEnq: '' });
        this.setState({ idEnq: 0 });
        this.setState({ suggestionEnq: '' });
        this.setState({ heureFinEnq: '' });
        this.setState({ heureDebEnq: '' });
        this.setState({ telEnq: '' });
        this.setState({ modif: false, visible_dlg_enq: false });
        this.setState({ idPrest: null });
        this.setState({ liste_ligne: [] });
        this.forceUpdate();
    }

    resultat_ligne(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.get_liste_ligne_enquete(this.state.selectedEnquete.idEnq);
            this.setState({ visible_dlg_ligne: false });
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    enreg_ligne() {
        if (this.state.selectedLenquete !== null && this.state.selectedLenquete != undefined) {
            if (this.state.libLenq == "") {
                this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
                return;
            }
            if (this.state.selectedEnquete.etatEnq == "Validé") {
                this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Impossible de modifier cette ligne car déja validée' });
                return;
            }
            this.setState({ showindicator: true });
            var ligne = this.state.selectedLenquete;
            ligne.noteEnq = this.state.noteEnq;
            ligne.appLenq = this.state.appLenq;
            this.FbrService.enreg_ligne_enquete(ligne).then(data => this.resultat_ligne(data.code, data.contenu));
        } else {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez selectionner une ligne' });
        }
    }

    enreg_enq() {
        if (this.state.dateEnq == "" || this.state.dateEnq == null || this.state.nomEnq == "" || this.state.heureFinEnq == "" || this.state.heureDebEnq == "") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            this.setState({ showindicator: true });
            let userconnected = this.FbrService.get_userconnected();
            if (this.state.modif == false) {
                let val = {
                    idEnq: 0,
                    heureDebEnq: this.state.heureDebEnq,
                    heureFinEnq: this.state.heureFinEnq,
                    nomEnq: this.state.nomEnq,
                    etatEnq: 'En attente',
                    telEnq: this.state.telEnq,
                    suggestionEnq: this.state.suggestionEnq,
                    idPrest: { idPrest: this.state.idPrest },
                    iduser: userconnected.idUser,
                    idUser: { idUser: userconnected.idUser },
                    libEnq: this.state.libEnq,
                    dateEnq: moment(this.state.dateEnq).format('DD/MM/YYYY')
                };
                for (var i = this.state.liste_ligne.length; i--;) {
                    this.state.liste_ligne[i].idEnq = val;
                    this.state.liste_ligne[i].iduser = userconnected.idUser;
                }
                this.FbrService.enreg_enquete_batch(this.state.liste_ligne).then(data => this.resultat(data.code, data.contenu));
            } else {
                let val = this.state.selectedEnquete;
                val.nomEnq = this.state.nomEnq;
                val.suggestionEnq = this.state.suggestionEnq;
                val.heureDebEnq = this.state.heureDebEnq;
                val.telEnq = this.state.telEnq;
                val.heureFinEnq = this.state.heureFinEnq;
                val.iduser = userconnected.idUser;
                val.idPrest = { idPrest: this.state.idPrest };
                val.dateEnq = moment(this.state.dateEnq).format('DD/MM/YYYY');
                this.FbrService.enreg_enquete(val).then(data => this.resultat(data.code, data.contenu));
            }
        }
    }

    actionTemplate(rowData) {
        return <div className="p-grid" style={{ width: 90 }}>
            <div className="p-col"><Button title='Modifier' onClick={() => this.edit_enquete(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button title='Supprimer' onClick={() => this.conf_delete_enquete(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -20 }} icon="pi pi-times" /></div>
        </div>
    }


    delete_enq() {
        if (this.state.selectedEnquete == null || this.state.selectedEnquete == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.FbrService.delete_enquete(this.state.selectedEnquete).then(data => this.resultat(data.code, data.contenu));
        }
    }

    get_liste_ligne_enquete_saisie(idPrest, idFamille) {
        if (this.state.nomBailleur == 'KFW') {
            if (this.state.idPrest !== null && this.state.ridFamille !== 'Tous') {
                this.setState({ showindicator: true });
                this.FbrService.get_liste_ligne_enquete_saisie(idPrest, idFamille).then(data => {
                    this.setState({ showindicator: false });
                    this.setState({ liste_ligne: data });
                })
            }
        } else {
            this.setState({ showindicator: true });
            this.FbrService.get_liste_ligne_enquete_saisie(idPrest, idFamille).then(data => {
                this.setState({ showindicator: false });
                this.setState({ liste_ligne: data });
            })
        }

    }


    conf_delete_enquete(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedEnquete: data });
            this.setState({ msg: data.nomEnq });
            this.setState({ visible: true });
        }
    }

    actionTemplate_enq(rowData, props) {
        let items = [
            {
                label: 'Supprimer',
                icon: 'pi pi-times',
                command: (e) => {
                    this.conf_delete_enquete(rowData);
                }
            },
            {
                label: 'Modifier',
                icon: 'pi pi-pencil',
                command: (e) => {
                    this.edit_enquete(rowData);
                }
            }
        ]
        let cl = 'p-button-info';
        let label = props.rowIndex + 1 + '';
        let etat = rowData.etatEnq;
        if (etat === 'Validé') {
            cl = 'p-button-success';
        } else {
            cl = 'p-button-warning';
        }
        return <SplitButton title='Modifier' label={label} className={cl} onClick={(e) => this.edit_enquete(rowData)} icon="pi pi-pencil" style={{ width: 78 }} model={items}></SplitButton>
    }

    actionTemplate_Lenquete(rowData) {
        return <Button title='Modifier' onClick={(e) => this.edit_ligne_enquete(rowData)} style={{ width: 25, height: 25 }} icon="pi pi-pencil" />
    }

    itemTemplateRech(option) {
        if (!option.value) {
            return option.label;
        }
        else {
            return (
                <div className="p-grid" >
                    <div className="p-col-12 p-md-8">
                        <label>{option.label}</label>
                    </div>
                    <div className="p-col-12 p-md-4">
                        <label>{option.typePrest}</label>
                    </div>
                </div>
            );
        }
    }

    itemTemplate(option) {
        if (!option.value) {
            return option.label;
        }
        else {
            return (
                <div className="p-grid" style={{ padding: 10 }}>
                    <div className="p-col-12 p-md-8">
                        <label>{option.label}</label>
                    </div>
                    <div className="p-col-12 p-md-4">
                        <label>{option.typePrest}</label>
                    </div>
                </div>
            );
        }
    }

    render() {
        let header_new = <Button title='Nouvelle fiche' label='Nouveau' className="p-button-help" onClick={(e) => {
            this.setState({ visible_form_new_enq: true });
        }} icon="pi pi-plus" style={{ width: 150 }} ></Button>
        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_enq();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        return (
            <div className="p-fluid">
                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={() => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                <Dialog header="Modification reponse" visible={this.state.visible_dlg_ligne} style={{ width: '400px' }} modal={true} onHide={(e) => this.setState({ visible_dlg_ligne: false })}>
                    <div className="p-fluid p-formgrid p-grid p-card" style={{ marginBottom: 10 }}>
                        <div className="p-field p-col-12 p-md-12">
                            <label style={{ fontWeight: 'bold' }}>Question *</label>
                            <InputTextarea rows={2} value={this.state.libLenq} onChange={(e) => this.setState({ libLenq: e.target.value })} />
                        </div>
                        <div className="p-field p-col-12 p-md-12">
                            <label style={{ fontWeight: 'bold' }} >Reponse *</label>
                            {this.state.listeChoix.length > 0 ?
                                <div >
                                    {this.state.listeChoix.map((item) => {
                                        return (
                                            <div key={item.idItem} className="p-field-radiobutton">
                                                <RadioButton
                                                    inputId={item.idItem}
                                                    name="item"
                                                    value={item}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            noteEnq: e.target.value.valueItem,
                                                            appLenq: e.target.value.keyItem
                                                        });
                                                    }} checked={this.state.appLenq === item.keyItem}
                                                />
                                                <label htmlFor={item.idItem}> {item.keyItem} </label>
                                                <br />
                                            </div>
                                        );
                                    })}
                                </div>
                                :
                                <InputText onChange={(e) => {
                                    this.setState({
                                        appLenq: e.target.value
                                    });
                                }} value={this.state.appLenq} />
                            }
                        </div>
                    </div>
                    <center>
                        <div className="p-grid" style={{ width: 300 }}>
                            <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={() => {
                                this.setState({ visible_dlg_ligne: false });
                            }} /></div>
                            <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={() => this.enreg_ligne()} /></div>

                        </div>
                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                    </center>
                </Dialog>

                <Dialog header="Modification enquête" visible={this.state.visible_dlg_enq} style={{ width: '70%' }} modal={true} onHide={(e) => this.setState({ visible_dlg_enq: false })}>
                    <div className="p-fluid p-formgrid p-grid p-card" style={{ marginBottom: 10 }}>
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="firstname2">Date *</label>
                            <Calendar dateFormat="dd/mm/yy" value={this.state.dateEnq} onChange={(e) => this.setState({ dateEnq: e.target.value })} appendTo={document.body} />
                        </div>
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="firstname2">Heure début *</label>
                            <InputMask mask="99:99" value={this.state.heureDebEnq} onChange={(e) => this.setState({ heureDebEnq: e.target.value })} />
                        </div>
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="firstname2">Heure fin *</label>
                            <InputMask mask="99:99" value={this.state.heureFinEnq} onChange={(e) => this.setState({ heureFinEnq: e.target.value })} />
                        </div>
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="firstname2">Acteur *</label>
                            <Dropdown id="idPrest" value={this.state.idPrest} options={this.state.listeprest} optionLabel='label' filter={true} filterBy='label,typePrest' filterMatchMode='contains' onChange={(e) => this.setState({ idPrest: e.target.value }, () => {
                                this.setState({ liste_ligne: [] });
                                this.get_liste_ligne_enquete_saisie(this.state.idPrest, this.state.ridFamille);
                            })} autoWidth={false} panelStyle={{ width: '300px' }} />
                        </div>
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="firstname2">Nom enquêteur *</label>
                            <InputText value={this.state.nomEnq} onChange={(e) => this.setState({ nomEnq: e.target.value })} />
                        </div>
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="firstname2">Téléphone enquêteur *</label>
                            <InputText value={this.state.telEnq} onChange={(e) => this.setState({ telEnq: e.target.value })} />
                        </div>
                        <div className="p-field p-col-12 p-md-12">
                            <label htmlFor="firstname2">Suggestions </label>
                            <InputTextarea rows={4} value={this.state.suggestionEnq} onChange={(e) => this.setState({ suggestionEnq: e.target.value })} />
                        </div>
                    </div>
                    <center>
                        <div className="p-grid" style={{ width: 300, marginBottom: -20 }}>
                            <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={() => {
                                this.setState({ visible_dlg_enq: false });
                            }} /></div>
                            <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={() => this.enreg_enq()} /></div>

                        </div>
                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                    </center>
                </Dialog>


                {
                    this.state.visible_form_new_enq == true ?
                        <div className="p-card" style={{ padding: 5 }}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

                                <label style={{ fontSize: 18, fontWeight: 'bold' }}>
                                    <i className="pi pi-pencil"></i>  NOUVELLE FICHE D'ENQUETE
                                </label>
                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                <label>
                                    <Button label="Liste enquête" className="p-button-help" icon="pi pi-list" onClick={() => {
                                        this.setState({ visible_form_new_enq: false, liste_ligne: [] });
                                    }} />
                                </label>

                            </div>
                            <hr />
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="firstname2">Date *</label>
                                    <Calendar dateFormat="dd/mm/yy" value={this.state.dateEnq} onChange={(e) => this.setState({ dateEnq: e.target.value })} appendTo={document.body} />
                                </div>
                                <div className="p-field p-col-12 p-md-3">
                                    <label htmlFor="firstname2">Acteur *</label>
                                    <Dropdown id="idPrest" value={this.state.idPrest} options={this.state.listeprest} optionLabel='label' filter={true} filterBy='label,typePrest' filterMatchMode='contains' onChange={(e) => this.setState({ idPrest: e.target.value }, () => {
                                        this.setState({ liste_ligne: [] });
                                        this.setState({ selectedPrest: this.FbrService.getSelectedData(this.state.listeprest, this.state.idPrest) }, () => {
                                            this.setState({
                                                nomBailleur: this.state.selectedPrest.nomBailleur,
                                                idBailleur: this.state.selectedPrest.idBailleur,
                                            }, () => {
                                                this.get_liste_famille_enquete();
                                                this.get_liste_ligne_enquete_saisie(this.state.idPrest, this.state.ridFamille);
                                            })
                                        })
                                    })} autoWidth={false}
                                        panelStyle={{ width: '300px' }} appendTo={document.body} filterInputAutoFocus={false} />
                                </div>
                                {this.state.nomBailleur == 'KFW' ?
                                    <div className="p-field p-col-12 p-md-3">
                                        <label htmlFor="firstname2">Fiche *</label>
                                        <Dropdown value={this.state.ridFamille} options={this.state.liste_famille} optionLabel='label' filter={true} filterBy='label,typePrest' filterMatchMode='contains' onChange={(e) => this.setState({ ridFamille: e.target.value }, () => {
                                            this.setState({ liste_ligne: [] });
                                            this.setState({libEnq: this.FbrService.getSelectedData(this.state.liste_famille, this.state.ridFamille).label});
                                            this.get_liste_ligne_enquete_saisie(this.state.idPrest, this.state.ridFamille);
                                        })} autoWidth={false}
                                            panelStyle={{ width: '300px' }} appendTo={document.body} filterInputAutoFocus={false} />
                                    </div>
                                    : null}
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="firstname2">Heure début *</label>
                                    <InputMask mask="99:99" value={this.state.heureDebEnq} onChange={(e) => this.setState({ heureDebEnq: e.target.value })} />
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="firstname2">Heure fin *</label>
                                    <InputMask mask="99:99" value={this.state.heureFinEnq} onChange={(e) => this.setState({ heureFinEnq: e.target.value })} />
                                </div>
                            </div>

                            {this.getTab()}

                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-12 p-md-6">
                                    <label htmlFor="firstname2">Suggestions </label>
                                    <InputTextarea rows={2} value={this.state.suggestionEnq} onChange={(e) => this.setState({ suggestionEnq: e.target.value })} />
                                </div>
                                <div className="p-field p-col-12 p-md-3">
                                    <label htmlFor="firstname2">Nom enquêteur *</label>
                                    <InputText value={this.state.nomEnq} onChange={(e) => this.setState({ nomEnq: e.target.value })} />
                                </div>
                                <div className="p-field p-col-12 p-md-3">
                                    <label htmlFor="firstname2">Téléphone enquêteur *</label>
                                    <InputText value={this.state.telEnq} onChange={(e) => this.setState({ telEnq: e.target.value })} />
                                </div>
                            </div>
                            <br />
                            <center style={{}}>
                                <div className="p-grid" style={{ width: 300 }}>
                                    <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={() => this.annule_enquete()} /></div>
                                    <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={() => this.enreg_enq()} /></div>
                                    <br />
                                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                </div>
                            </center>
                        </div>
                        :
                        <>
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <div className="card-w-title">
                                        <i className="pi pi-search"></i> Option de recherche
                                        <hr />
                                        <div className="p-grid">
                                            <div className="p-col-12 p-md-2">
                                                <Calendar value={this.state.pd1} onChange={(e) => this.setState({ pd1: e.value }, () => {
                                                    this.get_liste_enquete();
                                                })} dateFormat="dd/mm/yy" yearNavigator yearRange="2021:2070" />
                                            </div>
                                            <div className="p-col-12 p-md-2">
                                                <Calendar value={this.state.pd2} onChange={(e) => this.setState({ pd2: e.value }, () => {
                                                    this.get_liste_enquete();
                                                })} dateFormat="dd/mm/yy" yearNavigator yearRange="2021:2070" />
                                            </div>
                                            <div className="p-col-12 p-md-3">
                                                <Dropdown autoWidth={false} options={[{ label: 'Acteur', value: 'Tous' }].concat(this.state.listeprest)} optionLabel='label' placeholder='Acteur' filter={true} filterBy='label,typePrest' filterMatchMode='contains' value={this.state.ridPrest} onChange={e => this.setState({ ridPrest: e.value }, () => {
                                                    this.setState({ selectedPrest: this.FbrService.getSelectedData(this.state.listeprest, this.state.ridPrest) }, () => {
                                                        if (this.state.selectedPrest !== null && this.state.selectedPrest !== undefined) {
                                                            this.setState({
                                                                nomBailleur: this.state.selectedPrest.nomBailleur,
                                                                idBailleur: this.state.selectedPrest.idBailleur,
                                                            }, () => {
                                                                this.get_liste_famille_enquete();
                                                            })
                                                        }
                                                    })
                                                    this.get_liste_enquete();
                                                })} panelStyle={{ width: '300px' }} appendTo={document.body} filterInputAutoFocus={false} />
                                            </div>
                                            {this.state.nomBailleur == 'KFW' ?
                                                <div className="p-col-12 p-md-2">
                                                    <Dropdown autoWidth={false} options={[{ label: 'Type enquête', value: 'Tous' }].concat(this.state.liste_lib_enquete)} optionLabel='label' placeholder='Type enquête' filter={true} filterBy='label' filterMatchMode='contains' value={this.state.rLibEnq} onChange={e => this.setState({ rLibEnq: e.value }, () => {
                                                        this.get_liste_enquete();
                                                    })} panelStyle={{ width: '300px' }} appendTo={document.body} filterInputAutoFocus={false} />
                                                </div>
                                                : null
                                            }

                                            <div className="p-col-12 p-md-2">
                                                <Button icon='pi pi-search' style={{ width: 150 }} label='Rechercher' onClick={() => this.get_liste_enquete()} />
                                            </div>
                                            <div className="p-col-12 p-md-1">
                                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="p-grid">
                                <div className="p-col-12 p-lg-6">
                                    <div className="p-grid">
                                        <div className="p-col-12">
                                            <div className="card" style={{ height: 590 }}>
                                                <DataTable value={this.state.liste_enquete} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                                                    responsive={true} paginator={true} rows={7} alwaysShowPaginator={true} selectionMode="single" paginatorPosition="top"
                                                    onRowClick={e => {
                                                        this.setState({ selectedEnquete: e.data }, () => {
                                                            this.get_liste_ligne_enquete(this.state.selectedEnquete.idEnq);
                                                        });
                                                    }} paginatorLeft={header_new} onRowSelect={e => this.setState({ selectedEnquete: e.data })}
                                                    selection={this.state.selectedEnquete} >
                                                    <Column field="idEnq" header="Action" body={this.actionTemplate_enq.bind(this)} style={{ width: '20%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                    <Column field="dateEnq" header="Date" body={(rowData, column) => {
                                                        return <div>
                                                            <span style={{}}>{rowData.dateEnq}</span>
                                                        </div>
                                                    }} filter={true} filterMatchMode='contains' sortable={true} style={{ width: '20%' }} />
                                                    <Column field="moyEnq" header="Moy" body={(rowData, column) => {
                                                        return <div>
                                                            <span style={{ fontWeight: 'bold' }}>{rowData.moyEnq} %</span>
                                                        </div>
                                                    }} filter={true} filterMatchMode='contains' sortable={true} style={{ width: '17%' }} />
                                                    <Column field="nomPrest" header="Acteur" body={(rowData, column) => {
                                                        return <div>
                                                            <span style={{ color: '#000000', fontWeight: 'bold' }}>{rowData.nomPrest}</span><br />
                                                            <span style={{ color: '#FF5733', fontSize: 10, fontWeight: 'bold' }}>{rowData.libEnq}</span>
                                                        </div>
                                                    }} filter={true} filterMatchMode='contains' sortable={true} />
                                                </DataTable>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12 p-lg-6">
                                    <div className="p-grid">
                                        <div className="p-col-12">
                                            <div className="card" style={{ height: 590 }}>
                                                <DataTable value={this.state.liste_ligne_enquete}
                                                    responsive={true} scrollable={true} scrollHeight="420px" selectionMode="single" className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                                                    onRowClick={e => this.setState({ selectedLenquete: e.data })} onRowSelect={e => this.setState({ selectedLenquete: e.data })}
                                                    selection={this.state.selectedLenquete} style={{}}>
                                                    <Column header="#" body={this.actionTemplate_Lenquete.bind(this)} style={{ width: '8%' }} />
                                                    <Column field="Index" header="N°" body={this.onIndexTemplate} style={{ width: '7%' }} />
                                                    <Column field="libLenq" header="Indicateur" filter={true} filterMatchMode='contains' />
                                                    <Column field="appLenq" header="Reponse" filter={true} filterMatchMode='contains' style={{ width: '25%' }} />
                                                </DataTable>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </>
                }

            </div>
        );
    }
}