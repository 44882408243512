import React, { Component } from 'react';
import FbrService from '../service/FbrService';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Editor } from 'primereact/editor';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { ProgressSpinner } from 'primereact/progressspinner';
 
export class FormArticle extends Component {
    constructor() {
        super();
        this.state = {
            listearticle: [],
            selectedArt: null,
            idArticle: 0,
            titreArticle: '',
            bodyArticle: '',
            couvArticle: '',
            listefic: [],
            listearticle: [],
            selectedFic: null,
            idFic: 0,
            libFic: '',
            urlFic: '',
            accesFic: 'PUBLIC',
            descFic: '',
            liste_access: [
                { label: 'PUBLIC', value: 'PUBLIC' },
                { label: 'PRIVÉ', value: 'PRIVÉ' }
            ],
            msg: '',
            file: null,
            fileJoint: null,
            modif: false,
            modifFic: false,
            visible: false,
            visible_fic: false,
            visible_dlg_fic: false,
            showindicator: false,
        };
        this.edit_article = this.edit_article.bind(this);
        this.conf_delete_article = this.conf_delete_article.bind(this);
        this.edit_fic = this.edit_fic.bind(this);
        this.conf_delete_fic = this.conf_delete_fic.bind(this);
        this.show_dlg_fic = this.show_dlg_fic.bind(this);
        this.download_file = this.download_file.bind(this);
        this.FbrService = new FbrService();
    }


    componentDidMount() {
        if (this.FbrService.get_userconnected().profilUser == 'Administrateur') {
            this.get_liste_article();
        } else {
            this.setState({ showindicator: true });
            this.FbrService.get_liste_fic_article_byAccess('Tous').then(data => this.setState({ listefic: data, showindicator: false }));
        }
    }

    get_liste_article() {
        this.setState({ showindicator: true });
        this.FbrService.get_liste_article().then(data => this.setState({ listearticle: data, showindicator: false }));
    }


    resultat(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_article();
            this.componentDidMount();
            this.forceUpdate();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: true });
    }

    edit_article(data) {
        if (data != null && data != undefined) {
            this.setState({ idArticle: data.idArticle });
            this.setState({ titreArticle: data.titreArticle });
            this.setState({ bodyArticle: data.bodyArticle });
            this.setState({ couvArticle: data.couvArticle });
            this.setState({ modif: true });
            this.setState({ selectedArt: data });
            window.scrollTo(0, 0);
        }
    }

    annule_article() {
        this.setState({ titreArticle: '' });
        this.setState({ bodyArticle: '' });
        this.setState({ couvArticle: '' });
        this.setState({ idArticle: 0 });
        this.setState({ modif: false });
        this.forceUpdate();
        window.scrollTo(0, 0);
    }

    fileUpload(file, namefile, destination) {
        this.setState({ showindicator: true });
        this.FbrService.upload_file(file, namefile, destination).then(data => {
            this.setState({ showindicator: false });
            if (data.code == '200') {
                this.toast.show({ severity: 'success', summary: 'Success', detail: 'Chargement effectué avec succès' });
            } else {
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Echec chargement' });
            }
        });
    }

    download_file(data) {
        this.FbrService.download_fichier_joint(data.urlFic, 'docs/')
    }

    enreg_article() {
        if (this.state.titreArticle == "") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            this.setState({ showindicator: true });
            let upload = false;
            let nomfic = this.state.couvArticle;
            if (this.state.file != null && this.state.file != undefined && this.state.file != '') {
                let name = this.state.file.name;
                upload = true;
                nomfic = moment(new Date(), 'DDMMYYHHMMSS') + '.' + name.slice((name.lastIndexOf(".") - 1 >>> 0) + 2);
            }
            if (this.state.modif == false) {
                let article = {
                    idArticle: 0,
                    titreArticle: this.state.titreArticle,
                    bodyArticle: this.state.bodyArticle,
                    couvArticle: nomfic
                }
                this.FbrService.enreg_article(article).then(data => {
                    if (data != undefined && data != null) {
                        if (upload === true) {
                            this.fileUpload(this.state.file, nomfic, 'photos');
                        }
                        this.resultat(data.code, data.contenu);
                    }
                });
            } else {
                let article = this.state.selectedArt;
                article.titreArticle = this.state.titreArticle;
                article.bodyArticle = this.state.bodyArticle;
                article.couvArticle = nomfic;
                this.FbrService.enreg_article(article).then(data => {
                    if (data != undefined && data != null) {
                        if (upload === true) {
                            this.fileUpload(this.state.file, nomfic, 'photos');
                        }
                        this.resultat(data.code, data.contenu);
                    }
                });
            }
        }
    }

    actionTemplate(rowData) {
        return <div className="p-grid" style={{ width: 120 }}>
            <div className="p-col"><Button title='Modifier' onClick={() => this.edit_article(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button title='Supprimer' onClick={() => this.conf_delete_article(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -20 }} icon="pi pi-times" /></div>
            <div className="p-col"><Button title='Fichiers joints' onClick={() => this.show_dlg_fic(rowData)} className="p-button-secondary" style={{ width: 25, height: 25, marginLeft: -20 }} icon="pi pi-sitemap" /></div>
        </div>
    }

    delete_article() {
        if (this.state.selectedArt == null || this.state.selectedArt == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.FbrService.delete_article(this.state.selectedArt).then(data => this.resultat(data.code, data.contenu));
        }
    }

    conf_delete_article(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedArt: data });
            this.setState({ msg: data.titreArticle });
            this.setState({ visible: true });
        }
    }

    show_dlg_fic(data) {
        if (data != null && data != undefined) {
            this.setState({ visible_dlg_fic: true }, () => {
                this.get_liste_fic(data.idArticle);
            });
        }
    }

    get_liste_fic(idArticle) {
        this.setState({ showindicator: true });
        this.FbrService.get_liste_fic_article(idArticle).then(data => {
            this.setState({ listefic: data, showindicator: false });
        })
    }

    photoTemplate(rowData, column) {
        let fic = rowData.couvArticle;
        if (fic === undefined || fic === null || fic === '') {
            fic = 'nocouv.png';
        }
        return <img src={this.FbrService.download_file(fic, 'photos')} style={{ width: 50, height: 50 }} />
    }

    resultat_fic(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_fic();
            this.get_liste_fic(this.state.selectedArt.idArticle);
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
    }

    edit_fic(data) {
        if (data != null && data != undefined) {
            this.setState({ libFic: data.libFic });
            this.setState({ typeFic: data.typeFic });
            this.setState({ descFic: data.descFic });
            this.setState({ accesFic: data.accesFic });
            this.setState({ urlFic: data.urlFic });
            this.setState({ modifFic: true });
            this.setState({ selectedFic: data });
        }
    }

    annule_fic() {
        this.setState({ libFic: '' });
        this.setState({ typeFic: '' });
        this.setState({ urlFic: '' });
        this.setState({ idArticle: '' });
        this.setState({ descFic: '' });
        this.setState({ fileJoint: null });
        this.setState({ idFic: 0 });
        this.setState({ modifFic: false });
        this.forceUpdate();
    }

    enreg_fic() {
        if (this.state.libFic == "") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            let upload = false;
            let nomfic = this.state.urlFic;
            let ext = this.state.typeFic;
            if (this.state.fileJoint !== null && this.state.fileJoint !== undefined && this.state.fileJoint !== '') {
                let name = this.state.fileJoint.name;
                upload = true;
                nomfic = name.replace(' ', '_');
                ext = name.slice((name.lastIndexOf(".") - 1 >>> 0) + 2);
            }
            if (this.state.modifFic == false) {
                let ficArticle = {
                    idFic: 0,
                    libFic: this.state.libFic,
                    typeFic: ext.toLowerCase(),
                    descFic: this.state.descFic,
                    urlFic: nomfic,
                    accesFic: this.state.accesFic,
                    idArticle: this.state.selectedArt
                }
                this.FbrService.enreg_fic_article(ficArticle).then(data => {
                    if (data != undefined && data != null) {
                        if (upload === true) {
                            this.fileUpload(this.state.fileJoint, nomfic, 'docs');
                        }
                        this.resultat_fic(data.code, data.contenu, 'docs');
                    }
                });
            } else {
                let ficArticle = this.state.selectedFic;
                ficArticle.libFic = this.state.libFic;
                ficArticle.descFic = this.state.descFic;
                ficArticle.accesFic = this.state.accesFic;
                ficArticle.typeFic = ext;
                ficArticle.urlFic = nomfic;
                this.FbrService.enreg_fic_article(ficArticle).then(data => {
                    if (data != undefined && data != null) {
                        if (upload === true) {
                            this.fileUpload(this.state.fileJoint, nomfic);
                        }
                        this.resultat_fic(data.code, data.contenu);
                    }
                });
            }
        }
    }

    actionTemplateFic(rowData) {
        let disable = true;
        if (this.FbrService.get_userconnected().profilUser == 'Administrateur') {
            disable = false;
        }
        return <div className="p-grid" style={{ width: 120 }}>
            <div className="p-col"><Button title='Modifier' disabled={disable} onClick={() => this.edit_fic(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button title='Supprimer' disabled={disable} onClick={() => this.conf_delete_fic(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -20 }} icon="pi pi-times" /></div>
            <div className="p-col"><Button title='Télécharger' onClick={() => this.download_file(rowData)} className="p-button-secondary" style={{ width: 25, height: 25, marginLeft: -20 }} icon="pi pi-download" /></div>
        </div>
    }

    actionTemplateFicGuest(rowData) {
        return <div className="p-grid" style={{}}>
            <div className="p-col"><Button label='Télécharger' onClick={() => this.download_file(rowData)} className="p-button-secondary" style={{}} icon="pi pi-download" /></div>
        </div>
    }

    delete_fic() {
        if (this.state.selectedFic == null || this.state.selectedFic == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.FbrService.delete_fic_article(this.state.selectedFic).then(data => this.resultat_fic(data.code, data.contenu));
        }
    }

    conf_delete_fic(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedFic: data });
            this.setState({ msg: data.libFic });
            this.setState({ visible_fic: true });
        }
    }

    render() {
        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_article();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        const dialogFooterFic = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible_fic: false });
                    this.delete_fic();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible_fic: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        return (
            <div className="p-fluid">
                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={() => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                <Dialog header="Confirmation" visible={this.state.visible_fic} style={{ width: '250px' }} modal={true} footer={dialogFooterFic} onHide={() => this.setState({ visible_fic: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>

                <Dialog header="Joindre des fichiers" visible={this.state.visible_dlg_fic} style={{ width: '70%' }} modal={true} onHide={(e) => this.setState({ visible_dlg_fic: false })}>
                    <div className="card p-fluid">
                        <div className="p-grid">
                            <div className="p-col-12 p-md-2">
                                <label htmlFor="libFic">Nom du fichier *</label>
                            </div>
                            <div className="p-col-12 p-md-4">
                                <InputText id="libFic" value={this.state.libFic} onChange={(e) => this.setState({ libFic: e.target.value })} />
                            </div>
                            <div className="p-col-12 p-md-2">
                                <label htmlFor="typeFic">Accès *</label>
                            </div>
                            <div className="p-col-12 p-md-4">
                                <Dropdown value={this.state.accesFic} options={this.state.liste_access} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ accesFic: e.target.value })} autoWidth={false} />
                            </div>

                            <div className="p-col-12 p-md-2">
                                <label htmlFor="libFic">Description </label>
                            </div>
                            <div className="p-col-12 p-md-4">
                                <InputText id="libFic" value={this.state.descFic} onChange={(e) => this.setState({ descFic: e.target.value })} />
                            </div>

                            <div className="p-col-12 p-md-2">
                                <label htmlFor="couvArt">Fichier </label>
                            </div>
                            <div className="p-col-12 p-md-4">
                                <input type="file" onChange={(e) => this.setState({ fileJoint: e.target.files[0] })} />
                            </div>

                        </div>
                        <center>
                            <div className="p-grid" style={{ width: 300 }}>
                                <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={() => this.annule_fic()} /></div>
                                <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={() => this.enreg_fic()} /></div>
                            </div>
                            {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}

                        </center>

                        <DataTable value={this.state.listefic} paginatorPosition="top" selectionMode="single" paginator={true} rows={100}
                            alwaysShowPaginator={false} onRowClick={e => this.setState({ selectedFic: e.data })} onRowSelect={e => this.setState({ selectedFic: e.data })}
                            responsive={true} selection={this.state.selectedFic} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers" >
                            <Column header="Action" body={this.actionTemplateFic.bind(this)} style={{ width: '12%' }} />
                            <Column field="libFic" body={(rowData, column) => {
                                let ic = 'pi pi-file';
                                let cl = 'black';
                                if (rowData.typeFic == 'pdf') {
                                    ic = 'pi pi-file-pdf';
                                    cl = 'red';
                                } else if (rowData.typeFic == 'xls' || rowData.typeFic == 'xlsx') {
                                    ic = 'pi pi-file-excel';
                                    cl = 'green';
                                }
                                return <div>
                                    <i className={ic} style={{ color: cl }}></i> {rowData.libFic}
                                    <span style={{ color: 'green', fontWeight: 'bold' }}></span>
                                </div>
                            }} filter={true} filterMatchMode='contains' header="Fichier" sortable={true} />
                            <Column field="descFic" filter={true} filterMatchMode='contains' header="Description" sortable={true} />
                            <Column field="accesFic" filter={true} filterMatchMode='contains' header="Accès" sortable={true} style={{ width: '10%' }} />
                        </DataTable>
                    </div>
                </Dialog>

                {
                    this.FbrService.get_userconnected() !== undefined && this.FbrService.get_userconnected() !== null ?
                        <>
                            {this.FbrService.get_userconnected().profilUser == 'Administrateur' ?
                                <div className="p-grid">
                                    <div className="p-col-12">
                                        <div className="card card-w-title">

                                            <div className="p-grid">
                                                <div className="p-col-12 p-md-2">
                                                    <label htmlFor="titreArt">Titre</label>
                                                </div>
                                                <div className="p-col-12 p-md-4">
                                                    <InputText id="titreArt" value={this.state.titreArticle} onChange={(e) => this.setState({ titreArticle: e.target.value })} />
                                                </div>

                                                <div className="p-col-12 p-md-2">
                                                    <label htmlFor="couvArt">Couverture</label>
                                                </div>
                                                <div className="p-col-12 p-md-4">
                                                    <input type="file" onChange={(e) => this.setState({ file: e.target.files[0] })} />
                                                </div>

                                                <div className="p-col-12">
                                                    <Editor style={{ height: '320px' }} value={this.state.bodyArticle} onTextChange={(e) => this.setState({ bodyArticle: e.htmlValue })} />
                                                </div>
                                            </div>
                                            <center>
                                                <div className="p-grid" style={{ width: 300 }}>
                                                    <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={() => this.annule_article()} /></div>
                                                    <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={() => this.enreg_article()} /></div>
                                                </div>
                                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                            </center>

                                            <DataTable value={this.state.listearticle} paginatorPosition="top" selectionMode="single" paginator={true} rows={100}
                                                alwaysShowPaginator={false} onRowClick={e => this.setState({ selectedArt: e.data })} onRowSelect={e => this.setState({ selectedArt: e.data })}
                                                responsive={true} selection={this.state.selectedArt} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers" >
                                                <Column header="Action" body={this.actionTemplate.bind(this)} style={{ width: '10%' }} />
                                                <Column field="dateArticle" filter={true} filterMatchMode='contains' header="Datee" sortable={true} style={{ width: '10%' }} />
                                                <Column field="titreArticle" filter={true} filterMatchMode='contains' header="Titre" sortable={true} />
                                                <Column field="couvArticle" body={this.photoTemplate.bind(this)} header="Couverture" style={{ width: '15%' }} />
                                            </DataTable>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="p-grid">
                                    <div className="card card-w-title">
                                        <DataTable value={this.state.listefic} paginatorPosition="top" selectionMode="single" paginator={true} rows={100}
                                            alwaysShowPaginator={false} onRowClick={e => this.setState({ selectedFic: e.data })} onRowSelect={e => this.setState({ selectedFic: e.data })}
                                            responsive={true} selection={this.state.selectedFic} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers" >
                                            <Column header="Action" body={this.actionTemplateFicGuest.bind(this)} style={{ width: '12%' }} />
                                            <Column field="dateFic" filter={true} filterMatchMode='contains' header="Date" sortable={true} style={{ width: '10%' }} />
                                            <Column field="libFic" body={(rowData, column) => {
                                                let ic = 'pi pi-file';
                                                let cl = 'black';
                                                if (rowData.typeFic == 'pdf') {
                                                    ic = 'pi pi-file-pdf';
                                                    cl = 'red';
                                                } else if (rowData.typeFic == 'xls' || rowData.typeFic == 'xlsx') {
                                                    ic = 'pi pi-file-excel';
                                                    cl = 'green';
                                                }
                                                return <div>
                                                    <i className={ic} style={{ color: cl }}></i> {rowData.libFic}
                                                    <span style={{ color: 'green', fontWeight: 'bold' }}></span>
                                                </div>
                                            }} filter={true} filterMatchMode='contains' header="Fichier" sortable={true} />
                                            <Column field="descFic" filter={true} filterMatchMode='contains'   sortable={true} header="Description" />
                                            <Column field="accesFic" filter={true} filterMatchMode='contains' header="Accès" sortable={true} style={{ width: '10%' }} />
                                        </DataTable>
                                    </div>
                                </div>
                            }
                        </>
                        :
                        null
                }


            </div>
        );
    }
}