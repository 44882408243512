import React from 'react';

const AppFooter = () => {

    return (
        <div className="layout-footer">
             <div className="footer-logo-container">
                <img id="footer-logo" src="assets/layout/images/logo-fbr.png" alt="diamond-layout" />
                <span className="app-name">FBP</span>
            </div>
            <span className="copyright">&#169; Ministère de la Santé</span>
        </div>
    );
}

export default AppFooter;
