import React, { Component } from 'react';
import FbrService from '../service/FbrService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { Dropdown } from 'primereact/dropdown';
import { SplitButton } from 'primereact/splitbutton';
import { Calendar } from 'primereact/calendar';
import { ProgressSpinner } from 'primereact/progressspinner';
import 'moment/locale/fr';
import moment from 'moment';

export class FormJournalResultatEnquete extends Component {
    constructor() {
        super();
        this.state = {
            pd1: new Date(moment('01/01/' + moment(new Date()).format('YYYY'), 'YYYY-MM-DD').format('YYYY-MM-DD')),
            pd2: new Date(),
            ridPrest: null,
            liste_data: [],
            listeprest: [],
            totalFacture: '0',
            totalRest: '0',
            totalQual: '0',
            showindicator: false,
            items: [
                {
                    label: 'Format CSV',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.dt.exportCSV();
                    }
                },
            ],
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
        };

        this.FbrService = new FbrService();
    }


    get_liste_prestataire() {
        this.setState({ showindicator: true });
        this.FbrService.get_liste_prestataire_eval().then(data => {
            this.setState({ showindicator: false });
            if (data !== null && data !== undefined && data !== '') {
                if (data.length > 0) {
                    let liste = data.map(x => {
                        return { value: x.idPrest, label: x.nomPrest }
                    });
                    this.setState({ listeprest: liste });
                }
            }


        });
    }

    get_liste_data() {
        this.setState({ showindicator: true });
        let spd1 = moment(this.state.pd1).format('DD/MM/YYYY');
        let spd2 = moment(this.state.pd2).format('DD/MM/YYYY');
        let ridpres = 'Tous';
        if (this.state.ridPrest !== undefined && this.state.ridPrest !== null && this.state.ridPrest !== '') {
            ridpres = this.state.ridPrest;
        }
        this.FbrService.get_liste_resultat_enquete(spd1, spd2, ridpres).then(data => this.setState({ liste_data: data, showindicator: false }));

    }

    componentDidMount() {
        this.get_liste_prestataire();
        this.get_liste_data();
    }


    render() {


        return (
            <div className="card p-fluid">

                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card-w-title">
                            <i className="pi pi-search"></i> Option de recherche
                            <hr />
                            <div className="p-grid">
                                <div className="p-col-12 p-md-3">
                                    <Calendar value={this.state.pd1} onChange={(e) => this.setState({ pd1: e.value }, () => {
                                        this.get_liste_data();
                                    })} dateFormat="dd/mm/yy" />
                                </div>
                                <div className="p-col-12 p-md-3">
                                    <Calendar value={this.state.pd2} onChange={(e) => this.setState({ pd2: e.value }, () => {
                                        this.get_liste_data();
                                    })} dateFormat="dd/mm/yy" />
                                </div>
                                <div className="p-col-12 p-md-3">
                                    <Dropdown autoWidth={false} options={[{ label: 'Acteur', value: 'Tous' }].concat(this.state.listeprest)} optionLabel='label' placeholder='Acteur' filter={true} filterBy='label' filterMatchMode='contains' value={this.state.ridPrest} onChange={e => this.setState({ ridPrest: e.value }, () => {
                                        this.get_liste_data();
                                    })} />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <SplitButton label="Rechercher" style={{ width: '140px' }} onClick={(e) => this.get_liste_data()} icon="pi pi-search" model={this.state.items} />
                                </div>
                                <div className="p-col-12 p-md-1">
                                    <div className="p-inputgroup">
                                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>


                <DataTable value={this.state.liste_data} responsive={true} scrollable={true} scrollHeight="620px" csvSeparator=";"
                    ref={(el) => { this.dt = el; }} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers">
                    <Column field="idResEnq" header="No" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '8%' }} />
                    <Column field="dateResEnq" header="Date" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '10%' }} />
                    <Column field="nomPrest" header="Acteur" filter={true} filterMatchMode='contains' sortable={true} />
                    <Column field="regionSite" header="Region" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '10%' }} />
                    <Column field="prefectureSite" header="Prefecture" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '12%' }} />
                    <Column field="prc_existe" body={(rowData, column) => {
                        return <div>
                            <span style={{ fontWeight: 'bold' }}>{rowData.prc_existe} %</span>
                        </div>
                    }} header="Existe" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '10%' }} />
                    <Column field="prc_prest" body={(rowData, column) => {
                        return <div>
                            <span style={{ fontWeight: 'bold' }}>{rowData.prc_prest} %</span>
                        </div>
                    }}header="Prestation" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '12%' }} />
                    <Column field="prc_satisf" body={(rowData, column) => {
                        return <div>
                            <span style={{ fontWeight: 'bold' }}>{rowData.prc_satisf} %</span>
                        </div>
                    }} header="Satisfaction" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '12%' }} />
                    <Column field="score_percept" body={(rowData, column) => {
                        return <div>
                            <span style={{ fontWeight: 'bold' }}>{rowData.prc_existe} %</span>
                        </div>
                    }} header="Score" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '8%' }} />

                </DataTable>
            </div>
        );
    }
}