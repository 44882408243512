import React, { Component } from 'react';
import FbrService from '../service/FbrService';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { TabView, TabPanel } from 'primereact/tabview';
import { InputNumber } from 'primereact/inputnumber';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import { SplitButton } from 'primereact/splitbutton';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { RadioButton } from "primereact/radiobutton";

export class FormFacture extends Component {
    constructor() {
        super();
        this.state = {
            listeObj: [],
            listeprest: [],
            listeLobj: [],
            listeLobj_forAdding: [],
            listetypesrv: [],
            listeLobj_quantitatif: [],
            listeLobj_qualitatif: [],
            listeLobj_outil_indice: [],
            liste_quantitatif: [],
            liste_qualitatif: [],
            liste_outil_indice: [],
            liste_trace: [],
            idObj: 0,
            confDistanceObj: 'Oui',
            typeSaisie: 'MOIS',
            moisObj: '01',
            rMois: '',
            debPrest: '',
            rMois1: '',
            rMois2: '',
            rMois3: '',
            rTrim: 'T1',
            libM1: 'Jan',
            libM2: 'Fev',
            libM3: 'Mars',
            anneeObj: moment(new Date()).format('YYYY'),
            pointsFortsObj: '',
            pointsFaiblesObj: '',
            contraintesObj: '',
            obsObj: '',
            idPrest: null,
            etatObj: '',
            delaiPayObj: '',
            selectedObj: null,
            selectedLobj: null,
            selectedLobjQuant: null,
            selectedLobjQual: null,
            selectedLigne: null,
            debut: new Date(),
            fin: new Date(),
            idLobj: 0,
            libLobj: '',
            valEvalLobj: 0,
            valConfLobj: 0,
            valPrevLobj: 0,
            suspfraudeLobj: 'Non',
            obsLobj: '',
            puLobj: 0,
            maxLobj: '',
            idType: null,
            categorieType: 'Quantitative',
            total_mt_quantite: '0',
            liste_recom: [],
            liste_new_recom: [],
            selectedReco: null,
            libRec: '',
            cmtRec: '',
            dateRec: '',
            mtQuantObj: '0',
            scoreObj: '0',
            activeIndex: 0,
            valDhis2Lobj: 0,

            totalValidation: '0',
            liste_selectedFacture: [],
            ridPrest: null,
            retatObj: null,
            rstatutPay: null,

            visible_dlg_form_obj: false,
            visible_dlg_val_obj: false,
            visible_dlg_val_obj_bloc: false,
            visible_dlg_trace_obj: false,
            visible_dlg_detail_lobj: false,

            visible_dlg_lobj: false,
            visible_dlg_rec: false,

            visible_obj: false,
            visible_ligne: false,
            visible_rec: false,
            visible_form_new_obj: false,
            visible_dlgobsLobj: false,
            visible_dlglibRec: false,
            visible_dlgcmtRec: false,

            modif_lobj: false,
            modif_obj: false,
            modif_reco: false,
            showindicator: false,
            statutSaisie: 'NEW',
            nomBailleur: ''
        };

        this.edit_obj = this.edit_obj.bind(this);
        this.conf_delete_obj = this.conf_delete_obj.bind(this);
        this.edit_reco = this.edit_reco.bind(this);
        this.conf_delete_rec = this.conf_delete_rec.bind(this);

        this.edit_ligne = this.edit_ligne.bind(this);
        this.conf_delete_ligne = this.conf_delete_ligne.bind(this);

        this.conf_delete_new_rec = this.conf_delete_new_rec.bind(this);
        this.conf_delete_new_ligne = this.conf_delete_new_ligne.bind(this);

        this.obsLobjEditorQual = this.obsLobjEditorQual.bind(this);


        this.valConfLobjM2EditorQuant = this.valConfLobjM2EditorQuant.bind(this);
        this.valEvalLobjM2EditorQuant = this.valEvalLobjM2EditorQuant.bind(this);
        this.valEvalLobjM3EditorQuant = this.valEvalLobjM3EditorQuant.bind(this);
        this.valConfLobjM3EditorQuant = this.valConfLobjM3EditorQuant.bind(this);

        this.valEvalLobjEditorQual = this.valEvalLobjEditorQual.bind(this);
        this.valConfLobjEditorQual = this.valConfLobjEditorQual.bind(this);
        this.obsLobjEditorQuant = this.obsLobjEditorQuant.bind(this);
        this.valEvalLobjEditorQuant = this.valEvalLobjEditorQuant.bind(this);
        this.valConfLobjEditorQuant = this.valConfLobjEditorQuant.bind(this);
        this.valDhis2LobjEditorQuant = this.valDhis2LobjEditorQuant.bind(this);
        this.puLobjEditorQuant = this.puLobjEditorQuant.bind(this);
        this.inputTextareaEditorQual = this.inputTextareaEditorQual.bind(this);
        this.inputTextareaEditorQuant = this.inputTextareaEditorQuant.bind(this);
        this.inputNumberEditorQuant = this.inputNumberEditorQuant.bind(this);
        this.inputNumberEditorQual = this.inputNumberEditorQual.bind(this);
        this.inputTextareaEditor = this.inputTextareaEditor.bind(this);

        // this.calculateCustomerTotal=this.calculateCustomerTotal.bind(this);
        this.footerTemplate = this.footerTemplate.bind(this);
        this.footerTemplateLobj = this.footerTemplateLobj.bind(this);
        this.cmtRecEditor = this.cmtRecEditor.bind(this);
        this.libRecEditor = this.libRecEditor.bind(this);

        this.FbrService = new FbrService();
        this.onIndexTemplate = this.onIndexTemplate.bind(this);
        this.rangBody = this.rangBody.bind(this);
        this.valDhis2LobjM2EditorQuant = this.valDhis2LobjM2EditorQuant.bind(this);
        this.valDhis2LobjM3EditorQuant = this.valDhis2LobjM3EditorQuant.bind(this);


        this.valPrevLobjEditorQuant = this.valPrevLobjEditorQuant.bind(this);
        this.valPrevLobjM2EditorQuant = this.valPrevLobjM2EditorQuant.bind(this);
        this.valPrevLobjM3EditorQuant = this.valPrevLobjM3EditorQuant.bind(this);

        this.radioEditor = this.radioEditor.bind(this);


        this.show_dlg_detail_lobj = this.show_dlg_detail_lobj.bind(this);
    }

    onIndexTemplate(data, props) {
        return props.rowIndex + 1;
    }

    rangBody(rowData) {
        return <div>
            <span>{rowData.idType.rangType}</span>
        </div>
    }

    headerTemplate(data) {
        return (
            <React.Fragment>
                <span style={{ fontWeight: 'bold', color: '#106198' }}>{data.idType.idFamille.valCodif} - {data.idType.idFamille.libCodif}</span>
            </React.Fragment>
        );
    }


    footerTemplate(data) {
        let max = '0';
        let note = '0';
        let nd = '0';
        let td = '0';
        let lab = this.calculateCustomerTotal(data.idType.idFamille.idCodif);
        if (lab !== null && lab !== undefined && lab !== '') {
            max = lab.split('|')[0];
            note = lab.split('|')[1];
            nd = lab.split('|')[2];
            if (parseFloat(nd) > 0) {
                td = Math.round(100 * (parseFloat(nd) - parseFloat(note)) / parseFloat(nd));
            }
        }
        return (
            <React.Fragment>
                <td colSpan="2" style={{ textAlign: 'right', fontWeight: 'bold' }}>TOTAL </td>
                <td style={{ fontWeight: 'bold' }}>{max}</td>
                <td style={{ fontWeight: 'bold' }}>{nd}</td>
                <td style={{ fontWeight: 'bold' }}>{note}</td>
                <td style={{ fontWeight: 'bold' }}>{td} %</td>
            </React.Fragment>
        );
    }

    footerTemplateLobj(data) {
        let max = '0';
        let note = '0';
        let nd = '0';
        let lab = this.calculateCustomerTotalLobj(data.idType.idFamille.idCodif);
        if (lab !== null && lab !== undefined && lab !== '') {
            max = lab.split('|')[0];
            note = lab.split('|')[1];
            nd = lab.split('|')[2];
        }
        return (
            <React.Fragment>
                <td colSpan="2" style={{ textAlign: 'right', fontWeight: 'bold' }}>TOTAL </td>
                <td style={{ fontWeight: 'bold' }}>{max}</td>
                <td style={{ fontWeight: 'bold' }}>{nd}</td>
                <td style={{ fontWeight: 'bold' }}>{note}</td>
            </React.Fragment>
        );
    }

    calculateCustomerTotalLobj(idFamille) {
        let total = 0;
        let note = 0;
        let nd = 0;
        if (this.state.liste_qualitatif) {
            for (let row of this.state.listeLobj_qualitatif) {
                if (row.idType.idFamille.idCodif === idFamille) {
                    note = note + row.valConfLobj;
                    total = total + row.maxLobj;
                    nd = nd + row.valEvalLobj;
                }
            }
        }
        return total + '|' + note + '|' + nd;
    }

    calculateCustomerTotal(idFamille) {
        let total = 0;
        let note = 0;
        let nd = 0;
        if (this.state.liste_qualitatif) {
            for (let row of this.state.liste_qualitatif) {
                if (row.idType.idFamille.idCodif === idFamille) {
                    note = note + row.valConfLobj;
                    total = total + row.maxLobj;
                    nd = nd + row.valEvalLobj;
                }
            }
        }
        return total + '|' + note + '|' + nd;
    }


    radioEditor(props, field) {
        let list = ['Oui', 'Non'];
        return <div className="p-grid" style={{ marginTop: 1, marginLeft: 1 }}>
            {list.map((item) => {
                return (
                    <div key={item} style={{ padding: 5 }}>
                        <RadioButton inputId={item} name="item" value={item} onChange={(e) => {
                            this.onEditorValueChange(props, e.value);
                        }} checked={props.rowData.spfraudeLobj === item} />
                        <label htmlFor={item}> {item} </label>
                    </div>
                );
            })}
        </div>

    }


    obsLobjEditorQual(props) {
        return this.inputTextareaEditorQual(props, 'obsLobj');
    }


    inputNumberEditorQual(props, field) {
        return <InputNumber disabled={props.rowData['idObj']['disableSaisie'] == 'true' ? true : false} style={{ width: props.rowData['idType']['categorieType'] == 'Outil indice' ? '107px' : '105px' }} showButtons buttonLayout="horizontal" decrementButtonClassName="p-button-danger" incrementButtonClassName="p-button-success" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" max={props.rowData['maxLobj']} min={0} step={props.rowData['maxLobj']} value={props.rowData[field]} onChange={(e) => this.onEditorValueChange(props, e.value)} />
        //return <InputNumber value={props.rowData[field]} onChange={(e) => this.onEditorValueChangeQual(props, e.value)} />;
    }

    valEvalLobjEditorQual(props) {
        return this.inputNumberEditorQual(props, 'valEvalLobj');
    }
    valConfLobjEditorQual(props) {
        return this.inputNumberEditorQual(props, 'valConfLobj');
    }


    obsLobjEditorQuant(props) {
        return this.inputTextareaEditorQuant(props, 'obsLobj');
    }
    valEvalLobjEditorQuant(props) {
        return this.inputNumberEditorQuant(props, 'valEvalLobj');
    }
    valConfLobjEditorQuant(props) {
        return this.inputNumberEditorQuant(props, 'valConfLobj');
    }

    valEvalLobjM2EditorQuant(props) {
        return this.inputNumberEditorQuant(props, 'valEvalLobjM2');
    }
    valConfLobjM2EditorQuant(props) {
        return this.inputNumberEditorQuant(props, 'valConfLobjM2');
    }

    valEvalLobjM3EditorQuant(props) {
        return this.inputNumberEditorQuant(props, 'valEvalLobjM3');
    }
    valConfLobjM3EditorQuant(props) {
        return this.inputNumberEditorQuant(props, 'valConfLobjM3');
    }

    valDhis2LobjEditorQuant(props) {
        return this.inputNumberEditorQuant(props, 'valDhis2Lobj');
    }

    valDhis2LobjM2EditorQuant(props) {
        return this.inputNumberEditorQuant(props, 'valDhis2LobjM2');
    }

    valDhis2LobjM3EditorQuant(props) {
        return this.inputNumberEditorQuant(props, 'valDhis2LobjM3');
    }

    valPrevLobjEditorQuant(props) {
        return this.inputNumberEditorQuant(props, 'valPrevLobj');
    }

    valPrevLobjM2EditorQuant(props) {
        return this.inputNumberEditorQuant(props, 'valPrevLobjM2');
    }

    valPrevLobjM3EditorQuant(props) {
        return this.inputNumberEditorQuant(props, 'valPrevLobjM3');
    }

    puLobjEditorQuant(props) {
        return this.inputNumberEditorQuant(props, 'puLobj');
    }

    libRecEditor(props) {
        return this.inputTextareaEditor(props, 'libRec');
    }
    cmtRecEditor(props) {
        return this.inputTextareaEditor(props, 'cmtRec');
    }

    onEditorValueChangeQual(props, value) {
        let updateds = [...props.value];
        updateds[props.rowIndex][props.field] = value;
        this.setState({ liste_qualitatif: updateds });
    }

    onEditorValueChangeQuant(props, value) {
        let updateds = [...props.value];
        updateds[props.rowIndex][props.field] = value;
        this.setState({ liste_quantitatif: updateds }, () => {
            this.calculer_total(updateds);
        });
    }

    calculer_total(liste) {
        let _montant_brut = 0;
        let obj = {
            periodeObj: this.state.rMois,
            idPrest: { debPrest: this.state.debPrest }
        }
        let nbMoisObj = this.FbrService.getNbMois(obj);
        let applyTD = this.FbrService.checkTD(nbMoisObj);
        let nomBailleur = this.state.nomBailleur;
        if (liste != null) {
            for (var i = liste.length; i--;) {
                let volume = liste[i].valConfLobj + liste[i].valPrevLobj;
                let marge1 = liste[i].valEvalLobj > 0 ? 100 * (liste[i].valEvalLobj - liste[i].valConfLobj) / liste[i].valEvalLobj : 0;
                let marge2 = liste[i].valEvalLobjM2 > 0 ? 100 * (liste[i].valEvalLobjM2 - liste[i].valConfLobjM2) / liste[i].valEvalLobjM2 : 0;
                let marge3 = liste[i].valEvalLobjM3 > 0 ? 100 * (liste[i].valEvalLobjM3 - liste[i].valConfLobjM3) / liste[i].valEvalLobjM3 : 0;

                let mt1 = Math.abs(marge1) > (nomBailleur == 'ENABEL' && volume <= 50 ? 20 : 10) && applyTD == true ? 0 : liste[i].valConfLobj * liste[i].puLobj;
                let mt2 = Math.abs(marge2) > (nomBailleur == 'ENABEL' && volume <= 50 ? 20 : 10) && applyTD == true ? 0 : liste[i].valConfLobjM2 * liste[i].puLobj;
                let mt3 = Math.abs(marge3) > (nomBailleur == 'ENABEL' && volume <= 50 ? 20 : 10) && applyTD == true ? 0 : liste[i].valConfLobjM3 * liste[i].puLobj;

                liste[i].montant = mt1 + mt2 + mt3;

                _montant_brut += liste[i].montant;
            }
        }
        this.setState({ total_mt_quantite: this.FbrService.formaterValueSep(parseFloat(_montant_brut)) });
    }

    inputTextareaEditorQual(props, field) {
        return <InputTextarea rows={2} value={props.rowData[field]} onChange={(e) => this.onEditorValueChangeQual(props, e.target.value)} />;
    }
    inputTextareaEditorQuant(props, field) {
        return <InputTextarea rows={2} value={props.rowData[field]} onChange={(e) => this.onEditorValueChangeQuant(props, e.target.value)} />;
    }

    inputNumberEditorQuant(props, field) {
        return <InputNumber disabled={props.rowData['idObj']['disableSaisie'] == 'true' ? true : false} value={props.rowData[field]} onChange={(e) => this.onEditorValueChangeQuant(props, e.value)} />;
    }


    onEditorValueChange(props, value) {
        let updateds = [...props.value];
        updateds[props.rowIndex][props.field] = value;
        this.setState({ liste_rec: updateds });
    }

    inputTextareaEditor(props, field) {
        return <InputTextarea rows={2} type="text" value={props.rowData[field]} onChange={(e) => this.onEditorValueChange(props, e.target.value)} />;
    }

    componentDidMount() {
        this.get_liste_prestataire();
        this.get_liste_objectif();
    }

    exportCSV = () => {
        this.dt.exportCSV();
    }

    conf_print_facture(rowData) {
        this.FbrService.generer_edition_facture(rowData.idObj, 'PDF');
    }

    conf_print_facture_trim(rowData) {
        this.FbrService.generer_facture_trim(rowData.idPrest, rowData.anneeObj, rowData.trimestreObj);
    }

    conf_print_rapportage(rowData) {
        this.FbrService.generer_edition_rapportage(rowData.idObj, 'PDF');
    }

    show_trace(rowData) {
        this.setState({ visible_dlg_trace_obj: true, showindicator: true });
        this.FbrService.get_liste_trace_rapportage(rowData.idObj).then(data => this.setState({ liste_trace: data, showindicator: false }));
    }

    conf_valider_facture(rowData, etat) {
        if (rowData.etatObj == 'Validé') {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Cet rapportage est déja validé' });
            return;
        }
        if (rowData.etatObj == 'Annulé') {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Cet rapportage est déja annulé' });
            return;
        }
        let selectedPrest = this.FbrService.getPrest(this.state.listeprest, rowData.idPrest);
        this.setState({ selectedObj: rowData, etatObj: etat, nomBailleur: selectedPrest.bailleur }, () => {
            this.setState({ showindicator: true });
            this.setState({
                rMois: rowData.periodeObj,
                debPrest: rowData.debPrest
            });
            let obj = {
                periodeObj: rowData.periodeObj,
                idPrest: { debPrest: rowData.debPrest }
            }
            let nbMoisObj = this.FbrService.getNbMois(obj);
            let applyTD = this.FbrService.checkTD(nbMoisObj);
            let nomBailleur = this.state.nomBailleur;
            this.FbrService.get_liste_ligne_objectif(rowData.idObj).then(data => {
                this.setState({ showindicator: false });
                this.setState({ listeLobj: data }, () => {
                    if (data.length > 0) {
                        let liste_qual = data.filter(function (el) {
                            return el.idType.categorieType === 'Qualitative';
                        });
                        let liste_quant = data.filter(function (el) {
                            return el.idType.categorieType === 'Quantitative';
                        });
                        this.setState({ liste_qualitatif: liste_qual });
                        if (liste_quant !== null && liste_quant !== undefined) {
                            for (var i = liste_quant.length; i--;) {
                                let volume = liste_quant[i].valConfLobj + liste_quant[i].valPrevLobj;
                                let marge = liste_quant[i].valEvalLobj > 0 ? 100 * (liste_quant[i].valEvalLobj - liste_quant[i].valConfLobj) / liste_quant[i].valEvalLobj : 0;
                                let mt = Math.abs(marge) > (nomBailleur == 'ENABEL' && volume <= 50 ? 20 : 10) && applyTD == true ? 0 : liste_quant[i].valConfLobj * liste_quant[i].puLobj;
                                liste_quant[i].montant = mt;
                                liste_quant[i].td = Math.round(marge, 2);
                            }
                        }
                        this.setState({ liste_quantitatif: liste_quant });
                    }
                });
            })
            this.FbrService.get_liste_reco_byRapportage(rowData.idObj).then(data => {
                this.setState({ liste_new_recom: data});
            })
            this.setState({
                visible_dlg_val_obj: true,
                obsObj: rowData.obsObj,
                pointsFortsObj: rowData.pointsFortsObj,
                pointsFaiblesObj: rowData.pointsFaiblesObj,
                contraintesObj: rowData.contraintesObj
            });
        })
    }

    get_liste_reco(idObj) {
        this.FbrService.get_liste_reco_byRapportage(idObj).then(data => {
            this.setState({ liste_recom: data });
        })
    }

    get_liste_ligne_objectif(idObj) {
        this.setState({ showindicator: true });
        this.FbrService.get_liste_ligne_objectif(idObj).then(data => {
            this.setState({ showindicator: false });
            this.setState({ listeLobj: data }, () => {
                if (data.length > 0) {
                    let liste_qual = data.filter(function (el) {
                        return el.idType.categorieType === 'Qualitative';
                    });
                    let liste_quant = data.filter(function (el) {
                        return el.idType.categorieType === 'Quantitative';
                    });
                    this.setState({ listeLobj_qualitatif: liste_qual });
                    this.setState({ listeLobj_quantitatif: liste_quant });
                }
            });
        })
    }

    get_liste_ligne_objectif_for_evaluation(idPrest, rMois) {
        if (rMois !== '' && idPrest !== '') {
            this.setState({ showindicator: true });
            this.setState({ obsObj: '' });
            this.setState({ contraintesObj: '' });
            this.setState({ pointsFaiblesObj: '' });
            this.setState({ pointsFortsObj: '' });
            this.FbrService.get_liste_ligne_objectif_for_evaluation(idPrest, rMois).then(data => {
                this.setState({ showindicator: false });
                if (data.length > 0) {
                    let liste_qual = data.filter(function (el) {
                        return el.idType.categorieType === 'Qualitative';
                    });
                    let liste_quant = data.filter(function (el) {
                        return el.idType.categorieType === 'Quantitative';
                    });
                    let liste_indice = data.filter(function (el) {
                        return el.idType.categorieType === 'Outil indice';
                    });
                    let obj = data[0].idObj;
                    this.setState({ obsObj: obj.obsObj });
                    this.setState({ contraintesObj: obj.contraintesObj });
                    this.setState({ pointsFaiblesObj: obj.pointsFaiblesObj });
                    this.setState({ pointsFortsObj: obj.pointsFortsObj });
                    if (obj.idObj == 0) {
                        this.setState({ statutSaisie: 'NEW' });
                    } else {
                        this.setState({ statutSaisie: 'OLD' });
                    }
                    this.setState({ liste_qualitatif: liste_qual });
                    this.setState({ liste_quantitatif: liste_quant });
                    this.setState({ liste_outil_indice: liste_indice });
                    this.calculer_total(liste_quant);
                }
            })
        } else {
            this.setState({ liste_qualitatif: [] });
            this.setState({ liste_quantitatif: [] });
        }
    }

    get_liste_ligne_objectif_for_evaluation_trim(idPrest, rMois1, rMois2, rMois3) {
        if (this.state.rMois1 !== '' && idPrest !== '' && idPrest !== undefined && idPrest !== null) {
            if (this.state.rMois1.length == 7) {
                this.setState({ showindicator: true });
                this.setState({ obsObj: '' });
                this.setState({ contraintesObj: '' });
                this.setState({ pointsFaiblesObj: '' });
                this.setState({ pointsFortsObj: '' });
                this.FbrService.get_liste_ligne_objectif_for_evaluation_trim(idPrest, rMois1, rMois2, rMois3).then(data => {
                    this.setState({ showindicator: false });
                    if (data.length > 0) {
                        let liste_qual = data.filter(function (el) {
                            return el.idType.categorieType === 'Qualitative';
                        });
                        let liste_quant = data.filter(function (el) {
                            return el.idType.categorieType === 'Quantitative';
                        });
                        let liste_indice = data.filter(function (el) {
                            return el.idType.categorieType === 'Outil indice';
                        });
                        this.setState({ liste_qualitatif: liste_qual });
                        this.setState({ liste_quantitatif: liste_quant });
                        this.setState({ liste_outil_indice: liste_indice });
                        let obj = data[0].idObj;
                        this.setState({ obsObj: obj.obsObj });
                        this.setState({ contraintesObj: obj.contraintesObj });
                        this.setState({ pointsFaiblesObj: obj.pointsFaiblesObj });
                        this.setState({ pointsFortsObj: obj.pointsFortsObj });
                        if (obj.idObj == 0) {
                            this.setState({ statutSaisie: 'NEW' });
                        } else {
                            this.setState({ statutSaisie: 'OLD' });
                        }
                        this.calculer_total(liste_quant);
                    }
                })
            }
        } else {
            this.setState({ liste_qualitatif: [] });
            this.setState({ liste_quantitatif: [] });
            this.setState({ liste_outil_indice: [] });
            this.setState({ contraintesObj: '' });
            this.setState({ pointsFaiblesObj: '' });
            this.setState({ pointsFortsObj: '' });
        }
    }

    get_liste_ligne_objectif_for_adding(idPrest, rMois, rCategorie) {
        this.setState({ showindicator: true });
        this.FbrService.get_liste_ligne_objectif_for_adding(idPrest, rMois).then(data => {
            this.setState({ showindicator: false });
            if (data !== undefined) {
                if (data.length > 0) {
                    let liste = data.filter(function (el) {
                        return el.idType.categorieType == rCategorie;
                    });
                    this.setState({ listeLobj_forAdding: liste });
                }
            }
        })
    }

    itemTemplateRech(option) {
        if (!option.value) {
            return option.label;
        }
        else {
            return (
                <div className="p-grid" >
                    <div className="p-col-12 p-md-8">
                        <label>{option.label}</label>
                    </div>
                    <div className="p-col-12 p-md-4">
                        <label>{option.typePrest}</label>
                    </div>
                </div>
            );
        }
    }

    itemTemplate(option) {
        if (!option.value) {
            return option.label;
        }
        else {
            return (
                <div className="p-grid" style={{ padding: 10 }}>
                    <div className="p-col-12 p-md-8">
                        <label>{option.label}</label>
                    </div>
                    <div className="p-col-12 p-md-4">
                        <label>{option.typePrest}</label>
                    </div>
                </div>
            );
        }
    }

    get_liste_prestataire() {
        this.setState({ showindicator: true });
        this.FbrService.get_liste_prestataire_eval().then(data => {
            this.setState({ showindicator: false });
            if (data !== null && data !== undefined && data !== '') {
                if (data.length > 0) {
                    if (this.FbrService.get_userconnected().profilUser == 'OBC') {
                        let idp = this.FbrService.get_userconnected().idPrest.idPrestParent;
                        let listeFiltre = data.filter(function (el) {
                            return el.idPrest == idp;
                        });
                        let liste = listeFiltre.map(x => {
                            return { value: x.idPrest, label: x.nomPrest, typePrest: x.typePrest.libCodif, debPrest: x.debPrest, bailleur: x.idBailleur.libCodif }
                        });
                        this.setState({ listeprest: liste });
                    } else {
                        let liste = data.map(x => {
                            return { value: x.idPrest, label: x.nomPrest, typePrest: x.typePrest.libCodif, debPrest: x.debPrest, bailleur: x.idBailleur.libCodif }
                        });
                        this.setState({ listeprest: liste });
                    }
                }
            }
        });
    }

    get_liste_objectif() {
        let spd1 = moment(this.state.debut).format('MM/YYYY');
        let spd2 = moment(this.state.fin).format('MM/YYYY');
        let ridpres = 'Tous';
        let retat = 'Tous';
        let rstatut = 'Tous';
        if (this.state.ridPrest !== undefined && this.state.ridPrest !== null) {
            ridpres = this.state.ridPrest;
        }
        if (this.state.retatObj !== undefined && this.state.retatObj !== null) {
            retat = this.state.retatObj;
        }
        if (this.state.rstatutPay !== undefined && this.state.rstatutPay !== null) {
            rstatut = this.state.rstatutPay;
        }
        this.setState({ showindicator: true });
        this.FbrService.get_liste_objectif(spd1, spd2, ridpres, retat, rstatut).then(data => this.setState({ listeObj: data, showindicator: false }));
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_objectif();
            this.get_liste_objectif();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }


    resultat_ligne(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_ligne();
            this.get_liste_ligne_objectif(this.state.selectedObj.idObj);
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    resultat_reco(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_reco();
            this.get_liste_reco(this.state.selectedObj.idObj);
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    annule_reco() {
        this.setState({
            libRec: '',
            cmtRec: '',
            modif_reco: null,
        });
    }

    edit_obj(data) {
        if (data != null && data != undefined) {
            if (data.etatObj == 'Validé' || data.etatObj == 'Annulé') {
                this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Vous ne pouvez plus modifier ce rapportage' });
                return;
            }
            /*  if(this.FbrService.get_userconnected().profilUser!=='Administrateur' && this.FbrService.get_userconnected().profilUser!=='Coordinateur'){
                 if(rowData.isDateJ==false){
                     this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Vous ne pouvez plus modifier ce rapportage' });
                     return;
                 }
             } */
            this.setState({
                confDistanceObj: data.confDistanceObj,
                delaiPayObj: new Date(moment(data.delaiPayObj, 'DD/MM/YYYY').format('YYYY-MM-DD')),
                moisObj: new Date(moment('01/' + data.periodeObj, 'DD/MM/YYYY').format('YYYY-MM-DD')),
                obsObj: data.obsObj,
                pointsFortsObj: data.pointsFortsObj,
                pointsFaiblesObj: data.pointsFaiblesObj,
                contraintesObj: data.contraintesObj,
                idPrest: data.idPrest,
                confDistanceObj: data.confDistanceObj,
                modif_obj: true,
                visible_dlg_form_obj: true,
                selectedObj: data
            });
        }
    }

    edit_reco(data) {
        if (data != null && data != undefined) {
            this.setState({
                idRec: data.idRec,
                libRec: data.libRec,
                cmtRec: data.cmtRec,
                etatRec: data.etatRec,
                modif_reco: true,
                visible_dlg_rec: true,
                selectedReco: data
            });
        }
    }

    edit_ligne(data) {
        if (data != null && data != undefined) {
            if (data.idObj.etatObj == 'Validé') {
                this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Ce rapportage est déja validé' });
                return;
            }
            this.setState({
                idLobj: data.idLobj,
                libLobj: data.libLobj,
                valPrevLobj: data.valPrevLobj,
                valEvalLobj: data.valEvalLobj,
                valConfLobj: data.valConfLobj,
                valDhis2Lobj: data.valDhis2Lobj,
                noteLobj: data.noteLobj,
                obsLobj: data.obsLobj,
                puLobj: data.puLobj,
                maxLobj: data.maxLobj,
                modif_lobj: true,
                visible_dlg_lobj: true,
                selectedLobj: data
            });


            this.setState({ categorieType: data.idType.categorieType });
            if (data.idType.categorieType == 'Quantitative') {
                this.setState({ selectedLobjQuant: data });
            } else {
                this.setState({ selectedLobjQual: data });
            }
        }
    }

    annule_objectif() {
        this.setState({ idObj: 0 });
        this.setState({
            obsObj: '',
            pointsFortsObj: '',
            pointsFaiblesObj: '',
            contraintesObj: '',
            idPrest: null,
            liste_qualitatif: [],
            liste_quantitatif: [],
            liste_new_recom: [],
            retatObj: 'Tous',
            total_mt_quantite: '0'
        });
        if (this.state.modif_obj == true) {
            this.setState({ visible_dlg_form_obj: false });
        }
        this.setState({ modif_obj: false, visible_dlg_val_obj: false, visible_dlg_val_obj_bloc: false });
        this.forceUpdate();
    }

    annule_rec() {
        this.setState({ idRec: 0 });
        this.setState({
            libRec: '',
            cmtRec: '',
        });
        this.setState({ modif_reco: false });
        this.forceUpdate();
    }

    annule_ligne() {
        this.setState({
            idLobj: 0,
            libLobj: '',
            valPrevLobj: 0,
            valEvalLobj: 0,
            valConfLobj: 0,
            noteLobj: 0,
            obsLobj: '',
            puLobj: 0,
            maxLobj: 0,
            valDhis2Lobj: 0,
            idType: null
        });
        if (this.state.modif_lobj == true) {
            this.setState({ visible_dlg_lobj: false });
        }
        this.forceUpdate();
    }

    enreg_objectif() {
        if (this.state.idPrest == "" || this.state.idPrest == null) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner un acteur' });
        } else if (this.state.moisObj == "" || this.state.moisObj == null) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la période' });
        } else {
            this.setState({ showindicator: true });
            if (this.state.modif_obj == false) {
                if (this.state.liste_qualitatif.length == 0 && this.state.liste_quantitatif.length == 0) {
                    this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez renseigner les indicateurs' });
                    return;
                }
                let userconnected = this.FbrService.get_userconnected();
                let val = moment(this.state.moisObj).format('MM/YYYY') + "";
                let error = false;
                let listegnle = [];
                if (this.state.statutSaisie == 'NEW') {
                    let obj = {
                        idObj: 0,
                        moisObj: val.split("/")[0],
                        anneeObj: val.split("/")[1],
                        obsObj: this.state.obsObj,
                        pointsFortsObj: this.state.pointsFortsObj,
                        pointsFaiblesObj: this.state.pointsFaiblesObj,
                        contraintesObj: this.state.contraintesObj,
                        idPrest: { idPrest: this.state.idPrest },
                        idUser: { idUser: userconnected.idUser },
                        iduser: userconnected.idUser,
                        confDistanceObj: this.state.confDistanceObj,
                        idEtabl: userconnected.idEtabl,
                        etatObj: 'En attente'
                    }
                    listegnle = this.state.liste_quantitatif.concat(this.state.liste_qualitatif);
                    listegnle = listegnle.concat(this.state.liste_outil_indice);
                    for (var i = listegnle.length; i--;) {
                        listegnle[i].idObj = obj;
                        listegnle[i].iduser = userconnected.idUser;
                        listegnle[i].color = 'black';
                        if (listegnle[i].idType.categorieType == 'Qualitative') {
                            if (parseFloat(listegnle[i].valConfLobj) > parseFloat(listegnle[i].maxLobj)) {
                                error = true;
                                listegnle[i].color = 'red';
                            }
                        }
                    }
                } else {
                    listegnle = this.state.liste_quantitatif.concat(this.state.liste_qualitatif);
                    listegnle = listegnle.concat(this.state.liste_outil_indice);
                    for (var i = listegnle.length; i--;) {
                        listegnle[i].idObj.obsObj = this.state.obsObj;
                        listegnle[i].idObj.iduser = userconnected.idUser;
                        listegnle[i].idObj.pointsFortsObj = this.state.pointsFortsObj;
                        listegnle[i].idObj.pointsFaiblesObj = this.state.pointsFaiblesObj;
                        listegnle[i].idObj.contraintesObj = this.state.contraintesObj;
                        listegnle[i].idObj.idEtabl = userconnected.idEtabl;
                        listegnle[i].iduser = userconnected.idUser;
                        listegnle[i].color = 'black';
                        if (listegnle[i].idType.categorieType == 'Qualitative') {
                            if (parseFloat(listegnle[i].valConfLobj) > parseFloat(listegnle[i].maxLobj)) {
                                error = true;
                                listegnle[i].color = 'red';
                            }
                        } else {
                            if (parseFloat(listegnle[i].valConfLobj) > parseFloat(listegnle[i].valDhis2Lobj) || parseFloat(listegnle[i].valEvalLobj) > parseFloat(listegnle[i].valDhis2Lobj)) {
                                error = true;
                                listegnle[i].color = 'red';
                            }
                        }
                    }
                }


                if (error == true) {
                    this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez vérifier les notes et les quantités SNIS' });
                    this.setState({ showindicator: false });
                    return;
                }

                this.FbrService.enreg_ligne_objectif_batch({ listeLobj: listegnle, listeRec: this.state.liste_new_recom }).then(data => this.resultat(data.code, data.contenu));


            } else {
                let val = moment(this.state.moisObj).format('MM/YYYY') + "";

                let obj = this.state.selectedObj;
                obj.moisObj = val.split("/")[0];
                obj.anneeObj = val.split("/")[1];
                let date = moment(this.state.delaiPayObj).format('DD/MM/YYYY');
                if (date === 'Invalid date') {
                    date = null;
                }
                obj.delaiPayObj = date;
                obj.obsObj = this.state.obsObj;
                obj.pointsFortsObj = this.state.pointsFortsObj;
                obj.idPrest = { idPrest: this.state.idPrest };
                obj.pointsFaiblesObj = this.state.pointsFaiblesObj;
                obj.confDistanceObj = this.state.confDistanceObj;
                obj.contraintesObj = this.state.contraintesObj;
                obj.idUser = { idUser: obj.idUser };
                this.FbrService.enreg_objectif(obj).then(data => this.resultat(data.code, data.contenu));
            }
        }
    }

    enreg_objectif_trim() {
        if (this.state.idPrest == "" || this.state.idPrest == null) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner un acteur' });
        } else if (this.state.rTrim == "" || this.state.rTrim == null) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner le trimestre' });
        } else {
            this.setState({ showindicator: true });
            if (this.state.liste_qualitatif.length == 0 && this.state.liste_quantitatif.length == 0) {
                this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez renseigner les indicateurs' });
                return;
            }
            let userconnected = this.FbrService.get_userconnected();
            let listegnle = [];
            let error = false;
            if (this.state.statutSaisie == 'NEW') {
                let obj = {
                    idObj: 0,
                    moisObj: this.state.moisObj,
                    anneeObj: this.state.anneeObj,
                    obsObj: this.state.obsObj,
                    pointsFortsObj: this.state.pointsFortsObj,
                    pointsFaiblesObj: this.state.pointsFaiblesObj,
                    contraintesObj: this.state.contraintesObj,
                    idPrest: { idPrest: this.state.idPrest },
                    idUser: { idUser: userconnected.idUser },
                    iduser: userconnected.idUser,
                    confDistanceObj: this.state.confDistanceObj,
                    idEtabl: userconnected.idEtabl,
                    etatObj: 'En attente'
                }
                listegnle = this.state.liste_quantitatif.concat(this.state.liste_qualitatif);
                listegnle = listegnle.concat(this.state.liste_outil_indice);
                for (var i = listegnle.length; i--;) {
                    listegnle[i].idObj = obj;
                    listegnle[i].iduser = userconnected.idUser;
                    listegnle[i].color = 'black';
                    if (listegnle[i].idType.categorieType == 'Qualitative') {
                        if (parseFloat(listegnle[i].valConfLobj) > parseFloat(listegnle[i].maxLobj)) {
                            error = true;
                            listegnle[i].color = 'red';
                        }
                    }
                }
            } else {
                listegnle = this.state.liste_quantitatif.concat(this.state.liste_qualitatif);
                listegnle = listegnle.concat(this.state.liste_outil_indice);
                for (var i = listegnle.length; i--;) {
                    listegnle[i].idObj.obsObj = this.state.obsObj;
                    listegnle[i].idObj.iduser = userconnected.idUser;
                    listegnle[i].idObj.pointsFortsObj = this.state.pointsFortsObj;
                    listegnle[i].idObj.pointsFaiblesObj = this.state.pointsFaiblesObj;
                    listegnle[i].idObj.contraintesObj = this.state.contraintesObj;
                    listegnle[i].idObj.idEtabl = userconnected.idEtabl;
                    listegnle[i].iduser = userconnected.idUser;
                    listegnle[i].color = 'black';
                    if (listegnle[i].idType.categorieType == 'Qualitative') {
                        if (parseFloat(listegnle[i].valConfLobj) > parseFloat(listegnle[i].maxLobj)) {
                            error = true;
                            listegnle[i].color = 'red';
                        }
                    }
                }
            }
            if (error == true) {
                this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez vérifier les notes de qualité' });
                this.setState({ showindicator: false });
                return;
            }
            this.FbrService.enreg_ligne_objectif_batch_trim({ listeLobjTrim: listegnle, listeRec: this.state.liste_new_recom }).then(data => this.resultat(data.code, data.contenu));
        }
    }

    enreg_obs() {
        this.state.selectedLobj.obsLobj = this.state.obsLobj;
        this.setState({ visible_dlgobsLobj: false });
    }

    enreg_libRec() {
        if (this.state.libRec == "" || this.state.libRec == null) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir la recommandation' });
            return;
        }
        this.state.selectedReco.libRec = this.state.libRec;
        this.setState({ visible_dlglibRec: false });
    }

    enreg_cmtRec() {
        this.state.selectedReco.cmtRec = this.state.cmtRec;
        this.setState({ visible_dlgcmtRec: false });
    }

    enreg_reco() {
        if (this.state.selectedObj != null && this.state.selectedObj != undefined) {
            if (this.state.libRec == "" || this.state.libRec == null) {
                this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir la recommandation' });
                return;
            }
            if (this.state.modif_reco == false) {
                let reco = {
                    idRec: 0,
                    libRec: this.state.libRec,
                    cmtRec: this.state.cmtRec,
                    etatRec: "Non soldé",
                    idObj: { idObj: this.state.selectedObj.idObj }
                }
                this.setState({ showindicator: true });
                this.FbrService.enreg_recommandation(reco).then(data => this.resultat_reco(data.code, data.contenu));
            } else {
                if (this.state.libRec == "" || this.state.libRec == null) {
                    this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir la recommandation' });
                    return;
                }
                this.setState({ showindicator: true });
                var ligne = this.state.selectedReco;
                ligne.libRec = this.state.libRec;
                ligne.cmtRec = this.state.cmtRec;
                ligne.etatRec = this.state.etatRec;
                this.FbrService.enreg_recommandation(ligne).then(data => this.resultat_reco(data.code, data.contenu));
            }
        } else {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez selectionner un rapportage' });
        }
    }

    valider_rapportage() {
        if (this.state.visible_dlg_val_obj_bloc == true) {
            if (this.state.liste_selectedFacture == null || this.state.liste_selectedFacture == undefined || this.state.liste_selectedFacture.length <= 0) {
                this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner les rapportages à valider' });
            } else {
                let ids = this.state.liste_selectedFacture.map(x => {
                    return x.idObj
                });
                this.setState({ showindicator: true });
                this.FbrService.valider_objectif_bloc(ids, this.state.etatObj).then(data => {
                    this.resultat(data.code, data.contenu);
                });
            }
        } else {
            let userconnected = this.FbrService.get_userconnected();
            let listegnle = this.state.liste_quantitatif.concat(this.state.liste_qualitatif);
            listegnle = listegnle.concat(this.state.liste_outil_indice);
            let error = false;
            for (var i = listegnle.length; i--;) {
                listegnle[i].idObj.pointsFaiblesObj = this.state.pointsFaiblesObj;
                listegnle[i].idObj.obsObj = this.state.obsObj;
                listegnle[i].idObj.etatObj = this.state.etatObj;
                listegnle[i].idObj.idUserVal = { idUser: userconnected.idUser };
                listegnle[i].idObj.pointsFortsObj = this.state.pointsFortsObj;
                listegnle[i].iduser = userconnected.idUser;
                listegnle[i].color = 'black';
                if (listegnle[i].idType.categorieType == 'Qualitative') {
                    if (parseFloat(listegnle[i].valConfLobj) > parseFloat(listegnle[i].maxLobj)) {
                        error = true;
                        listegnle[i].color = 'red';
                    }
                }
            }
            if (error == true) {
                this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez vérifier les notes de qualité' });
            } else {
                this.setState({ showindicator: true });
                this.FbrService.enreg_ligne_objectif_batch({ listeLobj: listegnle, listeRec: this.state.liste_new_recom }).then(data => {
                    this.resultat(data.code, data.contenu);
                    if (data.code == 200) {
                        this.state.selectedObj.etatObj = this.state.etatObj;
                    }
                });
            }
        }
    }

    enreg_ligne() {
        if (this.state.selectedObj != null && this.state.selectedObj != undefined) {
            if (this.state.modif_lobj == false) {
                if (this.state.listeLobj.length <= 0) {
                    this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez renseigner les indicateurs' }); return;
                }
                let userconnected = this.FbrService.get_userconnected();
                for (var i = this.state.listeLobj.length; i--;) {
                    this.state.listeLobj[i].idObj = this.state.selectedObj;
                    this.state.listeLobj[i].iduser = userconnected.idUser;
                }
                this.setState({ showindicator: true });
                this.FbrService.enreg_ligne_objectif_batch2(this.state.listeLobj).then(data => this.resultat_ligne(data.code, data.contenu));
            } else {
                if (this.state.libLobj == "") {
                    this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
                    return;
                }
                if (this.state.selectedObj.etatObj == "Validé") {
                    this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Impossible de modifier cette ligne car déja validé' });
                    return;
                }
                this.setState({ showindicator: true });
                var ligne = this.state.selectedLobj;
                ligne.libLobj = this.state.libLobj;
                ligne.valEvalLobj = this.state.valEvalLobj;
                ligne.valConfLobj = this.state.valConfLobj;
                ligne.valPrevLobj = this.state.valPrevLobj;
                ligne.obsLobj = this.state.obsLobj;
                ligne.puLobj = this.state.puLobj;
                ligne.maxLobj = this.state.maxLobj;
                ligne.valDhis2Lobj = this.state.valDhis2Lobj;
                this.FbrService.enreg_ligne_objectif(ligne).then(data => this.resultat_ligne(data.code, data.contenu));
            }
        } else {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez selectionner un rapportage' });
        }
    }

    show_dlg_detail_lobj(data) {
        this.get_liste_ligne_objectif(data.idObj);
        this.get_liste_reco(data.idObj);
        this.setState({ mtQuantObj: this.FbrService.formaterValueSep(parseFloat(data.mtQuantObj)) });
        this.setState({ scoreObj: data.scoreObj });
        this.setState({ selectedObj: data }, () => {
            this.setState({ visible_dlg_detail_lobj: true });
        });
        let selectedPrest = this.FbrService.getPrest(this.state.listeprest, data.idPrest);
        this.setState({
            liste_quantitatif: [],
            liste_qualitatif: [],
            debPrest: selectedPrest.debPrest,
            nomBailleur: selectedPrest.bailleur
        });
    }


    actionTemplate_obj(rowData) {
        let items = [
            {
                label: 'Détails',
                icon: 'pi pi-search',
                command: (e) => {
                    this.show_dlg_detail_lobj(rowData);
                }
            },
            {
                label: 'Supprimer',
                icon: 'pi pi-times',
                command: (e) => {
                    this.conf_delete_obj(rowData);
                }
            },
            {
                label: 'Modifier',
                icon: 'pi pi-pencil',
                command: (e) => {
                    this.edit_obj(rowData);
                }
            },
            {
                label: 'Facture mensuelle',
                icon: 'pi pi-file',
                command: (e) => {
                    this.conf_print_rapportage(rowData);
                }
            },
            {
                label: 'Facture trimestrielle',
                icon: 'pi pi-file',
                command: (e) => {
                    this.conf_print_facture_trim(rowData);
                }
            },
            {
                label: 'Historique',
                icon: 'pi pi-search',
                command: (e) => {
                    this.show_trace(rowData);
                }
            }
        ]

        let cl = 'p-button-info';
        let label = rowData.idObj + '';
        let etat = rowData.etatObj;
        if (etat === 'Validé') {
            cl = 'p-button-success';
        } else if (etat === 'Annulé') {
            cl = 'p-button-danger';
        } else {
            cl = 'p-button-warning';
            if (this.FbrService.get_userconnected().profilUser == 'Administrateur' || this.FbrService.get_userconnected().profilUser == 'Coordinateur' || this.FbrService.get_userconnected().profilUser == 'AGFA') {
                items.push(
                    {
                        label: 'Valider',
                        icon: 'pi pi-check',
                        command: (e) => {
                            this.conf_valider_facture(rowData, 'Validé');
                        }
                    }
                );
                items.push(
                    {
                        label: 'Annuler',
                        icon: 'pi pi-times',
                        command: (e) => {
                            this.conf_valider_facture(rowData, 'Annulé');
                        }
                    }
                )
            }
        }
        return <SplitButton title='Modifier' label={label} className={cl} onClick={(e) => this.show_dlg_detail_lobj(rowData)} icon="pi pi-search" style={{ width: 130 }} model={items}></SplitButton>
    }

    actionTemplate_ligne(rowData) {
        if (rowData.idType.categorieType == 'Qualitative') {
            return <Button title='Supprimer' onClick={() => this.conf_delete_new_ligne(rowData, 'QUAL')} className="p-button-danger" style={{ width: 25, height: 25 }} icon="pi pi-times" />
        } else {
            return <Button title='Supprimer' onClick={() => this.conf_delete_new_ligne(rowData, 'QUAN')} className="p-button-danger" style={{ width: 25, height: 25 }} icon="pi pi-times" />
        }
    }

    actionTemplate_obs(rowData) {
        return <a onClick={() => {
            this.setState({ obsLobj: rowData.obsLobj, visible_dlgobsLobj: true });
        }}>
            <label>
                {rowData.obsLobj == '' || rowData.obsLobj == null || rowData.obsLobj == undefined ?
                    'Ajouter une Observation'
                    :
                    rowData.obsLobj
                }
            </label>
        </a>
    }

    actionTemplate_cmtRec(rowData) {
        return <a onClick={() => {
            this.setState({ cmtRec: rowData.cmtRec, visible_dlgcmtRec: true });
        }}>
            <label>
                {rowData.cmtRec == '' || rowData.cmtRec == null || rowData.cmtRec == undefined ?
                    'Ajouter un commentaire'
                    :
                    rowData.cmtRec
                }
            </label>
        </a>
    }


    actionTemplate_libRec(rowData) {
        return <a onClick={() => {
            this.setState({ libRec: rowData.libRec, visible_dlglibRec: true });
        }}>
            <label>
                {rowData.libRec == '' || rowData.libRec == null || rowData.libRec == undefined ?
                    'Ajouter une recommandation'
                    :
                    rowData.libRec
                }
            </label>
        </a>
    }


    actionTemplate_new_rec(rowData) {
        return <Button onClick={() => this.conf_delete_new_rec(rowData)} className="p-button-danger" style={{ width: 25, height: 25 }} icon="pi pi-times" />
    }


    actionTemplateLobj(rowData) {
        return <div className="p-grid" style={{ width: 45 }}>
            <div className="p-col"><Button title='Modifier' disabled={rowData.idObj.etatObj == 'Validé'} onClick={() => this.edit_ligne(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            {/* <div className="p-col"><Button title='Supprimer' disabled={rowData.idObj.etatObj == 'Validé'} onClick={() => this.conf_delete_ligne(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -20 }} icon="pi pi-times" /></div> */}
        </div>
    }

    actionTemplate_rec(rowData) {
        return <div className="p-grid" style={{ width: 90 }}>
            <div className="p-col"><Button title='Modifier' disabled={rowData.idObj.etatObj == 'Validé'} onClick={() => this.edit_reco(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button title='Supprimer' disabled={rowData.idObj.etatObj == 'Validé'} onClick={() => this.conf_delete_rec(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -20 }} icon="pi pi-times" /></div>
        </div>
    }

    conf_delete_ligne(rowData) {
        if (rowData != null && rowData != undefined) {
            if (rowData.idType.categorieType == 'Qualitative') {
                this.setState({ selectedLobjQual: rowData });
            } else {
                this.setState({ selectedLobjQuant: rowData });
            }
            this.setState({ selectedLobj: rowData });
            this.setState({ msg: rowData.libLobj });
            this.setState({ visible_ligne: true });
        }
        this.forceUpdate();
    }

    delete_objectif() {
        if (this.state.selectedObj == null || this.state.selectedObj == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.FbrService.delete_objectif(this.state.selectedObj).then(data => this.resultat(data.code, data.contenu));
        }
    }

    delete_reco() {
        if (this.state.selectedReco == null || this.state.selectedReco == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.FbrService.delete_recommandation(this.state.selectedReco).then(data => this.resultat_reco(data.code, data.contenu));
        }
    }

    delete_ligne_objectif() {
        if (this.state.selectedLobj == null || this.state.selectedLobj == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.FbrService.delete_ligne_objectif(this.state.selectedLobj).then(data => this.resultat_ligne(data.code, data.contenu));
        }
    }

    conf_delete_rec(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedReco: data });
            this.setState({ msg: data.libRec });
            this.setState({ visible_rec: true });
        }
    }

    conf_delete_obj(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedObj: data });
            this.setState({ msg: data.periodeObj + ' de ' + data.nomPrest });
            this.setState({ visible_obj: true });
        }
    }



    conf_delete_new_ligne(data, cible) {
        if (cible == 'QUAL') {
            for (var i = this.state.liste_qualitatif.length; i--;) {
                if (data.idType.idType == this.state.liste_qualitatif[i].idType.idType) {
                    this.state.liste_qualitatif.splice(i, 1);
                    break;
                }
            }
        } else {
            for (var i = this.state.listeLobj_quantitatif.length; i--;) {
                if (data.idType.idType == this.state.listeLobj_quantitatif[i].idType.idType) {
                    this.state.listeLobj_quantitatif.splice(i, 1);
                    break;
                }
            }
        }
        this.forceUpdate();
    }


    conf_delete_new_rec(data) {
        for (var i = this.state.liste_new_recom.length; i--;) {
            if (data.libRec == this.state.liste_new_recom[i].libRec) {
                this.state.liste_new_recom.splice(i, 1);
                break;
            }
        }
        this.forceUpdate();
    }

    add_reco() {
        if (this.state.libRec == "") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir la recommendation' });
        } else {
            Array.prototype.insert = function (index) {
                this.splice.apply(this, [index, 0].concat(this.slice.call(arguments, 1)));
            };
            this.state.liste_new_recom.insert(this.state.liste_new_recom.length + 1,
                {
                    idRec: 0,
                    libRec: this.state.libRec,
                    cmtRec: this.state.cmtRec,
                    etatRec: 'Non soldé',
                    no: this.state.liste_new_recom.length + 1
                }
            );
            this.setState({
                libRec: '',
                cmtRec: ''
            })
            this.forceUpdate();
        }
    }

    render() {
        let items = [
            {
                label: 'Rapportage mensuel',
                icon: 'pi pi-pencil',
                command: (e) => {
                    this.setState({ visible_form_new_obj: true, typeSaisie: 'MOIS' });
                }
            },
            {
                label: 'Rapportage trimestriel',
                icon: 'pi pi-pencil',
                command: (e) => {
                    this.setState({ visible_form_new_obj: true, typeSaisie: 'TRIM' });
                }
            }

        ]

        if (this.FbrService.get_userconnected().profilUser == 'Administrateur' || this.FbrService.get_userconnected().profilUser == 'Coordinateur') {
            items.push(
                {
                    label: 'Validation en bloc',
                    icon: 'pi pi-check-square',
                    command: (e) => {
                        this.setState({
                            visible_dlg_val_obj_bloc: true,
                            retatObj: 'En attente',
                            rstatutPay: 'Tous',
                            etatObj: 'Validé'
                        }, () => {
                            this.get_liste_objectif();
                        });
                    }
                }
            );
            items.push(
                {
                    label: 'Annulation en bloc',
                    icon: 'pi pi-times',
                    command: (e) => {
                        this.setState({
                            visible_dlg_val_obj_bloc: true,
                            retatObj: 'En attente',
                            rstatutPay: 'Tous',
                            etatObj: 'Annulé'
                        }, () => {
                            this.get_liste_objectif();
                        });
                    }
                }
            );
        }


        let header_new = <SplitButton title='Nouveau rapportage' label='Nouveau' className="p-button-help" onClick={(e) => {
            this.setState({ visible_form_new_obj: true, typeSaisie: 'MOIS' });
        }} icon="pi pi-plus" style={{ width: 150 }} model={items}></SplitButton>

        let headerGroup = <ColumnGroup>
            <Row>
                {/* <Column header="#" rowSpan={2} /> */}
                <Column header="N°" rowSpan={2} />
                <Column header="Indicateur" rowSpan={2} />
                <Column header="Quantités SNIS" colSpan={4} />
                <Column header="Quantités declarées" colSpan={4} />
                <Column header="Quantités validées" colSpan={4} />
                <Column header="Prix" rowSpan={2} />
                <Column header="Montant" rowSpan={2} />
                <Column header="Observation" rowSpan={2} />
            </Row>
            <Row>
                <Column header={this.state.libM1} field="valDhis2Lobj" />
                <Column header={this.state.libM2} field="valDhis2LobjM2" />
                <Column header={this.state.libM3} field="valDhis2LobjM3" />
                <Column header="TT" />

                <Column header={this.state.libM1} field="valEvalLobj" />
                <Column header={this.state.libM2} field="valEvalLobjM2" />
                <Column header={this.state.libM3} field="valEvalLobjM3" />
                <Column header="TT" />

                <Column header={this.state.libM1} field="valConfLobj" />
                <Column header={this.state.libM2} field="valConfLobjM2" />
                <Column header={this.state.libM3} field="valConfLobjM3" />
                <Column header="TT" />
            </Row>
        </ColumnGroup>;

        /*  <Button label="Nouveau" title='Nouveau rapportage' icon="pi pi-plus" style={{ width: 150 }} className="p-button-help" onClick={(e) => this.setState({ modif_obj: false }, () => {
             this.setState({ visible_form_new_obj: true });
         })} /> */

        let disable = true;
        if (this.state.selectedObj !== null && this.state.selectedObj !== undefined) {
            if (this.state.selectedObj.etatObj == 'Validé') {
                disable = true;
            } else {
                disable = false;
            }
        }
        let btn_add_ind_qual = <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <label style={{ fontWeight: 'bold', color: 'green', fontSize: 18 }}>{this.state.scoreObj} %</label>
            <Button label="Ajouter" disabled={disable} icon="pi pi-plus" style={{ width: 120 }} className="p-button-info" onClick={(e) => this.setState({ visible_dlg_lobj: true, modif_lobj: false, categorieType: 'Qualitative' }, () => {
                this.get_liste_ligne_objectif_for_adding(this.state.selectedObj.idPrest, this.state.selectedObj.periodeObj, 'Qualitative');
            })} />
        </div>

        let btn_add_ind_quant = <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <label style={{ fontWeight: 'bold', color: 'green', fontSize: 18 }}>{this.state.mtQuantObj}</label>
            <Button label="Ajouter" disabled={disable} icon="pi pi-plus" style={{ width: 120 }} className="p-button-info" onClick={(e) => this.setState({ visible_dlg_lobj: true, modif_lobj: false, categorieType: 'Quantitative' }, () => {
                this.get_liste_ligne_objectif_for_adding(this.state.selectedObj.idPrest, this.state.selectedObj.periodeObj, 'Quantitative');
            })} />
        </div>

        let btn_add_ligne_reco = <Button label="Ajouter" disabled={disable} icon="pi pi-plus" style={{ width: 120 }} className="p-button-info" onClick={(e) => this.setState({ visible_dlg_rec: true, modif_reco: false })} />

        const dialogFooterObj = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible_obj: false });
                    this.delete_objectif();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible_obj: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        const dialogFooterLigne = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible_ligne: false });
                    this.delete_ligne_objectif();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible_ligne: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        const dialogFooterReco = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible_rec: false });
                    this.delete_reco();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible_rec: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        const footerGroup = <ColumnGroup>
            <Row>
                <Column footer="TOTAL" colSpan={6} footerStyle={{ textAlign: 'center', fontWeight: 'bold' }} />
                <Column footer={this.state.totalValidation} style={{ color: 'green', fontWeight: 'bold' }} />
            </Row>
        </ColumnGroup>;
        return (
            <div className="card p-fluid" style={{}}>
                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Confirmation" visible={this.state.visible_obj} style={{ width: '250px' }} modal={true} footer={dialogFooterObj} onHide={() => this.setState({ visible_obj: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                <Dialog header="Confirmation" visible={this.state.visible_ligne} style={{ width: '250px' }} modal={true} footer={dialogFooterLigne} onHide={() => this.setState({ visible_ligne: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                <Dialog header="Confirmation" visible={this.state.visible_rec} style={{ width: '250px' }} modal={true} footer={dialogFooterReco} onHide={() => this.setState({ visible_rec: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>


                <Dialog header="Observation" visible={this.state.visible_dlgobsLobj} style={{ width: '500px' }} modal={true} onHide={(e) => this.setState({ visible_dlgobsLobj: false })}>
                    <div className="card p-fluid">
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-12">
                                <label >Observation  </label>
                                <InputTextarea rows={2} value={this.state.obsLobj} onChange={(e) => this.setState({ obsLobj: e.target.value })} />
                            </div>
                        </div>
                        <center>
                            <div className="p-grid" style={{ width: 300, marginTop: -10 }}>
                                <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={() => {
                                    this.setState({ visible_dlgobsLobj: false });
                                }} /></div>
                                <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={() => this.enreg_obs()} /></div>
                            </div>
                        </center>
                    </div>
                </Dialog>


                <Dialog header="Commentaire" visible={this.state.visible_dlgcmtRec} style={{ width: '500px' }} modal={true} onHide={(e) => this.setState({ visible_dlgcmtRec: false })}>
                    <div className="card p-fluid">
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-12">
                                <label >Commentaire  </label>
                                <InputTextarea rows={2} value={this.state.cmtRec} onChange={(e) => this.setState({ cmtRec: e.target.value })} />
                            </div>
                        </div>
                        <center>
                            <div className="p-grid" style={{ width: 300, marginTop: -10 }}>
                                <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={() => {
                                    this.setState({ visible_dlgcmtRec: false });
                                }} /></div>
                                <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={() => this.enreg_cmtRec()} /></div>
                            </div>
                        </center>
                    </div>
                </Dialog>


                <Dialog header="Recommandation" visible={this.state.visible_dlglibRec} style={{ width: '500px' }} modal={true} onHide={(e) => this.setState({ visible_dlglibRec: false })}>
                    <div className="card p-fluid">
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-12">
                                <label >Recommandation * </label>
                                <InputTextarea rows={2} value={this.state.libRec} onChange={(e) => this.setState({ libRec: e.target.value })} />
                            </div>
                        </div>
                        <center>
                            <div className="p-grid" style={{ width: 300, marginTop: -10 }}>
                                <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={() => {
                                    this.setState({ visible_dlglibRec: false });
                                }} /></div>
                                <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={() => this.enreg_libRec()} /></div>
                            </div>
                        </center>
                    </div>
                </Dialog>


                <Dialog header="Historique" visible={this.state.visible_dlg_trace_obj} style={{ width: '70%' }} modal={true} onHide={(e) => this.setState({ visible_dlg_trace_obj: false })} >
                    <center>
                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                    </center>
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="card card-w-title" style={{ height: 480 }}>
                                <DataTable value={this.state.liste_trace} scrollable={true} scrollHeight="350px"
                                    responsive={true} style={{ marginBottom: 0 }} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers">
                                    <Column field="dateTrace" header="Date" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                    <Column field="actionTrace" header="Action" style={{ width: '18%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                    <Column field="idUser.loginUser" header="Utilisateur" style={{ width: '15%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                    <Column field="detailTrace" header="Détail" filter={true} filterMatchMode='contains' sortable={true} />
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </Dialog>


                <Dialog header="Ajout/Modification recommandation" visible={this.state.visible_dlg_rec} style={{ width: '500px' }} modal={true} onHide={(e) => this.setState({ visible_dlg_rec: false })}>
                    <div className="card p-fluid">
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-12">
                                <label >Recommandation * </label>
                                <InputTextarea rows={2} value={this.state.libRec} onChange={(e) => this.setState({ libRec: e.target.value })} />
                            </div>
                            <div className="p-field p-col-12 p-md-12">
                                <label>Statut *</label>
                                <Dropdown value={this.state.etatRec} options={[{ label: 'Soldé', value: 'Soldé' }, { label: 'Non soldé', value: 'Non soldé' }]} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ etatRec: e.target.value })} autoWidth={false} />
                            </div>
                            <div className="p-field p-col-12 p-md-12">
                                <label >Observation  </label>
                                <InputTextarea rows={2} value={this.state.cmtRec} onChange={(e) => this.setState({ cmtRec: e.target.value })} />
                            </div>
                        </div>

                        <center>
                            <div className="p-grid" style={{ width: 300, marginTop: -10 }}>
                                <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={() => {
                                    this.setState({ visible_dlg_rec: false });
                                }} /></div>
                                <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={() => this.enreg_reco()} /></div>

                            </div>
                            {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                        </center>
                    </div>
                </Dialog>


                <Dialog header={this.state.selectedObj !== undefined && this.state.selectedObj !== null ? this.state.etatObj == 'Validé' ? "Validation" : "Annulation" + " rapportage de " + this.state.selectedObj.periodeObj + ' de ' + this.state.selectedObj.nomPrest : ''} visible={this.state.visible_dlg_val_obj} style={{ width: '90%' }} modal={true} onHide={(e) => this.setState({ visible_dlg_val_obj: false })}>
                    <div className="p-fluid">
                        <center>
                            {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                        </center>
                        <TabView style={{ marginBottom: 20 }}>
                            <TabPanel header="Indicateurs quantitatifs">
                                <DataTable value={this.state.liste_quantitatif}
                                    responsive={true} scrollable={true} scrollHeight="260px" selectionMode="single" className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                                    onRowClick={e => this.setState({ selectedLobj: e.data })} onRowSelect={e => this.setState({ selectedLobj: e.data })}
                                    selection={this.state.selectedLobj}>
                                    <Column field="Index" header="N°" body={this.onIndexTemplate} style={{ width: '4%' }} />
                                    <Column field="libLobj" header="Indicateur" filter={true} filterMatchMode='contains' sortable={true} body={(rowData, column) => {
                                        return <div>
                                            <span style={{ color: rowData.color }}>{rowData.libLobj}</span>
                                        </div>
                                    }} />
                                    <Column field="valEvalLobj" header="QD" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '7%' }} editor={this.valEvalLobjEditorQuant} />
                                    <Column field="valConfLobj" header="QV" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '6%' }} editor={this.valConfLobjEditorQuant} />
                                    <Column field="puLobj" header="Prix" filter={true} filterMatchMode='contains' body={(rowData, column) => {
                                        return <div>
                                            <span style={{ color: '#000000' }}>{this.FbrService.formaterValueSep(parseFloat(rowData.puLobj))}</span>
                                        </div>
                                    }} sortable={true} style={{ width: '8%' }} editor={this.puLobjEditorQuant} />
                                    <Column header="TD" filter={true} filterMatchMode='contains' body={(rowData, column) => {
                                        let obj = {
                                            periodeObj: this.state.selectedObj.periodeObj,
                                            idPrest: { debPrest: this.state.selectedObj.debPrest }
                                        }
                                        let nbMoisObj = this.FbrService.getNbMois(obj);
                                        let applyTD = this.FbrService.checkTD(nbMoisObj);
                                        let nomBailleur = this.state.nomBailleur;
                                        let volume = rowData.valConfLobj + rowData.valPrevLobj;
                                        return <div>
                                            <span style={{ color: rowData.td > (nomBailleur == 'ENABEL' && volume <= 50 ? 20 : 10) && applyTD == true ? 'red' : 'black' }}>{rowData.td} %</span>
                                        </div>
                                    }} field="td" sortable={true} style={{ width: '6%' }} />
                                    <Column field="montant" body={(rowData, column) => {
                                        return <div>
                                            <span style={{ fontWeight: 'bold' }}>{this.FbrService.formaterValueSep(parseFloat(rowData.montant))}</span>
                                        </div>
                                    }} header="Montant" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '10%' }} />
                                    <Column field="obsLobj" header="Observation" body={this.actionTemplate_obs.bind(this)} filter={true} filterMatchMode='contains' sortable={true} style={{ width: '20%' }} />
                                </DataTable>
                            </TabPanel>
                            <TabPanel header="Indicateurs qualitatifs">
                                <DataTable value={this.state.liste_qualitatif}
                                    responsive={true} scrollable={true} scrollHeight="250px" selectionMode="single" className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                                    onRowClick={e => this.setState({ selectedLobj: e.data })} onRowSelect={e => this.setState({ selectedLobjQual: e.data })}
                                    selection={this.state.selectedLobj} style={{ marginTop: -8 }}
                                    rowGroupHeaderTemplate={this.headerTemplate} rowGroupFooterTemplate={this.footerTemplateLobj} rowGroupMode="subheader" groupField="idType.idFamille.libCodif">
                                    <Column field="Index" header="N°" body={(rowData, column) => {
                                        return <div>
                                            <span>{rowData.idType.idFamille.valCodif}.{rowData.idType.rangType}</span>
                                        </div>
                                    }} style={{ width: '4%' }} />
                                    <Column field="libLobj" body={(rowData, column) => {
                                        return <div>
                                            <span style={{ color: rowData.color }}>{rowData.libLobj}</span>
                                        </div>
                                    }} header="Indicateur" filter={true} filterMatchMode='contains' />
                                    <Column field="maxLobj" header="Max" body={(rowData, column) => {
                                        return <div>
                                            <span style={{ color: 'red', fontWeight: 'bold' }}>{rowData.maxLobj}</span>
                                        </div>
                                    }} filter={true} filterMatchMode='contains' style={{ width: '6%' }} />
                                    <Column field="valEvalLobj" body={(rowData, props) => {
                                        return <div>
                                            <InputNumber style={{ width: '105px', color: rowData.valEvalLobj < rowData.valConfLobj ? 'red' : 'black' }}
                                                onChange={(e) => {
                                                    this.state.liste_qualitatif[props.rowIndex].valEvalLobj = e.value;
                                                }}
                                                disabled={props.rowData.idObj.disableSaisie == 'true' ? true : false}
                                                showButtons buttonLayout="horizontal" decrementButtonClassName="p-button-secondary" incrementButtonClassName="p-button-success" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" max={rowData.maxLobj['maxLobj']} min={0} step={rowData.maxLobj} value={rowData.valEvalLobj} />
                                        </div>
                                    }} header="Point déclaré" filter={true} filterMatchMode='contains' style={{ width: '12%' }} editor={this.valEvalLobjEditorQual} />
                                    <Column field="valConfLobj" body={(rowData, props) => {
                                        return <div>
                                            <InputNumber style={{ width: '105px', color: rowData.valEvalLobj < rowData.valConfLobj ? 'red' : 'black' }}
                                                onChange={(e) => {
                                                    this.state.liste_qualitatif[props.rowIndex].valConfLobj = e.value;
                                                }}
                                                disabled={props.rowData.idObj.disableSaisie == 'true' ? true : false}
                                                showButtons buttonLayout="horizontal" decrementButtonClassName="p-button-secondary" incrementButtonClassName="p-button-success" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" max={rowData.maxLobj['maxLobj']} min={0} step={rowData.maxLobj} value={rowData.valEvalLobj} />
                                        </div>
                                    }} header="Point validé" filter={true} filterMatchMode='contains' style={{ width: '12%' }} editor={this.valConfLobjEditorQual} />
                                    <Column header="TD" filter={true} filterMatchMode='contains' body={(rowData, column) => {
                                        let marge = 0;
                                        if (rowData.maxLobj == 0) {
                                            marge = 0;
                                        } else {
                                            marge = 100 * (rowData.maxLobj - rowData.valConfLobj) / rowData.maxLobj
                                        }
                                        marge = Math.abs(parseFloat(marge));
                                        marge = Math.round(marge, 2);
                                        let nbMoisObj = this.state.selectedObj.nbMoisObj;
                                        let applyTD = this.FbrService.checkTD(nbMoisObj);
                                        return <div>
                                            <span style={{ color: marge > 100 && applyTD == true ? 'red' : 'black' }}>{marge} %</span>
                                        </div>
                                    }} style={{ width: '6%' }} />
                                    <Column field="obsLobj" header="Observation" body={this.actionTemplate_obs.bind(this)} filter={true} filterMatchMode='contains' style={{ width: '15%' }} />
                                </DataTable>
                                {/* <DataTable value={this.state.liste_qualitatif}
                                    responsive={true} scrollable={true} scrollHeight="260px" selectionMode="single" className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                                    onRowClick={e => this.setState({ selectedLobj: e.data })} onRowSelect={e => this.setState({ selectedLobj: e.data })}
                                    responsive={true} selection={this.state.selectedLobj}
                                    rowGroupHeaderTemplate={this.headerTemplate} rowGroupFooterTemplate={this.footerTemplate} rowGroupMode="subheader" groupField="idType.idFamille.libCodif" sortField="idType.idFamille.libCodif" sortOrder={1}>
                                    <Column field="Index" header="N°" body={(rowData, column) => {
                                        return <div>
                                            <span>{rowData.idType.idFamille.valCodif}.{rowData.idType.rangType}</span>
                                        </div>
                                    }} style={{ width: '4%' }} />
                                    <Column field="libLobj" body={(rowData, column) => {
                                        return <div>
                                            <span style={{ color: rowData.color }}>{rowData.libLobj}</span>
                                        </div>
                                    }} header="Indicateur" filter={true} filterMatchMode='contains'  />
                                    <Column field="maxLobj" header="Max" body={(rowData, column) => {
                                        return <div>
                                            <span style={{ color: 'red', fontWeight: 'bold' }}>{rowData.maxLobj}</span>
                                        </div>
                                    }} filter={true} filterMatchMode='contains'  style={{ width: '6%' }} />
                                    <Column field="valEvalLobj" body={(rowData, props) => {
                                        return <div>
                                            <InputNumber style={{ width: '105px', color: rowData.valEvalLobj < rowData.valConfLobj ? 'red' : 'black' }}
                                                onChange={(e) => {
                                                    this.state.liste_qualitatif[props.rowIndex].valEvalLobj = e.value;
                                                }}
                                                disabled={props.rowData.idObj.disableSaisie == 'true' ? true : false}
                                                showButtons buttonLayout="horizontal" decrementButtonClassName="p-button-secondary" incrementButtonClassName="p-button-success" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" max={rowData.maxLobj['maxLobj']} min={0} step={rowData.maxLobj} value={rowData.valEvalLobj} />
                                        </div>
                                    }} header="Point déclaré" filter={true} filterMatchMode='contains' style={{ width: '12%' }} editor={this.valEvalLobjEditorQual} />
                                    <Column field="valConfLobj" body={(rowData, props) => {
                                        return <div>
                                            <InputNumber style={{ width: '105px', color: rowData.valEvalLobj < rowData.valConfLobj ? 'red' : 'black' }}
                                                onChange={(e) => {
                                                    this.state.liste_qualitatif[props.rowIndex].valEvalLobj = e.value;
                                                }}
                                                disabled={props.rowData.idObj.disableSaisie == 'true' ? true : false}
                                                showButtons buttonLayout="horizontal" decrementButtonClassName="p-button-secondary" incrementButtonClassName="p-button-success" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" max={rowData.maxLobj['maxLobj']} min={0} step={rowData.maxLobj} value={rowData.valEvalLobj} />
                                        </div>
                                    }} header="Point validé" filter={true} filterMatchMode='contains' style={{ width: '12%' }} editor={this.valConfLobjEditorQual} />
                                    <Column header="TD" filter={true} filterMatchMode='contains' body={(rowData, column) => {
                                        let marge = 0;
                                        if (rowData.maxLobj == 0) {
                                            marge = 0;
                                        } else {
                                            marge = 100 * (rowData.maxLobj - rowData.valConfLobj) / rowData.maxLobj
                                        }
                                        marge = Math.abs(parseFloat(marge));
                                        marge = Math.round(marge, 2);
                                        let nbMoisObj = this.state.selectedObj.nbMoisObj;
                                        let applyTD = this.FbrService.checkTD(nbMoisObj);
                                        return <div>
                                            <span style={{ color: marge > 100 && applyTD == true ? 'red' : 'black' }}>{marge} %</span>
                                        </div>
                                    }} style={{ width: '6%' }} />
                                    <Column field="obsLobj" header="Observation" body={this.actionTemplate_obs.bind(this)} filter={true} filterMatchMode='contains'  style={{ width: '15%' }} />
                                </DataTable> */}
                            </TabPanel>
                            <TabPanel header="Recommandations">
                                <DataTable value={this.state.liste_new_recom}
                                    responsive={true} scrollable={true} scrollHeight="260px" selectionMode="single" className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                                    onRowClick={e => this.setState({ selectedReco: e.data })} onRowSelect={e => this.setState({ selectedReco: e.data })}
                                    selection={this.state.selectedReco}>
                                    <Column field="libRec" header="Recommandation" filter={true} filterMatchMode='contains' sortable={true} body={this.actionTemplate_libRec.bind(this)} />
                                    <Column field="cmtRec" header="Commentaire" filter={true} filterMatchMode='contains' sortable={true} body={this.actionTemplate_cmtRec.bind(this)} />
                                </DataTable>
                            </TabPanel>
                        </TabView>


                        <div className="p-grid">
                            <div className="p-col-12 p-md-3">
                                <div className="p-field">
                                    <span className="p-float-label">
                                        <InputTextarea id='obsObj' rows={2} value={this.state.obsObj} onChange={(e) => this.setState({ obsObj: e.target.value })} />
                                        <label htmlFor="obsObj">Observation </label>
                                    </span>
                                </div>
                            </div>
                            <div className="p-col-12 p-md-3">
                                <div className="p-field">
                                    <span className="p-float-label">
                                        <InputTextarea id='pointsFortsObj' rows={2} value={this.state.pointsFortsObj} onChange={(e) => this.setState({ pointsFortsObj: e.target.value })} />
                                        <label htmlFor="pointsFortsObj">Points forts </label>
                                    </span>
                                </div>
                            </div>
                            <div className="p-col-12 p-md-3">
                                <div className="p-field">
                                    <span className="p-float-label">
                                        <InputTextarea id='pointsFaiblesObj' rows={2} value={this.state.pointsFaiblesObj} onChange={(e) => this.setState({ pointsFaiblesObj: e.target.value })} />
                                        <label htmlFor="pointsFaiblesObj">Points faibles </label>
                                    </span>
                                </div>
                            </div>
                            <div className="p-col-12 p-md-3">
                                <div className="p-field">
                                    <span className="p-float-label">
                                        <InputTextarea id='pointsFaiblesObj' rows={2} value={this.state.contraintesObj} onChange={(e) => this.setState({ contraintesObj: e.target.value })} />
                                        <label htmlFor="pointsFaiblesObj">Contraintes </label>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <center>
                            <div className="p-grid" style={{ width: 500, marginTop: -30 }}>
                                <div className="p-col"><Button label="Fermer" icon="pi pi-ban" onClick={() => {
                                    this.setState({ visible_dlg_val_obj: false });
                                }} /></div>
                                <div className="p-col"><Button style={{ width: 200 }} label={this.state.etatObj == 'Validé' ? "Valider le rapportage" : "Annuler le rapportage"} icon="pi pi-check" onClick={() => this.valider_rapportage()} className={this.state.etatObj == 'Validé' ? "p-button-success" : "p-button-danger"} /></div>
                            </div>
                        </center>

                    </div>
                </Dialog>


                <Dialog visible={this.state.visible_dlg_val_obj_bloc} style={{ width: '85%' }} header={this.state.etatObj == 'Validé' ? "Validation en bloc" : "Annulation en bloc"} modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_val_obj_bloc: false })}>
                    <div className="card" style={{ height: 500, marginTop: -20 }}>
                        <div className="p-grid">
                            <div className="p-col-12">
                                <div className="card-w-title">
                                    <i className="pi pi-search"></i> Option de recherche
                                    <hr />
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-2">
                                            <Calendar value={this.state.debut} onChange={(e) => this.setState({ debut: e.value }, () => {
                                                this.setState({
                                                    retatObj: 'En attente',
                                                    rstatutPay: 'Tous'
                                                }, () => {
                                                    this.get_liste_objectif();
                                                })
                                            })} view="month" dateFormat="mm/yy" yearNavigator yearRange="2021:2070" />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Calendar value={this.state.fin} onChange={(e) => this.setState({ fin: e.value }, () => {
                                                this.setState({
                                                    retatObj: 'En attente',
                                                    rstatutPay: 'Tous'
                                                }, () => {
                                                    this.get_liste_objectif();
                                                })
                                            })} view="month" dateFormat="mm/yy" yearNavigator yearRange="2021:2070" />
                                        </div>
                                        <div className="p-col-12 p-md-3">
                                            <Dropdown autoWidth={false} options={[{ label: 'Acteur', value: 'Tous' }].concat(this.state.listeprest)} optionLabel='label' placeholder='Acteur' filter={true} filterBy='label,typePrest' filterMatchMode='contains' value={this.state.ridPrest} onChange={e => this.setState({ ridPrest: e.value }, () => {
                                                this.setState({
                                                    retatObj: 'En attente',
                                                    rstatutPay: 'Tous'
                                                }, () => {
                                                    this.get_liste_objectif();
                                                })
                                            })} itemTemplate={this.itemTemplateRech} panelStyle={{ width: '500px' }} />
                                        </div>

                                        <div className="p-col-12 p-md-3">
                                            <Button icon='pi pi-search' style={{ width: 150 }} label='Rechercher' onClick={() => {
                                                this.setState({
                                                    retatObj: 'En attente',
                                                    rstatutPay: 'Tous'
                                                }, () => {
                                                    this.get_liste_objectif();
                                                })
                                            }} />
                                            {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <DataTable value={this.state.listeObj} footerColumnGroup={footerGroup}
                            responsive={true} scrollable className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                            onSelectionChange={e => this.setState({ liste_selectedFacture: e.value }, () => {
                                let total = 0;
                                if (this.state.liste_selectedFacture != null && this.state.liste_selectedFacture != undefined) {
                                    if (this.state.liste_selectedFacture.length > 0) {
                                        for (var i = this.state.liste_selectedFacture.length; i--;) {
                                            total += this.state.liste_selectedFacture[i].mtRestObj;
                                        }
                                    }
                                }
                                this.setState({ totalValidation: this.FbrService.formaterValueSep(parseFloat(total)) });
                            })
                            } selection={this.state.liste_selectedFacture} scrollHeight="250px">
                            <Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
                            <Column field="idObj" filter={true} filterMatchMode='contains' header="Facture" sortable={true} style={{ width: '10%' }} />
                            <Column field="periodeObj" filter={true} filterMatchMode='contains' header="Mois" sortable={true} style={{ width: '12%' }} />
                            <Column field="nomPrest" filter={true} filterMatchMode='contains' header="Acteur" sortable={true} />
                            <Column field="typePrest" filter={true} filterMatchMode='contains' header="Type" sortable={true} style={{ width: '15%' }} />
                            <Column field="trimestreObj" filter={true} filterMatchMode='contains' header="Trimestre" style={{ width: '10%' }} sortable={true} />
                            <Column field="mtObj" body={(rowData, column) => {
                                return <div>
                                    <span style={{ color: 'green', fontWeight: 'bold' }}>{this.FbrService.formaterValueSep(parseFloat(rowData.mttObj))}</span>
                                </div>
                            }} filter={true} filterMatchMode='contains' header="Montant" sortable={true} style={{ width: '15%' }} />
                        </DataTable>
                    </div>

                    <center>
                        <div className="p-grid" style={{ width: 300, marginBottom: -20 }}>
                            <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={() => {
                                this.setState({ visible_dlg_val_obj_bloc: false, liste_selectedFacture: [] });
                            }} /></div>
                            <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={() => this.valider_rapportage()} /></div>
                            {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                        </div>
                        <br />
                    </center>
                </Dialog>


                <Dialog header={this.state.modif_lobj == true ? "Modification indicateur" : "Ajouter des indicateurs"} visible={this.state.visible_dlg_lobj} style={{ width: this.state.modif_lobj == true ? '60%' : '80%' }} modal={true} onHide={(e) => this.setState({ visible_dlg_lobj: false })}>
                    {this.state.modif_lobj == true ?
                        <div className="card p-fluid" style={{ marginBottom: -10 }}>
                            <div className="p-grid">
                                <div className="p-col-12 p-md-3">
                                    <label >Désignation *</label>
                                </div>
                                <div className="p-col-12 p-md-9">
                                    <InputTextarea rows={2} disabled={true} value={this.state.libLobj} onChange={(e) => this.setState({ libLobj: e.target.value })} />
                                </div>
                                {this.state.categorieType == 'Quantitative' ?
                                    <div className="p-col-3 p-md-3">
                                        <label >Quantité attendue *</label>
                                    </div>
                                    :
                                    null
                                }
                                {this.state.categorieType == 'Quantitative' ?
                                    <div className="p-col-12 p-md-9">
                                        <InputNumber min={0} value={this.state.valPrevLobj} onChange={(e) => this.setState({ valPrevLobj: e.value })} />
                                    </div>
                                    :
                                    null
                                }
                                {this.state.categorieType == 'Quantitative' ?
                                    <div className="p-col-3 p-md-3">
                                        <label >Quantité DHIS2 *</label>
                                    </div>
                                    :
                                    null
                                }
                                {this.state.categorieType == 'Quantitative' ?
                                    <div className="p-col-12 p-md-9">
                                        <InputNumber min={0} value={this.state.valDhis2Lobj} onChange={(e) => this.setState({ valDhis2Lobj: e.value })} />
                                    </div>
                                    :
                                    null
                                }
                                {this.state.categorieType == 'Quantitative' ?
                                    <div className="p-col-3 p-md-3">
                                        <label >Quantité déclarée *</label>
                                    </div>
                                    :
                                    null
                                }
                                {this.state.categorieType == 'Quantitative' ?
                                    <div className="p-col-12 p-md-9">
                                        <InputNumber min={0} value={this.state.valEvalLobj} onChange={(e) => this.setState({ valEvalLobj: e.value })} />
                                    </div>
                                    :
                                    null
                                }
                                {this.state.categorieType == 'Quantitative' ?
                                    <div className="p-col-12 p-md-3">
                                        <label >Quantité validée *</label>
                                    </div>
                                    :
                                    <div className="p-col-12 p-md-3">
                                        <label >Note *</label>
                                    </div>
                                }
                                {this.state.categorieType == 'Quantitative' ?
                                    <div className="p-col-12 p-md-9">
                                        <InputNumber min={0} value={this.state.valConfLobj} onChange={(e) => this.setState({ valConfLobj: e.value })} />
                                    </div>
                                    :
                                    <div className="p-col-12 p-md-9">
                                        <InputNumber min={0} value={this.state.valConfLobj} onChange={(e) => this.setState({ valConfLobj: e.value })} />
                                    </div>
                                }
                                {this.state.categorieType == 'Quantitative' ?
                                    <div className="p-col-12 p-md-3">
                                        <label >Prix unitaire *</label>
                                    </div>
                                    :
                                    <div className="p-col-12 p-md-3">
                                        <label >Note maximale *</label>
                                    </div>
                                }
                                {this.state.categorieType == 'Quantitative' ?
                                    <div className="p-col-12 p-md-9">
                                        <InputNumber min={0} value={this.state.puLobj} disabled={true} onChange={(e) => this.setState({ puLobj: e.value })} />
                                    </div>
                                    :
                                    <div className="p-col-12 p-md-9">
                                        <InputNumber min={0} value={this.state.maxLobj} disabled={true} onChange={(e) => this.setState({ maxLobj: e.value })} />
                                    </div>
                                }
                                <div className="p-col-12 p-md-3">
                                    <label >Observation </label>
                                </div>
                                <div className="p-col-12 p-md-9">
                                    <InputTextarea rows={2} value={this.state.obsLobj} onChange={(e) => this.setState({ obsLobj: e.target.value })} />
                                </div>
                            </div>

                            <center>
                                <div className="p-grid" style={{ width: 300, marginTop: -10 }}>
                                    <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={() => {
                                        this.setState({ visible_dlg_lobj: false });
                                    }} /></div>
                                    <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={() => this.enreg_ligne()} /></div>

                                </div>
                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                            </center>
                        </div>
                        :
                        <>
                            <div className="card p-fluid" style={{ marginBottom: -10 }}>
                                {this.state.categorieType == 'Quantitative' ?
                                    <DataTable value={this.state.listeLobj_forAdding}
                                        responsive={true} scrollable={true} scrollHeight="410px" selectionMode="single" className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                                        onRowClick={e => this.setState({ selectedLobj: e.data, selectedLobjQuant: e.data })} onRowSelect={e => this.setState({ selectedLobj: e.data, selectedLobjQuant: e.data })}
                                        selection={this.state.selectedLobjQuant}>
                                        <Column header="#" body={this.actionTemplate_ligne.bind(this)} style={{ width: '5%' }} />
                                        <Column field="Index" header="N°" body={this.onIndexTemplate} style={{ width: '5%' }} />
                                        <Column field="libLobj" header="Indicateur" filter={true} filterMatchMode='contains' sortable={true} />
                                        <Column field="puLobj" header="Prix" filter={true} filterMatchMode='contains' body={(rowData, column) => {
                                            return <div>
                                                <span style={{ color: '#000000', fontWeight: 'bold' }}>{this.FbrService.formaterValueSep(parseFloat(rowData.puLobj))}</span>
                                            </div>
                                        }} sortable={true} style={{ width: '10%' }} editor={this.puLobjEditorQuant} />
                                        <Column field="valEvalLobj" header="QD" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '8%' }} editor={this.valEvalLobjEditorQuant} />
                                        <Column field="valConfLobj" header="QV" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '8%' }} editor={this.valConfLobjEditorQuant} />
                                        <Column field="obsLobj" header="Observation" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '25%' }} editor={this.obsLobjEditorQuant} />
                                    </DataTable>
                                    :
                                    <DataTable value={this.state.listeLobj_forAdding}
                                        responsive={true} scrollable={true} scrollHeight="410px" selectionMode="single" className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                                        onRowClick={e => this.setState({ selectedLobjQual: e.data, selectedLobj: e.data })} onRowSelect={e => this.setState({ selectedLobjQual: e.data, selectedLobj: e.data })}
                                        selection={this.state.selectedLobjQual} editMode="cell">
                                        <Column header="#" body={this.actionTemplate_ligne.bind(this)} style={{ width: '5%' }} />
                                        <Column field="Index" header="N°" body={this.onIndexTemplate} style={{ width: '5%' }} />
                                        <Column field="libLobj" header="Indicateur" filter={true} filterMatchMode='contains' sortable={true} />
                                        <Column field="maxLobj" header="Max" body={(rowData, column) => {
                                            return <div>
                                                <span style={{ color: 'red', fontWeight: 'bold' }}>{rowData.maxLobj}</span>
                                            </div>
                                        }} filter={true} filterMatchMode='contains' sortable={true} style={{ width: '10%' }} />
                                        <Column field="valConfLobj" header="Note" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '8%' }} editor={this.valConfLobjEditorQual} />
                                        <Column field="obsLobj" header="Observation" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '25%' }} editor={this.obsLobjEditorQual} />
                                    </DataTable>
                                }
                                <center>
                                    <div className="p-grid" style={{ width: 300, marginBottom: -10, marginTop: 10 }}>
                                        <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={() => {
                                            this.setState({ visible_dlg_lobj: false });
                                        }} /></div>
                                        <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={() => this.enreg_ligne()} /></div>

                                    </div>
                                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                </center>
                            </div>
                        </>
                    }
                </Dialog>


                <Dialog visible={this.state.visible_dlg_form_obj} maximizable style={{ width: this.state.modif_obj == true ? '700px' : '80%' }} header={this.state.modif_obj == true ? "Modification rapportage" : "Nouveau rapportage"} modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_form_obj: false })}>
                    {this.state.modif_obj == true ?
                        <div className="p-fluid" style={{ marginBottom: 60 }}>
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-12 p-md-6">
                                    <label htmlFor="firstname2">Acteur *</label>
                                    <Dropdown id="idPrest" value={this.state.idPrest} options={this.state.listeprest} optionLabel='label' filter={true} filterBy='label,typePrest' filterMatchMode='contains' onChange={(e) => this.setState({ idPrest: e.target.value })} autoWidth={false} itemTemplate={this.itemTemplate} panelStyle={{ width: '500px' }} />
                                </div>
                                <div className="p-field p-col-12 p-md-6">
                                    <label htmlFor="firstname2">Période *</label>
                                    <Calendar id="monthpicker" value={this.state.moisObj} onChange={(e) => this.setState({ moisObj: e.target.value })} view="month" dateFormat="mm/yy" yearNavigator yearRange="2010:2030" />
                                </div>
                                {/*  <div className="p-col-12 p-md-6">
                                    <label htmlFor="pointsFaiblesObj">Délai payement </label>
                                    <Calendar id="dateRgl" dateFormat="dd/mm/yy" value={this.state.delaiPayObj} onChange={(e) => this.setState({ delaiPayObj: e.target.value })} viewDate={new Date()} />
                                </div>
                                <div className="p-field p-col-12 p-md-6">
                                    <label htmlFor="firstname2">Conformité distance *</label>
                                    <Dropdown id="idPrest" value={this.state.confDistanceObj} options={[{ label: 'Oui', value: 'Oui' }, { label: 'Non', value: 'Non' }]} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ confDistanceObj: e.target.value })} autoWidth={false} />
                                </div> */}
                                <div className="p-col-12 p-md-6">
                                    <label htmlFor="obsObj">Observation </label>
                                    <InputTextarea id='obsObj' rows={2} value={this.state.obsObj} onChange={(e) => this.setState({ obsObj: e.target.value })} />
                                </div>
                                <div className="p-col-12 p-md-6">
                                    <label htmlFor="pointsFortsObj">Points forts </label>
                                    <InputTextarea id='pointsFortsObj' rows={2} value={this.state.pointsFortsObj} onChange={(e) => this.setState({ pointsFortsObj: e.target.value })} />
                                </div>
                                <div className="p-col-12 p-md-6">
                                    <label htmlFor="pointsFaiblesObj">Points faibles </label>
                                    <InputTextarea id='pointsFaiblesObj' rows={2} value={this.state.pointsFaiblesObj} onChange={(e) => this.setState({ pointsFaiblesObj: e.target.value })} />
                                </div>
                                <div className="p-col-12 p-md-6">
                                    <label htmlFor="pointsFaiblesObj">Contraintes </label>
                                    <InputTextarea id='pointsFaiblesObj' rows={2} value={this.state.contraintesObj} onChange={(e) => this.setState({ contraintesObj: e.target.value })} />
                                </div>

                            </div>
                        </div>
                        :
                        null
                    }
                    <>
                        <center>
                            <div className="p-grid" style={{ width: 400, marginTop: -30 }}>
                                <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={() => {
                                    this.setState({ visible_dlg_rgl: false, liste_selectedFacture: [] });
                                }} /></div>
                                <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={() => this.enreg_objectif()} /></div>
                                <div className="p-col">
                                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                </div>
                            </div>
                        </center>
                    </>
                </Dialog>

                <Dialog header="Détails rapportage" visible={this.state.visible_dlg_detail_lobj} style={{ width: '90%' }} modal={true} onHide={(e) => this.setState({ visible_dlg_detail_lobj: false })}>
                    <div className="card p-fluid">
                        <center>
                            {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                        </center>
                        <TabView>
                            <TabPanel header="Indicateurs quantitatifs">
                                <DataTable value={this.state.listeLobj_quantitatif}
                                    responsive={true} scrollable={true} scrollHeight="250px" selectionMode="single" className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                                    onRowClick={e => this.setState({ selectedLobjQuant: e.data })} onRowSelect={e => this.setState({ selectedLobjQuant: e.data })}
                                    selection={this.state.selectedLobjQuant} style={{ marginTop: -8 }} footer={btn_add_ind_quant}>
                                    {/* <Column header="#" body={this.actionTemplateLobj.bind(this)} style={{ width: '5%' }} /> */}
                                    <Column field="Index" header="N°" body={this.onIndexTemplate} style={{ width: '4%' }} />
                                    <Column field="libLobj" header="Indicateur" filter={true} filterMatchMode='contains' sortable={true} />
                                    <Column field="valPrevLobj" header="CA" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '7%' }} />
                                    <Column field="valDhis2Lobj" header="SNIS" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '7%' }} />
                                    <Column field="valEvalLobj" header="QD" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '7%' }} />
                                    <Column field="valConfLobj" header="QV" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '7%' }} />
                                    <Column header="TD" filter={true} body={(rowData, column) => {
                                        let marge = this.FbrService.getTD(rowData);
                                        let nbMoisObj = this.state.selectedObj.nbMoisObj;
                                        let applyTD = this.FbrService.checkTD(nbMoisObj);
                                        let nomBailleur = this.state.nomBailleur;
                                        let volume = rowData.valConfLobj + rowData.valPrevLobj;
                                        return <div>
                                            <span style={{ color: marge > (nomBailleur == 'ENABEL' && volume <= 50 ? 20 : 10) && applyTD == true ? 'red' : 'black' }}>{marge} %</span>
                                        </div>
                                    }} filterMatchMode='contains' sortable={true} style={{ width: '7%' }} />
                                    <Column field="spfraudeLobj" header="Fraude" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '9%' }} />
                                    <Column field="obsLobj" header="Observation" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '15%' }} />
                                </DataTable>
                            </TabPanel>
                            <TabPanel header="Indicateurs qualitatifs">
                                <DataTable value={this.state.listeLobj_qualitatif}
                                    responsive={true} scrollable={true} scrollHeight="250px" selectionMode="single" className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                                    onRowClick={e => this.setState({ selectedLobjQual: e.data })} onRowSelect={e => this.setState({ selectedLobjQual: e.data })}
                                    selection={this.state.selectedLobjQual} style={{ marginTop: -8 }} footer={btn_add_ind_qual}
                                    rowGroupHeaderTemplate={this.headerTemplate} rowGroupFooterTemplate={this.footerTemplateLobj} rowGroupMode="subheader" groupField="idType.idFamille.libCodif">
                                    {/* <Column header="#" body={this.actionTemplateLobj.bind(this)} style={{ width: '5%' }} /> */}
                                    <Column field="Index" header="N°" body={this.onIndexTemplate} style={{ width: '4%' }} />
                                    <Column field="libLobj" header="Indicateur" filter={true} filterMatchMode='contains' sortable={true} />
                                    <Column field="maxLobj" header="Max" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '7%' }} />
                                    <Column field="valPrevLobj" header="PD" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '7%' }} />
                                    <Column field="valConfLobj" header="PV" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '7%' }} />
                                    <Column field="obsLobj" header="Observation" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '15%' }} />
                                </DataTable>
                            </TabPanel>
                            <TabPanel header="Recommandations">
                                <DataTable value={this.state.liste_recom}
                                    responsive={true} scrollable={true} scrollHeight="250px" selectionMode="single" className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                                    onRowClick={e => this.setState({ selectedReco: e.data })} style={{ marginTop: -8 }} onRowSelect={e => this.setState({ selectedReco: e.data })}
                                    selection={this.state.selectedReco} footer={btn_add_ligne_reco}>
                                    <Column header="#" body={this.actionTemplate_rec.bind(this)} style={{ width: '12%' }} />
                                    <Column field="libRec" header="Recommandation" filter={true} filterMatchMode='contains' sortable={true} />
                                    <Column field="cmtRec" header="Commentaire" filter={true} filterMatchMode='contains' sortable={true} />
                                    <Column field="etatRec" header="Statut" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '15%' }} />
                                </DataTable>
                            </TabPanel>
                        </TabView>
                    </div>
                </Dialog>

                {
                    this.state.visible_form_new_obj == false ?
                        <>
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <div className="card-w-title">
                                        <i className="pi pi-search"></i> Option de recherche
                                        <hr />
                                        <div className="p-grid">
                                            <div className="p-col-12 p-md-2">
                                                <Calendar value={this.state.debut} onChange={(e) => this.setState({ debut: e.value }, () => {
                                                    this.get_liste_objectif();
                                                })} view="month" dateFormat="mm/yy" yearNavigator yearRange="2021:2070" />
                                            </div>
                                            <div className="p-col-12 p-md-2">
                                                <Calendar value={this.state.fin} onChange={(e) => this.setState({ fin: e.value }, () => {
                                                    this.get_liste_objectif();
                                                })} view="month" dateFormat="mm/yy" yearNavigator yearRange="2021:2070" />
                                            </div>
                                            <div className="p-col-12 p-md-2">
                                                <Dropdown autoWidth={false} options={[{ label: 'Acteur', value: 'Tous' }].concat(this.state.listeprest)} optionLabel='label' placeholder='Acteur' filter={true} filterBy='label,typePrest' filterMatchMode='contains' value={this.state.ridPrest} onChange={e => this.setState({ ridPrest: e.value }, () => {
                                                    this.get_liste_objectif();
                                                })} itemTemplate={this.itemTemplateRech} panelStyle={{ width: '500px' }} filterInputAutoFocus={false} />
                                            </div>
                                            <div className="p-col-12 p-md-2">
                                                <Dropdown autoWidth={false} options={
                                                    [{ label: 'Etat', value: 'Tous' },
                                                    { label: 'En attente', value: 'En attente' },
                                                    { label: 'Validé', value: 'Validé' },
                                                    { label: 'Annulé', value: 'Annulé' }
                                                    ]} optionLabel='label' placeholder='Etat' value={this.state.retatObj} onChange={e => this.setState({ retatObj: e.value }, () => {
                                                        this.get_liste_objectif();
                                                    })} />
                                            </div>
                                            <div className="p-col-12 p-md-2">
                                                <Dropdown autoWidth={false} options={
                                                    [{ label: 'Payement', value: 'Tous' },
                                                    { label: 'Payé', value: 'Payée' },
                                                    { label: 'Non payé', value: 'Non payée' }
                                                    ]} optionLabel='label' placeholder='Statut payement' value={this.state.rstatutPay} onChange={e => this.setState({ rstatutPay: e.value }, () => {
                                                        this.get_liste_objectif();
                                                    })} />
                                            </div>
                                            <div className="p-col-12 p-md-2">
                                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : <Button icon='pi pi-search' style={{ width: 120 }} label='Rechercher' onClick={() => this.get_liste_objectif()} />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <DataTable value={this.state.listeObj} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                                responsive={true} paginator={true} rows={7} alwaysShowPaginator={true} selectionMode="single" paginatorPosition="top"
                                onRowClick={e => {
                                    this.setState({ selectedObj: e.data }, () => {

                                    });
                                }} paginatorLeft={header_new} onRowSelect={e => this.setState({ selectedFeva: e.data })} scrollDirection="both"
                                selection={this.state.selectedFeva} >
                                <Column field="idObj" header="Action" body={this.actionTemplate_obj.bind(this)} style={{ width: '15%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="periodeObj" header="Periode" body={(rowData, column) => {
                                    return <div>
                                        <span >{rowData.periodeObj}</span>
                                    </div>
                                }} filter={true} filterMatchMode='contains' sortable={true} style={{ width: '10%' }} />
                                <Column field="nomPrest" header="Acteur" body={(rowData, column) => {
                                    return <div>
                                        <span >{rowData.nomPrest}</span><br />
                                    </div>
                                }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="statutPayObj" header="Statut" body={(rowData, column) => {
                                    return <div>
                                        <span >  {rowData.statutPayObj}</span><br />
                                    </div>
                                }} filter={true} filterMatchMode='contains' sortable={true} style={{ width: '10%' }} />
                                <Column field="etatObj" header="Etat" body={(rowData, column) => {
                                    return <div>
                                        <span>  {rowData.etatObj}</span><br />
                                    </div>
                                }} filter={true} filterMatchMode='contains' sortable={true} style={{ width: '10%' }} />
                                <Column field="mttObj" header="Montant" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: 'green', fontWeight: 'bold' }}>{this.FbrService.formaterValueSep(parseFloat(rowData.mttObj))}</span>
                                    </div>
                                }} filter={true} filterMatchMode='contains' sortable={true} style={{ width: '12%' }} />
                                <Column field="scoreObj" header="Score" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: '#000000', fontWeight: 'bold' }}>{rowData.scoreObj}</span><br />
                                    </div>
                                }} filter={true} filterMatchMode='contains' sortable={true} style={{ width: '8%' }} />

                                <Column field="typePrest" header="Type" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: '#000000' }}>{rowData.typePrest}</span>
                                    </div>
                                }} filter={true} filterMatchMode='contains' sortable={true} style={{ width: '10%' }} />
                            </DataTable>

                        </>
                        :
                        <>
                            {this.state.typeSaisie == 'MOIS' ?
                                <div className="p-fluid">

                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

                                        <label style={{ fontSize: 18, fontWeight: 'bold' }}>
                                            <i className="pi pi-pencil"></i>  Nouveau rapportage mensuel
                                        </label>
                                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                        <label>
                                            <Button label="Liste rapportage" className="p-button-help" icon="pi pi-list" onClick={() => {
                                                this.setState({ visible_form_new_obj: false, liste_selectedFacture: [] });
                                            }} />
                                        </label>

                                    </div>
                                    <hr />

                                    <div className="p-fluid p-formgrid p-grid">
                                        <div className="p-field p-col-12 p-md-4">
                                            <label htmlFor="firstname2">Acteur *</label>
                                            <Dropdown id="idPrest" value={this.state.idPrest} options={this.state.listeprest} optionLabel='label' filter={true} filterBy='label,typePrest' filterMatchMode='contains' onChange={(e) => this.setState({ idPrest: e.target.value }, () => {
                                                let selectedPrest = this.FbrService.getPrest(this.state.listeprest, this.state.idPrest);
                                                this.setState({
                                                    liste_quantitatif: [],
                                                    liste_qualitatif: [],
                                                    debPrest: selectedPrest.debPrest,
                                                    nomBailleur: selectedPrest.bailleur
                                                });
                                                this.get_liste_ligne_objectif_for_evaluation(this.state.idPrest, this.state.rMois);
                                            })} autoWidth={false} itemTemplate={this.itemTemplate} panelStyle={{ width: '500px' }} appendTo={document.body} filterInputAutoFocus={false} />
                                        </div>
                                        <div className="p-field p-col-12 p-md-4">
                                            <label htmlFor="firstname2">Période *</label>
                                            <Calendar id="monthpicker" value={this.state.moisObj} onChange={(e) => this.setState({ moisObj: e.target.value }, () => {
                                                this.setState({ liste_quantitatif: [], liste_qualitatif: [] });
                                                let val = moment(this.state.moisObj).format('MM/YYYY');
                                                this.setState({ rMois: val.split("/")[0] + '/' + val.split("/")[1] }, () => {
                                                    this.get_liste_ligne_objectif_for_evaluation(this.state.idPrest, this.state.rMois);
                                                });
                                            })} view="month" dateFormat="mm/yy" yearNavigator yearRange="2010:2030" />
                                        </div>
                                        <div className="p-field p-col-12 p-md-4">
                                            <label htmlFor="firstname2">MONTANT QUANTITÉ</label>
                                            <br />
                                            <div className="p-col-12" style={{ padding: 2, backgroundColor: '#ccff66', border: '1px solid #333', alignItems: 'center' }} >
                                                <label style={{ color: '#000', fontSize: '22px', fontWeight: 'bold' }} htmlFor="in">{this.state.total_mt_quantite}</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card" style={{ height: 690, marginTop: -30 }}>
                                        <TabView>
                                            <TabPanel header="Indicateurs quantitatifs">
                                                <DataTable value={this.state.liste_quantitatif}
                                                    responsive={true} scrollable={true} scrollHeight="510px" selectionMode="single" className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                                                    onRowClick={e => this.setState({ selectedLobj: e.data })} onRowSelect={e => this.setState({ selectedLobj: e.data })}
                                                    selection={this.state.selectedLobj}>
                                                    <Column field="Index" header="N°" body={this.onIndexTemplate} style={{ width: '4%' }} />
                                                    <Column field="libLobj" header="Indicateur" filter={true} filterMatchMode='contains' />
                                                    <Column field="valPrevLobj" header="CA" filter={true} filterMatchMode='contains' style={{ width: '6%' }} editor={this.valPrevLobjEditorQuant} />
                                                    <Column field="valDhis2Lobj" body={(rowData, column) => {
                                                        return <div>
                                                            <span style={{ color: rowData.valEvalLobj > rowData.valDhis2Lobj || rowData.valConfLobj > rowData.valDhis2Lobj ? 'red' : 'black' }}>{rowData.valDhis2Lobj}</span>
                                                        </div>
                                                    }} header="SNIS" filter={true} filterMatchMode='contains' style={{ width: '6%' }} editor={this.valDhis2LobjEditorQuant} />
                                                    <Column field="valEvalLobj" body={(rowData, column) => {
                                                        let marge = this.FbrService.getTD(rowData);
                                                        let obj = {
                                                            periodeObj: this.state.rMois,
                                                            idPrest: { debPrest: this.state.debPrest }
                                                        }
                                                        let nbMoisObj = this.FbrService.getNbMois(obj);
                                                        let applyTD = this.FbrService.checkTD(nbMoisObj);
                                                        let nomBailleur = this.state.nomBailleur;
                                                        let volume = rowData.valConfLobj + rowData.valPrevLobj;
                                                        return <div>
                                                            <span style={{ color: marge > (nomBailleur == 'ENABEL' && volume <= 50 ? 20 : 10) && applyTD == true ? 'red' : 'black' }}>{rowData.valEvalLobj}</span>
                                                        </div>
                                                    }} header="QD" filter={true} filterMatchMode='contains' style={{ width: '6%' }} editor={this.valEvalLobjEditorQuant} />
                                                    <Column field="valConfLobj" header="QV" filter={true} filterMatchMode='contains' style={{ width: '6%' }} editor={this.valConfLobjEditorQuant} />
                                                    <Column field="puLobj" header="Prix" filter={true} filterMatchMode='contains' body={(rowData, column) => {
                                                        return <div>
                                                            <span style={{ color: '#000000', fontWeight: 'bold' }}>{this.FbrService.formaterValueSep(parseFloat(rowData.puLobj))}</span>
                                                        </div>
                                                    }} style={{ width: '9%' }} />
                                                    <Column header="TD" filter={true} filterMatchMode='contains' body={(rowData, column) => {
                                                        let marge = this.FbrService.getTD(rowData);
                                                        let obj = {
                                                            periodeObj: this.state.rMois,
                                                            idPrest: { debPrest: this.state.debPrest }
                                                        }
                                                        let nbMoisObj = this.FbrService.getNbMois(obj);
                                                        let applyTD = this.FbrService.checkTD(nbMoisObj);
                                                        let nomBailleur = this.state.nomBailleur;
                                                        let volume = rowData.valConfLobj + rowData.valPrevLobj;
                                                        return <div>
                                                            <span style={{ color: marge > (nomBailleur == 'ENABEL' && volume <= 50 ? 20 : 10) && applyTD == true ? 'red' : 'black' }}>{marge} %</span>
                                                        </div>
                                                    }} style={{ width: '7%' }} />
                                                    <Column field="montant" body={(rowData, column) => {
                                                        return <div>
                                                            <span style={{ fontWeight: 'bold' }}>{this.FbrService.formaterValueSep(parseFloat(rowData.montant))}</span>
                                                        </div>
                                                    }} header="Montant" filter={true} filterMatchMode='contains' style={{ width: '12%' }} />
                                                    <Column field="spfraudeLobj" filter={true} filterMatchMode='contains' body={(rowData, column) => {
                                                        let list = ['Oui', 'Non'];
                                                        return <div className="p-grid" style={{ marginTop: 1, marginLeft: 1 }}>
                                                            {list.map((item) => {
                                                                return (
                                                                    <div key={item} style={{ padding: 5 }}>
                                                                        <RadioButton inputId={item} name="item" value={item} onChange={(e) => this.setState({ spfraudeLobj: e.target.value })} checked={rowData.spfraudeLobj === item} />
                                                                        <label htmlFor={item}> {item} </label>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    }} header="Fraude" style={{ width: '8%' }} editor={this.radioEditor} />
                                                    <Column field="obsLobj" header="Observation" body={this.actionTemplate_obs.bind(this)} filter={true} filterMatchMode='contains' style={{ width: '15%' }} />
                                                </DataTable>
                                            </TabPanel>
                                            <TabPanel header="Indicateurs qualitatifs">
                                                <DataTable value={this.state.liste_qualitatif}
                                                    responsive={true} scrollable={true} scrollHeight="510px" selectionMode="single" className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                                                    onRowClick={e => this.setState({ selectedLobj: e.data })} onRowSelect={e => this.setState({ selectedLobj: e.data })}
                                                    selection={this.state.selectedLobj}
                                                    rowGroupHeaderTemplate={this.headerTemplate} rowGroupFooterTemplate={this.footerTemplate} rowGroupMode="subheader" groupField="idType.idFamille.libCodif">
                                                    <Column field="Index" header="N°" body={this.rangBody} style={{ width: '4%' }} />
                                                    <Column field="libLobj" header="Indicateur" filter={true} filterMatchMode='contains' />
                                                    <Column field="maxLobj" header="P Max" body={(rowData, column) => {
                                                        return <div>
                                                            <span style={{ fontWeight: 'bold' }}>{rowData.maxLobj}</span>
                                                        </div>
                                                    }} filter={true} filterMatchMode='contains' style={{ width: '7%' }} />
                                                    <Column field="valEvalLobj" body={(rowData, props) => {
                                                        return <div>
                                                            <InputNumber style={{ width: '105px', color: rowData.valEvalLobj < rowData.valConfLobj ? 'red' : 'black' }}
                                                                onChange={(e) => {
                                                                    this.state.liste_qualitatif[props.rowIndex].valEvalLobj = e.value;
                                                                }}
                                                                disabled={props.rowData.idObj.disableSaisie == 'true' ? true : false}
                                                                showButtons buttonLayout="horizontal" decrementButtonClassName="p-button-secondary" incrementButtonClassName="p-button-success" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" max={rowData.maxLobj['maxLobj']} min={0} step={rowData.maxLobj} value={rowData.valEvalLobj} />
                                                        </div>
                                                    }} header="Point déclaré" filter={true} filterMatchMode='contains' style={{ width: '14%' }} editor={this.valEvalLobjEditorQual} />
                                                    <Column field="valConfLobj" body={(rowData, props) => {
                                                        return <div>
                                                            <InputNumber style={{ width: '105px' }} onChange={(e) => {
                                                                this.state.liste_qualitatif[props.rowIndex].valConfLobj = e.value;
                                                            }}
                                                                disabled={props.rowData.idObj.disableSaisie == 'true' ? true : false}
                                                                showButtons buttonLayout="horizontal" decrementButtonClassName="p-button-secondary" incrementButtonClassName="p-button-success" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" max={rowData.maxLobj['maxLobj']} min={0} step={rowData.maxLobj} value={rowData.valConfLobj} />
                                                        </div>
                                                    }} header="Point validé" filter={true} filterMatchMode='contains' style={{ width: '14%' }} editor={this.valConfLobjEditorQual} />
                                                    <Column header="TD" filter={true} filterMatchMode='contains' body={(rowData, column) => {
                                                        let marge = this.FbrService.getTD(rowData);
                                                        let obj = {
                                                            periodeObj: this.state.rMois,
                                                            idPrest: { debPrest: this.state.debPrest }
                                                        }
                                                        let nbMoisObj = this.FbrService.getNbMois(obj);
                                                        let applyTD = this.FbrService.checkTD(nbMoisObj);
                                                        return <div>
                                                            <span style={{ color: marge > 100 && applyTD == true ? 'red' : 'black' }}>{marge} %</span>
                                                        </div>
                                                    }} style={{ width: '7%' }} />
                                                    <Column field="obsLobj" header="Observation" body={this.actionTemplate_obs.bind(this)} filter={true} filterMatchMode='contains' style={{ width: '15%' }} />
                                                </DataTable>
                                            </TabPanel>
                                            <TabPanel header="Outil indice">
                                                <DataTable value={this.state.liste_outil_indice}
                                                    responsive={true} scrollable={true} scrollHeight="510px" selectionMode="single" className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                                                    onRowClick={e => this.setState({ selectedLobj: e.data })} onRowSelect={e => this.setState({ selectedLobj: e.data })}
                                                    selection={this.state.selectedLobj}>
                                                    <Column field="Index" header="N°" body={this.onIndexTemplate} style={{ width: '4%' }} />
                                                    <Column field="libLobj" header="Indicateur" filter={true} filterMatchMode='contains' />
                                                    <Column field="maxLobj" header="P Max" body={(rowData, column) => {
                                                        return <div>
                                                            <span style={{ fontWeight: 'bold' }}>{rowData.maxLobj}</span>
                                                        </div>
                                                    }} filter={true} filterMatchMode='contains' style={{ width: '7%' }} />
                                                    <Column field="valConfLobj" body={(rowData, props) => {
                                                        return <div>
                                                            <InputNumber style={{ width: '107px' }} onChange={(e) => {
                                                                this.state.liste_outil_indice[props.rowIndex].valConfLobj = e.value;
                                                            }}
                                                                showButtons buttonLayout="horizontal" decrementButtonClassName="p-button-secondary" incrementButtonClassName="p-button-success" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" max={rowData.maxLobj['maxLobj']} min={0} step={rowData.maxLobj} value={rowData.valConfLobj} />
                                                        </div>
                                                    }} header="Point obtenu" filter={true} filterMatchMode='contains' style={{ width: '15%' }} editor={this.valConfLobjEditorQual} />

                                                    <Column field="obsLobj" header="Observation" body={this.actionTemplate_obs.bind(this)} filter={true} filterMatchMode='contains' style={{ width: '20%' }} />
                                                </DataTable>
                                            </TabPanel>
                                            <TabPanel header="Recommandations">
                                                <div className="p-grid">
                                                    <div className="p-col-12 p-md-2">
                                                        <label >Recommandation *</label>
                                                    </div>
                                                    <div className="p-col-12 p-md-4">
                                                        <InputTextarea rows={2} value={this.state.libRec} onChange={(e) => this.setState({ libRec: e.target.value })} />
                                                    </div>
                                                    <div className="p-col-12 p-md-2">
                                                        <label >Commentaire</label>
                                                    </div>
                                                    <div className="p-col-12 p-md-3">
                                                        <InputTextarea rows={2} value={this.state.cmtRec} onChange={(e) => this.setState({ cmtRec: e.target.value })} />
                                                    </div>
                                                    <div className="p-col-12 p-md-1">
                                                        <Button type="button" icon="pi pi-plus" iconPos="left" label="Ajouter" onClick={(e) => this.add_reco()} style={{ width: 100 }} />
                                                    </div>
                                                </div>
                                                <DataTable value={this.state.liste_new_recom}
                                                    responsive={true} scrollable={true} scrollHeight="475px" selectionMode="single" className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                                                    onRowClick={e => this.setState({ selectedReco: e.data })} onRowSelect={e => this.setState({ selectedReco: e.data })}
                                                    selection={this.state.selectedReco}>
                                                    <Column header="#" body={this.actionTemplate_new_rec.bind(this)} style={{ width: '5%' }} />
                                                    <Column field="libRec" header="Recommandation" filter={true} filterMatchMode='contains' sortable={true} editor={this.libRecEditor} />
                                                    <Column field="cmtRec" header="Commentaire" filter={true} filterMatchMode='contains' sortable={true} editor={this.cmtRecEditor} />
                                                </DataTable>
                                            </TabPanel>
                                        </TabView>

                                    </div>

                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-6">
                                            <div className="p-field">
                                                <span className="p-float-label">
                                                    <InputTextarea id='obsObj' rows={4} value={this.state.obsObj} onChange={(e) => this.setState({ obsObj: e.target.value })} />
                                                    <label htmlFor="obsObj">Observation </label>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="p-col-12 p-md-6">
                                            <div className="p-field">
                                                <span className="p-float-label">
                                                    <InputTextarea id='pointsFortsObj' rows={4} value={this.state.pointsFortsObj} onChange={(e) => this.setState({ pointsFortsObj: e.target.value })} />
                                                    <label htmlFor="pointsFortsObj">Points forts </label>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="p-col-12 p-md-6">
                                            <div className="p-field">
                                                <span className="p-float-label">
                                                    <InputTextarea id='pointsFaiblesObj' rows={4} value={this.state.pointsFaiblesObj} onChange={(e) => this.setState({ pointsFaiblesObj: e.target.value })} />
                                                    <label htmlFor="pointsFaiblesObj">Points faibles </label>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="p-col-12 p-md-6">
                                            <div className="p-field">
                                                <span className="p-float-label">
                                                    <InputTextarea id='pointsFaiblesObj' rows={4} value={this.state.contraintesObj} onChange={(e) => this.setState({ contraintesObj: e.target.value })} />
                                                    <label htmlFor="pointsFaiblesObj">Contraintes </label>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <>
                                        <center>
                                            <div className="p-grid" style={{ width: 400 }}>
                                                <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={() => {
                                                    this.setState({ visible_form_new_obj: false, liste_selectedFacture: [] });
                                                }} /></div>
                                                <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={() => this.enreg_objectif()} /></div>
                                                <div className="p-col">
                                                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                                </div>
                                            </div>
                                        </center>
                                    </>
                                </div>

                                :
                                <div className="p-fluid">

                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

                                        <label style={{ fontSize: 18, fontWeight: 'bold' }}>
                                            <i className="pi pi-pencil"></i>  Nouveau rapportage trimestriel
                                        </label>
                                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                        <label>
                                            <Button label="Liste rapportage" className="p-button-help" icon="pi pi-list" onClick={() => {
                                                this.setState({ visible_form_new_obj: false, liste_selectedFacture: [], liste_quantitatif: [], liste_qualitatif: [] });
                                            }} />
                                        </label>


                                    </div>
                                    <hr />

                                    <div className="p-fluid p-formgrid p-grid">
                                        <div className="p-field p-col-12 p-md-3">
                                            <label htmlFor="firstname2">Acteur *</label>
                                            <Dropdown id="idPrest" value={this.state.idPrest} options={this.state.listeprest} optionLabel='label' filter={true} filterBy='label,typePrest' filterMatchMode='contains' onChange={(e) => this.setState({ idPrest: e.target.value }, () => {
                                                let selectedPrest = this.FbrService.getPrest(this.state.listeprest, this.state.idPrest);
                                                this.setState({
                                                    liste_quantitatif: [],
                                                    liste_qualitatif: [],
                                                    debPrest: selectedPrest.debPrest,
                                                    nomBailleur: selectedPrest.bailleur
                                                });
                                                let m1 = '';
                                                let m2 = '';
                                                let m3 = '';

                                                if (this.state.rTrim == 'T1') {
                                                    m1 = '01/' + this.state.anneeObj;
                                                    m2 = '02/' + this.state.anneeObj;
                                                    m3 = '03/' + this.state.anneeObj;

                                                } else if (this.state.rTrim == 'T2') {
                                                    m1 = '04/' + this.state.anneeObj;
                                                    m2 = '05/' + this.state.anneeObj;
                                                    m3 = '06/' + this.state.anneeObj;

                                                } else if (this.state.rTrim == 'T3') {
                                                    m1 = '07/' + this.state.anneeObj;
                                                    m2 = '08/' + this.state.anneeObj;
                                                    m3 = '09/' + this.state.anneeObj;

                                                } else {
                                                    m1 = '10/' + this.state.anneeObj;
                                                    m2 = '11/' + this.state.anneeObj;
                                                    m3 = '12/' + this.state.anneeObj;

                                                }
                                                this.setState({
                                                    rMois1: m1,
                                                    rMois: m1,
                                                    rMois2: m2,
                                                    rMois3: m3,
                                                }, () => {
                                                    this.get_liste_ligne_objectif_for_evaluation_trim(this.state.idPrest, this.state.rMois1, this.state.rMois2, this.state.rMois3);
                                                })
                                            })} autoWidth={false} itemTemplate={this.itemTemplate} panelStyle={{ width: '500px' }} appendTo={document.body} filterInputAutoFocus={false} />
                                        </div>
                                        <div className="p-field p-col-12 p-md-3">
                                            <label htmlFor="firstname2">Trimestre *</label>
                                            <Dropdown id="idPrest" value={this.state.rTrim} options={[{ value: 'T1', label: 'T1' }, { value: 'T2', label: 'T2' }, { value: 'T3', label: 'T3' }, { value: 'T4', label: 'T4' }]} optionLabel='label' onChange={(e) => this.setState({ rTrim: e.target.value }, () => {
                                                this.setState({ liste_quantitatif: [], liste_qualitatif: [] });
                                                let m1 = '';
                                                let m2 = '';
                                                let m3 = '';
                                                let lm1 = '';
                                                let lm2 = '';
                                                let lm3 = '';
                                                let mois = '';

                                                if (this.state.rTrim == 'T1') {
                                                    m1 = '01/' + this.state.anneeObj;
                                                    m2 = '02/' + this.state.anneeObj;
                                                    m3 = '03/' + this.state.anneeObj;
                                                    mois = '01';
                                                    lm1 = 'Jan';
                                                    lm2 = 'Fev';
                                                    lm3 = 'Mars';
                                                } else if (this.state.rTrim == 'T2') {
                                                    m1 = '04/' + this.state.anneeObj;
                                                    m2 = '05/' + this.state.anneeObj;
                                                    m3 = '06/' + this.state.anneeObj;
                                                    mois = '04';
                                                    lm1 = 'Avr';
                                                    lm2 = 'Mai';
                                                    lm3 = 'Jun';
                                                } else if (this.state.rTrim == 'T3') {
                                                    m1 = '07/' + this.state.anneeObj;
                                                    m2 = '08/' + this.state.anneeObj;
                                                    m3 = '09/' + this.state.anneeObj;
                                                    mois = '07';
                                                    lm1 = 'Jul';
                                                    lm2 = 'Aou';
                                                    lm3 = 'Sep';
                                                } else {
                                                    m1 = '10/' + this.state.anneeObj;
                                                    m2 = '11/' + this.state.anneeObj;
                                                    m3 = '12/' + this.state.anneeObj;
                                                    mois = '10';
                                                    lm1 = 'Oct';
                                                    lm2 = 'Nov';
                                                    lm3 = 'Dec';
                                                }
                                                this.setState({
                                                    rMois1: m1,
                                                    rMois2: m2,
                                                    rMois3: m3,
                                                    libM1: lm1,
                                                    libM2: lm2,
                                                    libM3: lm3,
                                                    rMois: m1,
                                                    moisObj: mois
                                                }, () => {
                                                    this.get_liste_ligne_objectif_for_evaluation_trim(this.state.idPrest, this.state.rMois1, this.state.rMois2, this.state.rMois3);
                                                })
                                            })} autoWidth={false} itemTemplate={this.itemTemplate} panelStyle={{ width: '500px' }} />
                                        </div>
                                        <div className="p-field p-col-12 p-md-3">
                                            <label htmlFor="firstname2">Année *</label>
                                            <InputText keyfilter="pint" value={this.state.anneeObj} onChange={(e) => this.setState({ anneeObj: e.target.value }, () => {
                                                this.setState({ liste_quantitatif: [], liste_qualitatif: [] });
                                                let m1 = '';
                                                let m2 = '';
                                                let m3 = '';
                                                if (this.state.rTrim == 'T1') {
                                                    m1 = '01/' + this.state.anneeObj;
                                                    m2 = '02/' + this.state.anneeObj;
                                                    m3 = '03/' + this.state.anneeObj;
                                                } else if (this.state.rTrim == 'T2') {
                                                    m1 = '04/' + this.state.anneeObj;
                                                    m2 = '05/' + this.state.anneeObj;
                                                    m3 = '06/' + this.state.anneeObj;
                                                } else if (this.state.rTrim == 'T3') {
                                                    m1 = '07/' + this.state.anneeObj;
                                                    m2 = '08/' + this.state.anneeObj;
                                                    m3 = '09/' + this.state.anneeObj;
                                                } else {
                                                    m1 = '10/' + this.state.anneeObj;
                                                    m2 = '11/' + this.state.anneeObj;
                                                    m3 = '12/' + this.state.anneeObj;
                                                }
                                                this.setState({
                                                    rMois1: m1,
                                                    rMois2: m2,
                                                    rMois: m1,
                                                    rMois3: m3
                                                }, () => {
                                                    this.get_liste_ligne_objectif_for_evaluation_trim(this.state.idPrest, this.state.rMois1, this.state.rMois2, this.state.rMois3);
                                                })
                                            })} />
                                        </div>
                                        <div className="p-field p-col-12 p-md-3">
                                            <label htmlFor="firstname2">MONTANT QUANTITÉ</label>
                                            <br />
                                            <div className="p-col-12" style={{ padding: 2, backgroundColor: '#ccff66', border: '1px solid #333', alignItems: 'center' }} >
                                                <label style={{ color: '#000', fontSize: '22px', fontWeight: 'bold' }} htmlFor="in">{this.state.total_mt_quantite}</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card" style={{ height: 690, marginTop: -30 }}>
                                        <TabView>
                                            <TabPanel header="Indicateurs quantitatifs">
                                                <DataTable value={this.state.liste_quantitatif} scrollDirection="both"
                                                    responsive={true} scrollable={true} scrollHeight="510px" selectionMode="single" className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                                                    onRowClick={e => this.setState({ selectedLobj: e.data })} onRowSelect={e => this.setState({ selectedLobj: e.data })}
                                                    selection={this.state.selectedLobj} headerColumnGroup={headerGroup}>
                                                    <Column field="Index" header="N°" body={this.onIndexTemplate} style={{ width: '4%' }} />
                                                    <Column field="libLobj" header="Indicateur" filter={true} filterMatchMode='contains' />
                                                    <Column field="valDhis2Lobj" filter={true} filterMatchMode='contains' style={{ width: '4%' }} editor={this.valDhis2LobjEditorQuant} />
                                                    <Column field="valDhis2LobjM2" filter={true} filterMatchMode='contains' style={{ width: '4%' }} editor={this.valDhis2LobjM2EditorQuant} />
                                                    <Column field="valDhis2LobjM3" filter={true} filterMatchMode='contains' style={{ width: '4%' }} editor={this.valDhis2LobjM3EditorQuant} />
                                                    <Column body={(rowData, column) => {
                                                        let total = rowData.valDhis2Lobj + rowData.valDhis2LobjM2 + rowData.valDhis2LobjM3;
                                                        return <div>
                                                            <span >{total}</span>
                                                        </div>
                                                    }} filter={true} filterMatchMode='contains' sortable={true} style={{ width: '4%' }} />

                                                    <Column field="valEvalLobj" body={(rowData, column) => {
                                                        let marge = this.FbrService.getTD(rowData);
                                                        let obj = {
                                                            periodeObj: this.state.rMois,
                                                            idPrest: { debPrest: this.state.debPrest }
                                                        }
                                                        let nbMoisObj = this.FbrService.getNbMois(obj);
                                                        let applyTD = this.FbrService.checkTD(nbMoisObj);
                                                        let nomBailleur = this.state.nomBailleur;
                                                        let volume = rowData.valConfLobj + rowData.valPrevLobj;
                                                        return <div>
                                                            <span style={{ color: marge > (nomBailleur == 'ENABEL' && volume <= 50 ? 20 : 10) && applyTD == true ? 'red' : 'black' }}>{rowData.valEvalLobj}</span>
                                                        </div>
                                                    }} filter={true} filterMatchMode='contains' style={{ width: '4%' }} editor={this.valEvalLobjEditorQuant} />
                                                    <Column field="valEvalLobjM2" body={(rowData, column) => {
                                                        let marge = 0;
                                                        if (rowData.valEvalLobjM2 == 0) {
                                                            marge = 0;
                                                        } else {
                                                            marge = 100 * (rowData.valEvalLobjM2 - rowData.valConfLobjM2) / rowData.valEvalLobjM2
                                                        }
                                                        marge = Math.abs(parseFloat(marge));
                                                        let obj = {
                                                            periodeObj: this.state.rMois,
                                                            idPrest: { debPrest: this.state.debPrest }
                                                        }
                                                        let nbMoisObj = this.FbrService.getNbMois(obj);
                                                        let applyTD = this.FbrService.checkTD(nbMoisObj);
                                                        let nomBailleur = this.state.nomBailleur;
                                                        let volume = rowData.valConfLobjM2 + rowData.valPrevLobjM2;
                                                        return <div>
                                                            <span style={{ color: marge > (nomBailleur == 'ENABEL' && volume <= 50 ? 20 : 10) && applyTD == true ? 'red' : 'black' }}>{rowData.valEvalLobjM2}</span>
                                                        </div>
                                                    }} filter={true} filterMatchMode='contains' style={{ width: '4%' }} editor={this.valEvalLobjM2EditorQuant} />
                                                    <Column field="valEvalLobjM3" body={(rowData, column) => {
                                                        let marge = 0;
                                                        if (rowData.valEvalLobjM3 == 0) {
                                                            marge = 0;
                                                        } else {
                                                            marge = 100 * (rowData.valEvalLobjM3 - rowData.valConfLobjM3) / rowData.valEvalLobjM3
                                                        }
                                                        marge = Math.abs(parseFloat(marge));
                                                        let obj = {
                                                            periodeObj: this.state.rMois,
                                                            idPrest: { debPrest: this.state.debPrest }
                                                        }
                                                        let nbMoisObj = this.FbrService.getNbMois(obj);
                                                        let applyTD = this.FbrService.checkTD(nbMoisObj);
                                                        let nomBailleur = this.state.nomBailleur;
                                                        let volume = rowData.valConfLobjM3 + rowData.valPrevLobjM3;
                                                        return <div>
                                                            <span style={{ color: marge > (nomBailleur == 'ENABEL' && volume <= 50 ? 20 : 10) && applyTD == true ? 'red' : 'black' }}>{rowData.valEvalLobjM3}</span>
                                                        </div>
                                                    }} filter={true} filterMatchMode='contains' style={{ width: '4%' }} editor={this.valEvalLobjM3EditorQuant} />
                                                    <Column body={(rowData, column) => {
                                                        let total = rowData.valEvalLobj + rowData.valEvalLobjM2 + rowData.valEvalLobjM3;
                                                        return <div>
                                                            <span >{total}</span>
                                                        </div>
                                                    }} filter={true} filterMatchMode='contains' style={{ width: '4%' }} />
                                                    <Column field="valConfLobj" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '5%' }} editor={this.valConfLobjEditorQuant} />
                                                    <Column field="valConfLobjM2" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '5%' }} editor={this.valConfLobjM2EditorQuant} />
                                                    <Column field="valConfLobjM3" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '5%' }} editor={this.valConfLobjM3EditorQuant} />
                                                    <Column body={(rowData, column) => {
                                                        let total = rowData.valConfLobj + rowData.valConfLobjM2 + rowData.valConfLobjM3;
                                                        return <div>
                                                            <span >{total}</span>
                                                        </div>
                                                    }} filter={true} filterMatchMode='contains' sortable={true} style={{ width: '5%' }} />
                                                    <Column field="puLobj" header="Prix unitaire" filter={true} filterMatchMode='contains' body={(rowData, column) => {
                                                        return <div>
                                                            <span style={{ color: '#000000', fontWeight: 'bold' }}>{this.FbrService.formaterValueSep(parseFloat(rowData.puLobj))}</span>
                                                        </div>
                                                    }} sortable={true} style={{ width: '8%' }} />
                                                    <Column field="montant" body={(rowData, column) => {
                                                        return <div>
                                                            <span title='Montant' style={{ fontWeight: 'bold' }}>{this.FbrService.formaterValueSep(parseFloat(rowData.montant))}</span>
                                                        </div>
                                                    }} header="Montant" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '8%' }} />
                                                    <Column field="obsLobj" header="Observation" body={this.actionTemplate_obs.bind(this)} filter={true} filterMatchMode='contains' sortable={true} style={{ width: '10%' }} />
                                                </DataTable>
                                            </TabPanel>
                                            <TabPanel header="Indicateurs qualitatifs">
                                                <DataTable value={this.state.liste_qualitatif}
                                                    responsive={true} scrollable={true} scrollHeight="510px" selectionMode="single" className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                                                    onRowClick={e => this.setState({ selectedLobj: e.data })} onRowSelect={e => this.setState({ selectedLobj: e.data })}
                                                    rowGroupHeaderTemplate={this.headerTemplate} rowGroupFooterTemplate={this.footerTemplate} rowGroupMode="subheader" groupField="idType.idFamille.libCodif"
                                                    selection={this.state.selectedLobj}>
                                                    <Column field="Index" header="N°" body={this.rangBody} style={{ width: '4%' }} />
                                                    <Column field="libLobj" header="Indicateur" filter={true} filterMatchMode='contains' />
                                                    <Column field="maxLobj" header="P Max" body={(rowData, column) => {
                                                        return <div>
                                                            <span style={{ fontWeight: 'bold' }}>{rowData.maxLobj}</span>
                                                        </div>
                                                    }} filter={true} filterMatchMode='contains' style={{ width: '8%' }} />
                                                    <Column field="valEvalLobj" body={(rowData, props) => {
                                                        return <div>
                                                            <InputNumber style={{ width: '105px', color: rowData.valEvalLobj < rowData.valConfLobj ? 'red' : 'black' }}
                                                                onChange={(e) => {
                                                                    this.state.liste_qualitatif[props.rowIndex].valEvalLobj = e.value;
                                                                }}
                                                                showButtons buttonLayout="horizontal" decrementButtonClassName="p-button-secondary" incrementButtonClassName="p-button-success" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" max={rowData.maxLobj['maxLobj']} min={0} step={rowData.maxLobj} value={rowData.valEvalLobj} />
                                                        </div>
                                                    }} header="Point déclaré" filter={true} filterMatchMode='contains' style={{ width: '14%' }} editor={this.valEvalLobjEditorQual} />
                                                    <Column field="valConfLobj" body={(rowData, props) => {
                                                        return <div>
                                                            <InputNumber style={{ width: '105px' }} onChange={(e) => {
                                                                this.state.liste_qualitatif[props.rowIndex].valConfLobj = e.value;
                                                            }}
                                                                showButtons buttonLayout="horizontal" decrementButtonClassName="p-button-secondary" incrementButtonClassName="p-button-success" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" max={rowData.maxLobj['maxLobj']} min={0} step={rowData.maxLobj} value={rowData.valConfLobj} />
                                                        </div>
                                                    }} header="Point validé" filter={true} filterMatchMode='contains' style={{ width: '14%' }} editor={this.valConfLobjEditorQual} />
                                                    <Column header="TD" filter={true} filterMatchMode='contains' body={(rowData, column) => {
                                                        let marge = this.FbrService.getTD(rowData);
                                                        let obj = {
                                                            periodeObj: this.state.rMois,
                                                            idPrest: { debPrest: this.state.debPrest }
                                                        }
                                                        let nbMoisObj = this.FbrService.getNbMois(obj);
                                                        let applyTD = this.FbrService.checkTD(nbMoisObj);
                                                        return <div>
                                                            <span style={{ color: marge > 100 && applyTD == true ? 'red' : 'black' }}>{marge} %</span>
                                                        </div>
                                                    }} style={{ width: '7%' }} />
                                                    <Column field="obsLobj" header="Observation" body={this.actionTemplate_obs.bind(this)} filter={true} filterMatchMode='contains' style={{ width: '25%' }} />

                                                </DataTable>
                                            </TabPanel>

                                            <TabPanel header="Outil indice">
                                                <DataTable value={this.state.liste_outil_indice}
                                                    responsive={true} scrollable={true} scrollHeight="510px" selectionMode="single" className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                                                    onRowClick={e => this.setState({ selectedLobj: e.data })} onRowSelect={e => this.setState({ selectedLobj: e.data })}
                                                    selection={this.state.selectedLobj}>
                                                    <Column field="Index" header="N°" body={this.onIndexTemplate} style={{ width: '4%' }} />
                                                    <Column field="libLobj" header="Indicateur" filter={true} filterMatchMode='contains' />
                                                    <Column field="maxLobj" header="P Max" body={(rowData, column) => {
                                                        return <div>
                                                            <span style={{ fontWeight: 'bold' }}>{rowData.maxLobj}</span>
                                                        </div>
                                                    }} filter={true} filterMatchMode='contains' style={{ width: '7%' }} />
                                                    <Column field="valConfLobj" body={(rowData, props) => {
                                                        return <div>
                                                            <InputNumber style={{ width: '107px' }} onChange={(e) => {
                                                                this.state.liste_outil_indice[props.rowIndex].valConfLobj = e.value;
                                                            }}
                                                                showButtons buttonLayout="horizontal" decrementButtonClassName="p-button-secondary" incrementButtonClassName="p-button-success" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" max={rowData.maxLobj['maxLobj']} min={0} step={rowData.maxLobj} value={rowData.valConfLobj} />
                                                        </div>
                                                    }} header="Point obtenu" filter={true} filterMatchMode='contains' style={{ width: '15%' }} editor={this.valConfLobjEditorQual} />

                                                    <Column field="obsLobj" header="Observation" body={this.actionTemplate_obs.bind(this)} filter={true} filterMatchMode='contains' style={{ width: '20%' }} />
                                                </DataTable>
                                            </TabPanel>
                                            <TabPanel header="Recommandations">
                                                <div className="p-grid">
                                                    <div className="p-col-12 p-md-2">
                                                        <label >Recommandation *</label>
                                                    </div>
                                                    <div className="p-col-12 p-md-4">
                                                        <InputTextarea rows={2} value={this.state.libRec} onChange={(e) => this.setState({ libRec: e.target.value })} />
                                                    </div>
                                                    <div className="p-col-12 p-md-2">
                                                        <label >Commentaire</label>
                                                    </div>
                                                    <div className="p-col-12 p-md-3">
                                                        <InputTextarea rows={2} value={this.state.cmtRec} onChange={(e) => this.setState({ cmtRec: e.target.value })} />
                                                    </div>
                                                    <div className="p-col-12 p-md-1">
                                                        <Button type="button" icon="pi pi-plus" iconPos="left" label="Ajouter" onClick={(e) => this.add_reco()} style={{ width: 100 }} />
                                                    </div>
                                                </div>
                                                <DataTable value={this.state.liste_new_recom}
                                                    responsive={true} scrollable={true} scrollHeight="475px" selectionMode="single" className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                                                    onRowClick={e => this.setState({ selectedReco: e.data })} onRowSelect={e => this.setState({ selectedReco: e.data })}
                                                    selection={this.state.selectedReco}>
                                                    <Column header="#" body={this.actionTemplate_new_rec.bind(this)} style={{ width: '5%' }} />
                                                    <Column field="libRec" header="Recommandation" filter={true} filterMatchMode='contains' />
                                                    <Column field="cmtRec" header="Commentaire" filter={true} filterMatchMode='contains' />
                                                </DataTable>
                                            </TabPanel>
                                        </TabView>

                                    </div>

                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-6">
                                            <div className="p-field">
                                                <span className="p-float-label">
                                                    <InputTextarea id='obsObj' rows={4} value={this.state.obsObj} onChange={(e) => this.setState({ obsObj: e.target.value })} />
                                                    <label htmlFor="obsObj">Observation </label>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="p-col-12 p-md-6">
                                            <div className="p-field">
                                                <span className="p-float-label">
                                                    <InputTextarea id='pointsFortsObj' rows={4} value={this.state.pointsFortsObj} onChange={(e) => this.setState({ pointsFortsObj: e.target.value })} />
                                                    <label htmlFor="pointsFortsObj">Points forts </label>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="p-col-12 p-md-6">
                                            <div className="p-field">
                                                <span className="p-float-label">
                                                    <InputTextarea id='pointsFaiblesObj' rows={4} value={this.state.pointsFaiblesObj} onChange={(e) => this.setState({ pointsFaiblesObj: e.target.value })} />
                                                    <label htmlFor="pointsFaiblesObj">Points faibles </label>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="p-col-12 p-md-6">
                                            <div className="p-field">
                                                <span className="p-float-label">
                                                    <InputTextarea id='pointsFaiblesObj' rows={4} value={this.state.contraintesObj} onChange={(e) => this.setState({ contraintesObj: e.target.value })} />
                                                    <label htmlFor="pointsFaiblesObj">Contraintes </label>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <>
                                        <center>
                                            <div className="p-grid" style={{ width: 400 }}>
                                                <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={() => {
                                                    this.setState({ visible_form_new_obj: false, liste_selectedFacture: [] });
                                                }} /></div>
                                                <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={() => this.enreg_objectif_trim()} /></div>
                                                <div className="p-col">
                                                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                                </div>
                                            </div>
                                        </center>
                                    </>
                                </div>
                            }
                        </>
                }

            </div>


        );
    }
}