import React, { Component } from 'react';
import FbrService from '../service/FbrService';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { ProgressSpinner } from 'primereact/progressspinner';
import { SplitButton } from 'primereact/splitbutton';
import { MultiSelect } from 'primereact/multiselect';

export class FormUtilisateur extends Component {
    constructor() {
        super();
        this.state = {
            listeprest: [],
            listeuser: [],
            listesite: [],
            selectedUser: null,
            idUser: 0,
            pwdUser: '0000',
            dateExpUser: null,
            profilUser: null,
            loginUser: '',
            denregUser: new Date(),
            idPrest: null,
            msg: '',
            modif: false,
            visible: false,
            showindicator: false,

            paramIdUser: '',
            visible_gerer_site: false,
            selectedSites: null,
            modif_gerer_site: false,
            idGererSite: 0,
            liste_gerer_site: [],
            selectedGerersite: null,
        };
        this.edit_utiisateur = this.edit_utiisateur.bind(this);
        this.conf_delete_utiisateur = this.conf_delete_utiisateur.bind(this);
        this.toogle_actif_user = this.toogle_actif_user.bind(this);
        this.reset_password = this.reset_password.bind(this);

        this.show_dlg_gerer = this.show_dlg_gerer.bind(this);
        this.enreg_gerer_site = this.enreg_gerer_site.bind(this);
        this.act_gerer_site = this.act_gerer_site.bind(this);

        this.FbrService = new FbrService();

    }

    componentDidMount() {
        this.get_liste_utiisateur();
        this.get_liste_prestataire();
        this.get_liste_site();
    }

    get_liste_site() {
        this.setState({ showindicator: true });
        this.FbrService.get_liste_site().then(data => {
            this.setState({ showindicator: false });
            if (data.length > 0) {
                let liste = data.map(x => {
                    return { value: x.idSite, label: x.nomSite,prefectureSite: x.prefectureSite }
                });
                this.setState({ listesite: liste });
            }
        });
    }

    get_liste_gerer_site() {
        this.FbrService.get_liste_gerer_site_user(this.state.paramIdUser).then(data => this.setState({ liste_gerer_site: data }));
    }

    resultat_gerer_site(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.setState({ modif_gerer_site: false, selectedSites: [] });
            this.get_liste_gerer_site();

            this.componentDidMount();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    enreg_gerer_site() {
        if (this.state.selectedSites == null || this.state.selectedSites == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner les sites' });
        } else if (this.state.selectedSites.length == 0) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner les sites' });
        } else {
            this.setState({ showindicator: true });
            Array.prototype.insert = function (index) {
                this.splice.apply(this, [index, 0].concat(this.slice.call(arguments, 1)));
            };
            if (this.state.modif_gerer_site == false) {
                this.setState({ showindicator: true });
                var data_value = [];
                let userconnected = this.FbrService.get_userconnected();
                for (var i = 0; i < this.state.selectedSites.length; i++) {
                    var obj = { actifGererSite: 'Oui', idSite: { idSite: this.state.selectedSites[i] }, idUser: this.state.selectedUser, iduser: userconnected.idUser };
                    obj.idGererSite = 0;
                    data_value.push(obj);
                }
                this.FbrService.enreg_gerer_site_bulk(data_value).then(data => this.resultat_gerer_site(data.code, data.contenu));
            } else {
                let data = this.state.selectedGerersite;
                if (this.state.selectedGerersite.actifGererSite == 'Oui') {
                    data.actifGererSite = 'Non';
                } else {
                    data.actifGererSite = 'Oui';
                }
                this.setState({ showindicator: true });
                this.FbrService.enreg_gerer_site(data).then(data => this.resultat_gerer_site(data.code, data.contenu));
            }
        }
    }

    act_gerer_site(selected_data) {
        if (selected_data.actifGererSite == 'Oui') {
            selected_data.actifGererSite = 'Non';
        } else {
            selected_data.actifGererSite = 'Oui';
        }
        this.FbrService.enreg_gerer_site(selected_data).then(data => this.resultat_gerer_site(data.code, data.contenu));
    }

    actionTemplate_gerer_site(rowData, column) {
        if (rowData.actifGererSite == 'Oui') {
            return <Button title="Désactiver" onClick={(e) => this.act_gerer_site(rowData)} className="p-button-success" style={{ width: 25, height: 25 }} icon="pi pi-check" />
        } else {
            return <Button title="Activer" onClick={(e) => this.act_gerer_site(rowData)} className="p-button-danger" style={{ width: 25, height: 25 }} icon="pi pi-check" />
        }
    }

    show_dlg_gerer(data) {
        if (data !== null && data !== undefined) {
            this.setState({ selectedUser: data });
            this.setState({ paramIdUser: data.idUser }, () => {
                this.get_liste_gerer_site();
            });
            this.setState({ msg: data.loginUser });
            this.setState({ visible_gerer_site: true });
        }
    }


    get_liste_utiisateur() {
        this.setState({ showindicator: true });
        this.FbrService.get_liste_user().then(data => this.setState({ listeuser: data, showindicator: false }));
    }

    getParent(id, data) {
        let nom = '';
        if (data !== null && data !== undefined) {
            let liste = data.filter(function (el) {
                return el.idPrest == id;
            });
            if (liste !== null && liste !== undefined) {
                nom = liste[0].nomPrest;
            }
        }
        return nom;
    }

    get_liste_prestataire() {
        this.setState({ showindicator: true });
        this.FbrService.get_liste_prestataire().then(data => this.setState({ showindicator: false }, () => {
            if (data !== null && data !== undefined) {
                let listeprest = data;
                for (var i = listeprest.length; i--;) {
                    listeprest[i].nomPrestParent = this.getParent(listeprest[i].idPrestParent, data);
                }
                this.setState({ listeprest: listeprest });
            }
        }));
    }


    resultat(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_utiisateur();
            this.componentDidMount();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    edit_utiisateur(data) {
        if (data != null && data != undefined) {
            this.setState({ idUser: data.idUser });
            this.setState({ profilUser: data.profilUser });
            this.setState({ loginUser: data.loginUser });
            this.setState({ pwdUser: data.pwdUser });
            this.setState({ denregUser: data.denregUser });
            this.setState({ idPrest: data.idPrest.idPrest });
            this.setState({ dateExpUser: new Date(moment(data.dateExpUser, 'YYYY-MM-DD').format('YYYY-MM-DD')) });
            this.setState({ modif: true });
            this.setState({ selectedUser: data });
            window.scrollTo(0, 0);
        }
    }

    reset_password(rowData) {
        if (rowData === null || rowData === undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à modifier' });
        } else {
            rowData.pwdUser = '0000';
            this.setState({ showindicator: true });
            this.FbrService.enreg_user(rowData).then(data => {
                if (data !== null && data !== undefined) {
                    if (data.code === 200) {
                        this.toast.show({ severity: 'success', summary: 'Success', detail: 'Reinitialisation effectuée avec succès' });
                    } else {
                        this.toast.show({ severity: 'error', summary: 'Error', detail: 'Echec reinitialisation' });
                    }
                }
                this.setState({ showindicator: false });
            });
        }
    }

    toogle_actif_user(rowData) {
        if (rowData === null || rowData === undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à modifier' });
        } else {
            if (rowData.actifUser == 'Oui') {
                rowData.actifUser = 'Non';
            } else {
                rowData.actifUser = 'Oui';
            }
            this.setState({ showindicator: true });
            this.FbrService.enreg_user(rowData).then(data => {
                if (data !== null && data !== undefined) {
                    if (data.code === 200) {
                        this.toast.show({ severity: 'success', summary: 'Success', detail: 'Reinitialisation effectuée avec succès' });
                    } else {
                        this.toast.show({ severity: 'error', summary: 'Error', detail: 'Echec reinitialisation' });
                    }
                }
                this.setState({ showindicator: false });
            });
        }
    }

    annule_utiisateur() {
        this.setState({ profilUser: '' });
        this.setState({ loginUser: '' });
        this.setState({ idPrest: '' });
        this.setState({ idUser: 0 });
        this.setState({ modif: false });
        this.forceUpdate();
    }

    enreg_utiisateur() {
        if (this.state.loginUser == "" || this.state.profilUser == "" || this.state.idPrest == "" || this.state.dateExpUser == "") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            this.setState({ showindicator: true });
            if (this.state.modif == false) {
                let utilisateur = {
                    idUser: 0,
                    dateExpUser: moment(this.state.dateExpUser, 'DD/MM/YYYY').format('DD/MM/YYYY'),
                    loginUser: this.state.loginUser,
                    profilUser: this.state.profilUser,
                    pwdUser: this.state.pwdUser,
                    actifUser: 'Oui',
                    chpwdUser: 0,
                    idPrest: { idPrest: this.state.idPrest }
                }
                this.FbrService.enreg_user(utilisateur).then(data => this.resultat(data.code, data.contenu));
            } else {
                let utilisateur = this.state.selectedUser;
                utilisateur.dateExpUser = moment(this.state.dateExpUser, 'DD/MM/YYYY').format('DD/MM/YYYY');
                utilisateur.loginUser = this.state.loginUser;
                utilisateur.profilUser = this.state.profilUser;
                this.FbrService.enreg_user(utilisateur).then(data => this.resultat(data.code, data.contenu));
            }
        }
    }

    actionTemplate(rowData) {
        let label = 'Activer';
        if (rowData.actifUser == 'Oui') {
            label = 'Désactiver';
        }
        let items = [
            {
                label: 'Supprimer',
                icon: 'pi pi-times',
                command: (e) => {
                    this.conf_delete_utiisateur(rowData);
                }
            },
            {
                label: 'Modifier',
                icon: 'pi pi-pencil',
                command: (e) => {
                    this.edit_utiisateur(rowData);
                }
            },
            {
                label: 'Réinitialiser',
                icon: 'pi pi-refresh',
                command: (e) => {
                    this.reset_password(rowData);
                }
            },
            {
                label: label,
                icon: 'pi pi-lock',
                command: (e) => {
                    this.toogle_actif_user(rowData);
                }
            },
            {
                label: 'Affectation',
                icon: 'pi pi-sitemap',
                command: (e) => {
                    this.show_dlg_gerer(rowData);
                }
            }
        ]
        return <SplitButton className='p-button-info' onClick={(e) => this.edit_utiisateur(rowData)} icon="pi pi-pencil" style={{ width: 80 }} model={items}></SplitButton>
    }


    delete_utiisateur() {
        if (this.state.selectedUser == null || this.state.selectedUser == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.FbrService.delete_user(this.state.selectedUser).then(data => this.resultat(data.code, data.contenu));
        }
    }


    conf_delete_utiisateur(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedUser: data });
            this.setState({ msg: data.loginUser });
            this.setState({ visible: true });
        }
    }

    exportCSV = () => {
        this.dt.exportCSV();
    }

    itemTemplate(option) {
        if (!option.value) {
            return option.label;
        }
        else {
            return (
                <div className="p-clearfix">
                    <div className="p-grid">
                        <div className="p-col-12 p-md-6">
                            <label>{option.label}</label>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <label>{option.nomPrestParent}</label>
                        </div>
                    </div>
                </div>
            );
        }
    }


    itemTemplateSite(option) {
        if (!option.value) {
            return option.label;
        }
        else {
            return (
                <label>{option.label} / {option.prefectureSite}</label>
            );
        }
    }

    render() {
        let header_export = <React.Fragment>
            {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={this.exportCSV} />}
        </React.Fragment>
        let prestataire
        if (this.state.listeprest.length > 0) {
            let liste = this.state.listeprest.filter(function (el) {
                return el.typePrest.libCodif == 'PERSONNE';
            });
            if (liste !== null && liste !== undefined) {
                prestataire = liste.map(x => {
                    return { value: x.idPrest, label: x.nomPrest, nomPrestParent: x.nomPrestParent }
                });
            }
        }
        let liste_profil = [
            { label: 'Administrateur', value: 'Administrateur' },
            { label: 'Superviseur', value: 'Superviseur' },
            { label: 'Payeur', value: 'Payeur' },
            { label: 'Coordinateur', value: 'Coordinateur' },
            { label: 'AGFA', value: 'AGFA' },
            { label: 'OBC', value: 'OBC' },
            { label: 'Prestataire', value: 'Prestataire' },
            { label: 'Agent de saisie', value: 'Agent de saisie' },
            { label: 'Consultation', value: 'Consultation' },
        ];
        if(this.FbrService.getProfil()=='AGFA'){
            liste_profil = [
                { label: 'AGFA', value: 'AGFA' },
                { label: 'Coordinateur', value: 'Coordinateur' },
                { label: 'OBC', value: 'OBC' },
                { label: 'Prestataire', value: 'Prestataire' },
                { label: 'Agent de saisie', value: 'Agent de saisie' }
            ];
        }
        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_utiisateur();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        return (
            <div className="p-fluid">
                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={() => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                <Dialog header="Sites rattachés" visible={this.state.visible_gerer_site} style={{ width: '60%' }} modal={true} onHide={(e) => this.setState({ visible_gerer_site: false })}>
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="card card-w-title" style={{ height: 450 }}>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-2">
                                        <label htmlFor="profile">Sites *</label>
                                    </div> 
                                    <div className="p-col-12 p-md-7">
                                        <MultiSelect options={this.state.listesite} display="chip" value={this.state.selectedSites} onChange={(e) => this.setState({ selectedSites: e.value })} optionLabel="label" filter={true} filterBy='label,prefectureSite' filterMatchMode='contains' placeholder="Selectionner les sites" autoWidth={false}  appendTo={document.body} itemTemplate={this.itemTemplateSite} />
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <Button label="Ajouter" icon="pi pi-check" onClick={(e) => this.enreg_gerer_site()} />
                                    </div>
                                    <div className="p-col-12 p-md-1">
                                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                    </div>
                                </div>
                                <DataTable value={this.state.liste_gerer_site} selectionMode="single" scrollable={true} scrollHeight="300px"
                                    onRowClick={e => this.setState({ selectedGerersite: e.data })} onRowSelect={e => this.setState({ selectedGerersite: e.data })}
                                    responsive={true} selection={this.state.selectedGerersite} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers" >
                                    <Column header="#" body={this.actionTemplate_gerer_site.bind(this)} style={{ width: '7%' }} />
                                    <Column field="idSite.nomSite" header="Nom site" filter={true} filterMatchMode='contains' sortable={true} />
                                    <Column field="idSite.prefectureSite" header="Préfecture" style={{ width: '20%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                    <Column field="idSite.crdSite" header="CR" style={{ width: '20%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                    <Column field="idSite.regionSite" header="Région" style={{ width: '20%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </Dialog>

                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title">

                            <div className="p-grid">
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="idPrest">Prestataire *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Dropdown value={this.state.idPrest} options={prestataire} optionLabel='label' filter={true} filterMatchMode='contains' filterBy='label,nomPrestParent' onChange={(e) => this.setState({ idPrest: e.target.value })} autoWidth={false} panelStyle={{ width: '500px' }} itemTemplate={this.itemTemplate} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="loginUser">Login *</label> 
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText id="loginUser" value={this.state.loginUser} onChange={(e) => this.setState({ loginUser: e.target.value })} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="profilUser">Profil *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Dropdown value={this.state.profilUser} options={liste_profil} onChange={(e) => this.setState({ profilUser: e.target.value })} autoWidth={false} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="dateRgl">Date expiration *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Calendar id="dateRgl" dateFormat="dd/mm/yy" value={this.state.dateExpUser} onChange={(e) => this.setState({ dateExpUser: e.target.value })} />
                                </div>
                            </div>

                            <center>
                                <div className="p-grid" style={{ width: 300 }}>
                                    <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={() => this.annule_utiisateur()} /></div>
                                    <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={() => this.enreg_utiisateur()} /></div>
                                </div>
                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                            </center>

                            <DataTable value={this.state.listeuser} paginatorPosition="top" selectionMode="single" paginator={true} rows={20}
                                alwaysShowPaginator={false} onRowClick={e => this.setState({ selectedUser: e.data })} onRowSelect={e => this.setState({ selectedUser: e.data })} ref={(el) => { this.dt = el; }}
                                responsive={true} selection={this.state.selectedUser} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers" paginatorRight={header_export} paginatorLeft={""}>
                                <Column header="Action" body={this.actionTemplate.bind(this)} style={{ width: '10%' }} />
                                <Column field="idPrest.nomPrest" filter={true} filterMatchMode='contains' header="Nom utilisateur" sortable={true} />
                                <Column field="profilUser" filter={true} filterMatchMode='contains' header="Profil" sortable={true} style={{ width: '15%' }} />
                                <Column field="idPrest.telPrest" filter={true} filterMatchMode='contains' header="Téléphone" sortable={true} style={{ width: '12%' }} />
                                <Column field="loginUser" filter={true} filterMatchMode='contains' header="Login" sortable={true} style={{ width: '12%' }} />
                                <Column field="dateExpUser" filter={true} filterMatchMode='contains' header="Expiration" sortable={true} style={{ width: '11%' }} />
                                <Column field="actifUser" filter={true} filterMatchMode='contains' header="Actif" sortable={true} style={{ width: '8%' }} />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}